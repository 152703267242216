import { Dispatch } from 'react'
import fetchFactory from '../../../utils/fetchFactory'
import { saveFavorites } from '../reducer/actions'
import errorCatcher from '../../../utils/errorCatcher'

const FAVORITES_ERROR = 'get_favorites_error'

export const getFavorites = (id: string, language: string, dispatch: Dispatch<any>) => {
	return fetchFactory(`/cms/${language}/api/getFavorites?id=${id}`)
		.then(
			(favorites: any) => dispatch(
				saveFavorites(favorites.favorites)
			)
		)
		.catch(
			(error) => dispatch(
				errorCatcher(error, FAVORITES_ERROR)
			)
		)
}
