import { CitiesModel } from '../types/searchFormModel'
import queryString from 'query-string'
import { natureEnum } from '../../../enums/natureEnum'

export const getFormData = (search: string) => {
    const cities: CitiesModel[] = queryString.parse(search).cities ? JSON.parse(queryString.parse(search).cities as string) : []
    const disponibility = queryString.parse(search).disponibility || []
    const surface = queryString.parse(search).surface || '0,0'
    let min: number = 0
    let max: number = 0

    if (Array.isArray(surface)) {
        min = Number(surface[0].split(',')[0])
        max = Number(surface[0].split(',')[1])
    } else {
        min = Number(surface.split(',')[0])
        max = Number(surface.split(',')[1])
    }

    if (min < max) {
        return {
            cities: cities.map(
                (city) => (
                    {
                        name: city.name,
                        lat: Number(city.lat),
                        lng: Number(city.lng)
                    }
                )
            ),
            disponibility: Array.isArray(disponibility) ? disponibility : [disponibility],
            surface: {
                min: min,
                max: max
            }
        }
    } else if (queryString.parse(search).nature) {
        return {
            nature: natureEnum.retail
        }
    }

    return undefined
}
