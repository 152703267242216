import React, { useEffect, useState } from 'react'
import DeckModal from './component/DeckModal'
import { useHistory, useRouteMatch } from 'react-router'
import { DeckModel, DeckResponseModel } from './types/deckModel'
import Loader from '../../components/loader/Loader'
import { getOfferDetails } from './api/getOfferDetails'
import { useDispatch, useSelector } from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import { INITIAL_STATE } from './utils/deckMock'
import { deckMapper } from './utils/deckMapper'
import { useIntl } from 'react-intl'
import { RootState } from '../../index'

interface MatchParamsModel {
	id: string
}

const GET_OFFER_DETAILS = 'get_offer_details'
const NO_RESULT = 'Aucun contenu trouvé'

const Deck = () => {
	const intl = useIntl()
	const match = useRouteMatch<MatchParamsModel>()
	const dispatch = useDispatch()
	const history = useHistory()
	const user = useSelector((state: RootState) => state.user)

	const [content, setContent] = useState<DeckModel>(INITIAL_STATE)
	const [loading, setLoading] = useState<boolean>(true)

	useEffect(
		() => {
			const id = match.params.id

			getOfferDetails(id, intl.locale, user.id)
				.then(
					(response: any) => {
						if (response === NO_RESULT) {
							history.goBack()
						} else {
							const tmpContent: DeckResponseModel = response

							if (tmpContent.surfaces && tmpContent.surfaces.length > 0) {
								tmpContent.surfaces = tmpContent.surfaces.map(
										(surface) => (
											{
												level: surface.level,
												surface: surface.surface,
												garden: surface.garden,
												estimation: surface.estimation
											}
										)
									)

								tmpContent.surfaces.forEach(
										(item) => {
											if (item.estimation === undefined) {
												delete item.estimation
											}
											if (item.garden === undefined) {
												delete item.garden
											}
										}
									)
							}

							if (tmpContent.contacts && tmpContent.contacts.length > 0) {
								tmpContent.contacts.sort(
									(a, b) => {
										if (a.lastName > b.lastName) {
											return 1
										}
										else if (a.lastName < b.lastName) {
											return -1
										}

										return 0
									}
								)
							}

							setContent(deckMapper(tmpContent))
							setLoading(false)
						}
					}
				)
				.catch(
					(error) => {
						history.goBack()

						dispatch(
							errorCatcher(error, GET_OFFER_DETAILS)
						)
					}
				)
		}, [dispatch, history, intl.locale, match.params.id, user.id]
	)

	if (loading) {
		return (
			<Loader />
		)
	}

	return (
		<DeckModal content={content} />
	)
}

export default Deck