import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import classNames from 'classnames'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'

const useStyle = makeStyles((theme) => ({
        ...styles(theme),
        containerDiv: {
            display: 'flex',
            alignItems: 'center'
        },
        containerDivLargeScreen: {
            breakInside: 'avoid-column'
        },
        containerDivSmallScreen: {
            marginBottom: 17
        },
        textDivLargeScreen: {
            marginLeft: 16,
            wordBreak: 'break-word'
        },
        textDivSmallScreen: {
            marginLeft: '3%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        textTitle: {
            color: theme.palette.blue.main,
            fontSize: 18
        },
        textTitleSmall: {
            color: theme.palette.blue.main,
            marginRight: 8,
            fontSize: 18
        },
        textNumber: {
            color: theme.palette.accessibility.main,
            fontSize: 18
        },
        icon: {
            fill: theme.palette.blue.main
        }
    }
))

interface AcceptingProps {
    iconName: JSX.Element,
    title: string,
    number: string
}

type IconNumberComponentProps = AcceptingProps

const IconNumberComponent: React.FC<IconNumberComponentProps> = (
    {
        iconName, title, number
    }
) => {
    const classes = useStyle()
    const { isTabletteScreen } = useContext(ResponsiveContext)

    const textTitle = classNames(
        isTabletteScreen ? classes.textTitle : classes.textTitleSmall,
        classes.text8
    )

    const textNumber = classNames(
        classes.textNumber,
        classes.text2
    )

    const textDivClass = classNames(
        isTabletteScreen ? [classes.textDivLargeScreen] : [classes.textDivSmallScreen]
    )

    const containerDivClass = classNames(
        isTabletteScreen ? [classes.containerDivLargeScreen] : [classes.containerDivSmallScreen],
        classes.containerDiv
    )

    const callNumber = () => {
        if (!isTabletteScreen) {
            window.location.href = `tel:${number}`
        }
    }

    return (
        <div className={containerDivClass}>
            {iconName}
            <div className={textDivClass}>
				<span className={textTitle}>
					{title}
				</span>

                <div>
					<span className={textNumber} onClick={callNumber}>
						{number}
					</span>
                </div>
            </div>

        </div>
    )
}
export default IconNumberComponent