import { PromotedTypes } from '../types/types'
import { SAVE_PROMOTED } from './actionTypes'

const promoted = (
	state: string = '',
	action: PromotedTypes
): string => {
	switch (action.type) {
		case SAVE_PROMOTED: {
			return 'payload' in action ? action.payload : ''
		}
		default:
			return state
	}
}

export default promoted
