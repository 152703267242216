import { DeckModel, DeckResponseModel } from '../types/deckModel'

export const deckMapper = (deckResponse: DeckResponseModel): DeckModel => (
	{
		...deckResponse,
		location: {
			...deckResponse.location,
			stops: deckResponse.location.stops ? (
				deckResponse.location.stops.map(
					(stopResponse) => {
						return {
							line: stopResponse.ligne,
							name: stopResponse.station ? stopResponse.station : null,
							duration: stopResponse.time ? Number(stopResponse.time) : null,
							icon: stopResponse.picto,
							type: stopResponse.type
						}
					}
				)
			) : []
		}
	}
)
