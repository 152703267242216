import { SelectionModel } from '../../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../../components/offerDetails/types/offerDetailsModel'
import { CitiesModel } from '../../../research/types/searchFormModel'

export const calculDistance = (point1: CitiesModel, point2: (SelectionModel & OfferDetailsModel)) => {
	const R = 3958.8; // Radius of the Earth in miles
	const rlat1 = point1.lat * (Math.PI/180); // Convert degrees to radians
	const rlat2 = point2.latitude * (Math.PI/180); // Convert degrees to radians
	const difflat = rlat2-rlat1; // Radian difference (latitudes)
	const difflon = (point2.longitude-point1.lng) * (Math.PI/180); // Radian difference (longitudes)

	return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
}