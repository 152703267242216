import { Checkbox, FormControlLabel } from '@material-ui/core'
import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(
    () => (
        {
            checkboxTransit: {
                position: 'absolute',
                top: 10,
                right: 50,
                zIndex: 1,
                background: 'white',
                boxShadow: 'rgb(0 0 0 / 30%) 0px 1px 4px -1px',
                borderRadius: 2,
                marginRight: 15,
                paddingLeft: 10
            }
        }
    )
)

interface AcceptingProps {
    hasTransit: boolean,
    setHasTransit: (hasTransit: boolean) => void
}

type checkboxTransitProps = AcceptingProps

const TransitComponent: React.FC<checkboxTransitProps> = (
    {
        hasTransit,
        setHasTransit
    }
) => {
    const classes = useStyles()
    const intl = useIntl()

    return (
        <div className={classes.checkboxTransit}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hasTransit}
                        color="primary"
                        onChange={() => setHasTransit(!hasTransit)}
                    />
                }
                label={
                    intl.formatMessage(
                        {
                            id: 'map.transit',
                            defaultMessage: 'Transports',
                            description: 'Enable transit checkbox'
                        }
                    )
                }
                labelPlacement="end"
            />
        </div>
    )
}

export default TransitComponent