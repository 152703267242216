import React, { memo } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import links from '../enums/linksEnum'
import Homepage from '../modules/homepage/Homepage'
import Selection from '../modules/selection/Selection'
import localStorageEnum from '../enums/localStorageEnum'
import Deck from '../modules/deck/Deck'
import { useSelector } from 'react-redux'
import { RootState } from '../index'
import DetailFooterPage from '../modules/footer/DetailFooterPage'
import Favorites from '../modules/favorites/Favorites'
import Offers from '../modules/offers/Offers'
import CheckToken from '../modules/checkToken/CheckToken'
import SearchOffers from '../modules/research/SearchOffers'
import { useLocation } from 'react-router-dom'

const Root = () => {
	const url_token = localStorage.getItem(localStorageEnum.url_token)
	const selections = useSelector((state: RootState) => state.selection)
	const favorites = useSelector((state: RootState) => state.favorites)
	const location = useLocation()

	return (
			<Switch>
				<Route path={links.homepage} component={Homepage} />
				{
					url_token === process.env.REACT_APP_GENERIC_URL || selections.selections.visits.concat(selections.selections.selectedOffers).length === 0 ? (
						<Route path={links.selection}>
							<Redirect to={links.homepage} />
						</Route>
					) : (
						<Route path={links.selection} component={Selection} />
					)
				}
				<Route path={`${links.checkToken}/:token`} component={CheckToken} />
				<Route path={`${links.footer}/:id`} component={DetailFooterPage} />
				<Route path="/" exact>
					<Redirect to={links.homepage} />
				</Route>

				<Route path={`${links.deskDetails}/:id`} component={Deck} />

				{
					favorites.favorites.length === 0 ? (
						<Route path={links.favorites}>
							<Redirect to={`${links.homepage}${location.search}`} />
						</Route>
					) : (
						<Route path={links.favorites} component={Favorites} />
					)
				}

				<Route path={links.research} component={SearchOffers} />
				<Route path={links.offers} component={Offers} />
			</Switch>
	)
}

export default memo(Root)
