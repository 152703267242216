import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl'
import { manageFavorites as manageFavoritesAction } from '../../favorites/reducer/actions'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import links from '../../../enums/linksEnum'
import { useHistory } from 'react-router'
import { manageFavorites } from '../../selection/api/manageFavorites'
import errorCatcher from '../../../utils/errorCatcher'
import { SearchFormModel } from '../../research/types/searchFormModel'
import { SelectionModel } from '../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../components/offerDetails/types/offerDetailsModel'
import { NatureModel, OffersModel } from '../types/offersModel'
import { offersTypeEnum } from '../../research/enum/offersTypeEnum'
import { objectGetValues } from '../../../utils/ieCompatibility'
import Element from 'react-scroll/modules/components/Element'
import { isCardHovered } from '../../selection/reducer/actions'
import { isCardHoveredInitialState } from '../../selection/reducer/reducers'
import SelectionCard from '../../../components/offersDisplay/components/SelectionCard'
import { calculDistance } from '../utils/map/calculDistance'
import { offersFilter } from '../utils/map/offersFilter'
import { objIsNatureType } from '../../research/utils/checkObjType'
import SvgLocation from '../../../icons/Location'
import { useTheme } from '@material-ui/core/styles'
import { OffersContext } from '../utils/context/offersContext'
import { natureIdEnum } from '../../../enums/natureEnum'
import { selectionStatusEnum } from '../../selection/enum/selectionEnum'
import { useLocation } from 'react-router-dom'
import { API_KEY } from '../../../components/offersDisplay/components/map/utils/globalConst'
import GoogleMapReact from 'google-map-react'
import { redirectToWithQuery } from '../../../utils/formatter/formatRouter'

const useStyles = makeStyles(
    (theme) => (
        {
            selectionTitles: {
                '& > div:first-child h1': {
                    color: theme.palette.blue.main,
                    margin: 0
                },
                '& > div:first-child .title': {
                    marginTop: 30
                },
                '& .subtitle': {
                    ...styles(theme).title5,
                    color: theme.palette.blue.main,
                    margin: 0
                },
                '& > div:first-child h1:nth-child(2)': {
                    marginTop: 22
                },
                '& div div': {
                    padding: 0
                },
                '& .noMatching': {
                    marginTop: 20
                }
            },
            selectionTitlesLargeScreenStyle: {
                marginLeft: 40,

                '& > div:first-child h1': {
                    ...styles(theme).title1
                },
                '& .subtitle': {
                    marginBottom: 30
                },
                '& .shortDiv-0': {
                    paddingRight: 10
                },
                '& .shortDiv-1': {
                    paddingLeft: 10
                }
            },
            selectionTitlesSmallScreenStyles: {
                '& > div:first-child h1': {
                    ...styles(theme).title2
                },
                '& .subtitle': {
                    marginBottom: 15
                }
            },
            borderItem: {
                borderTop: `2px solid ${theme.palette.green.main}`
            }
        }
    )
)

interface AcceptingProps {
    formData?: SearchFormModel | NatureModel,
    onOffersFilteredUpdated?: (offersFiltered: (SelectionModel & OfferDetailsModel)[]) => void
}

const MANAGE_FAVORITE = 'manage_favorite'

type OffersComponentProps = AcceptingProps

const OffersComponent: React.FC<OffersComponentProps> = (
    {
        formData,
        onOffersFilteredUpdated
    }
) => {
    const classes = useStyles()
    const intl = useIntl()
    const location = useLocation()
    const history = useHistory()
    const theme = useTheme()
    const user = useSelector((state: RootState) => state.user)
    const offers = useSelector((state: RootState) => state.offers)
    const favorites = useSelector((state: RootState) => state.favorites)
    const dispatch = useDispatch()
    const { isLargeScreen } = useContext(ResponsiveContext)
    const { isApiLoaded, setIsApiLoaded } = useContext(OffersContext)
    const [offersFiltered, setOffersFitlered] = useState<OffersModel>(
        {
            matching: [],
            notMatching: []
        }
    )

    const titleText = intl.formatMessage(
        {
            id: 'offers.list.offer'
        },
        {
            value: offersFiltered.matching.length
        }
    )

    const selectionTitlesClass: ClassValue = classNames(
        classes.selectionTitles,
        isLargeScreen ? classes.selectionTitlesLargeScreenStyle : classes.selectionTitlesSmallScreenStyles
    )

    useEffect(
        () => {
            const offersMatching: (SelectionModel & OfferDetailsModel)[] = []
            const offersNotMatching: (SelectionModel & OfferDetailsModel)[] = []

            if (formData && !objIsNatureType(formData)) {
                const {
                    matching,
                    notMatching
                } = offersFilter(offers.offers.filter((offer) => offer.status === selectionStatusEnum.available && offer.offer_nature !== natureIdEnum.retail), formData, isApiLoaded)

                offersMatching.push(...matching)
                offersNotMatching.push(...notMatching)
            } else if (formData && objIsNatureType(formData)) {
                offersMatching.push(...offers.offers.filter((offer) => offer.offer_nature === natureIdEnum.retail && offer.status === selectionStatusEnum.available))
            } else {
                offersMatching.push(...offers.offers.filter((offer) => offer.status === selectionStatusEnum.available && offer.offer_nature !== natureIdEnum.retail))
            }

            const offersMatchingSorted = offersMatching.sort(
                (a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
                    const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : formData ? Number(a.divisibleFrom) : Number(a.availableArea)
                    const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : formData ? Number(b.divisibleFrom) : Number(b.availableArea)

                    return tmpB - tmpA
                }
            )

            let offersNotMatchingSorted: (SelectionModel & OfferDetailsModel)[] = []

            if (formData) {
                offersNotMatchingSorted = offersNotMatching.sort(
                    (a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
                        if (!objIsNatureType(formData) && formData.cities.length > 0) {
                            return calculDistance(formData.cities[0], a) + -calculDistance(formData.cities[0], b)
                        } else {
                            const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : Number(a.divisibleFrom)
                            const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : Number(b.divisibleFrom)

                            return tmpB - tmpA
                        }
                    }
                )
            }

            setOffersFitlered(
                {
                    matching: offersMatchingSorted,
                    notMatching: offersNotMatchingSorted
                }
            )

            onOffersFilteredUpdated && onOffersFilteredUpdated([...offersMatchingSorted.concat(offersNotMatchingSorted)])

            return () => setOffersFitlered(
                {
                    matching: [],
                    notMatching: []
                }
            )
        }, [formData, offers, onOffersFilteredUpdated, isApiLoaded]
    )

    const onItemSelected = (type: offersTypeEnum, index: number) => {
        const offer = offersFiltered[type][index]

        const favoriteIndex = favorites.favorites.findIndex((favorite) => favorite.id === offer.id)

        if (favoriteIndex >= 0) {
            const favoriteItem = favorites.favorites[favoriteIndex]

            manageFavorites(user.id, favoriteItem.id, !favoriteItem.selected)
                .then(
                    () => {
                        dispatch(
                            manageFavoritesAction(
                                {
                                    ...favoriteItem,
                                    selected: !favoriteItem.selected
                                }
                            )
                        )
                    }
                )
                .catch(
                    (error: any) => dispatch(
                        errorCatcher(error, MANAGE_FAVORITE)
                    )
                )
        } else {
            manageFavorites(user.id, offer.id, true)
                .then(
                    () => {
                        dispatch(
                            manageFavoritesAction(
                                {
                                    ...offer,
                                    selected: true
                                }
                            )
                        )
                    }
                )
                .catch(
                    (error: any) => dispatch(
                        errorCatcher(error, MANAGE_FAVORITE)
                    )
                )
        }
    }

    const seeDetails = (id: string) => {
        redirectToWithQuery(history, `${links.deskDetails}/${id}`)
    }

    return (
        <>
            {
                objectGetValues(offersTypeEnum).map(
                    (offerType: offersTypeEnum, index) => (
                        <React.Fragment key={offerType}>
                            {
                                offerType === offersTypeEnum.matching && offersFiltered.matching.length === 0 && (
                                    <div className={selectionTitlesClass}>
                                        <h2 className="subtitle noMatching">
                                            <FormattedMessage
                                                id="offers.list.noMatching"
                                                defaultMessage="Nous n’avons malheureusement pas trouvé d’offre correspondant à vos critères"
                                                description="No offers matching message"
                                            />
                                        </h2>
                                    </div>
                                )
                            }
                            {
                                offersFiltered[offerType].length > 0 && (
                                    <div
                                        className={
                                            classNames(
                                                selectionTitlesClass,
                                                {
                                                    [classes.borderItem]: offersFiltered.matching.length > 0 && index === 1
                                                }
                                            )
                                        }
                                    >
                                        <div className="row">
                                            <div className="col-xs-2 col-md-1 title">
                                                {
                                                    offerType === offersTypeEnum.matching ? (
                                                        <SvgLocation
                                                            color={theme.palette.green.main}
                                                            secondColor={'#FFFFFF'}
                                                            height={42}
                                                            width={30}
                                                            borderColor={theme.palette.green.main}
                                                        />
                                                    ) : (
                                                        <SvgLocation
                                                            color="#FFFFFF"
                                                            secondColor={theme.palette.blue.main}
                                                            height={42}
                                                            width={30}
                                                            borderColor={theme.palette.blue.main}
                                                        />
                                                    )
                                                }
                                            </div>

                                            <div className="col-xs-10 col-md-11 title">
                                                <h1>
                                                    {
                                                        offerType === offersTypeEnum.matching ? (
                                                            <FormattedPlural
                                                                value={offersFiltered.matching.length}
                                                                zero={titleText}
                                                                one={titleText}
                                                                other={`${titleText}s`}
                                                            />
                                                        ) : (
                                                            <FormattedMessage
                                                                id="offers.list.canInterest"
                                                            />
                                                        )
                                                    }
                                                </h1>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xs-2 col-md-1"></div>

                                            <div className="col-xs-10 col-md-11">
                                                <p className="subtitle">
                                                    {
                                                        offerType === offersTypeEnum.matching && location.pathname !== links.offers && (
                                                            <>
                                                                <FormattedPlural
                                                                    value={offersFiltered.matching.length}
                                                                    one={
                                                                        intl.formatMessage(
                                                                            {
                                                                                id: 'offers.list.match',
                                                                                defaultMessage: 'correspond à votre recherche',
                                                                                description: 'Subtitle text'
                                                                            }
                                                                        )
                                                                    }
                                                                    other={
                                                                        intl.formatMessage(
                                                                            {
                                                                                id: 'offers.list.matches',
                                                                                defaultMessage: 'correspondent à votre recherche',
                                                                                description: 'Subtitle text'
                                                                            }
                                                                        )
                                                                    }
                                                                />

                                                                {
                                                                    offersFiltered.notMatching.length > 0 && (
                                                                        <FormattedPlural
                                                                            value={offersFiltered.notMatching.length}
                                                                            one={
                                                                                intl.formatMessage(
                                                                                    {
                                                                                        id: 'offers.list.offerInterest',
                                                                                        defaultMessage: 'et 1 peut vous intéresser',
                                                                                        description: 'Subtitle offer can interest text'
                                                                                    }
                                                                                )
                                                                            }
                                                                            other={
                                                                                intl.formatMessage(
                                                                                    {
                                                                                        id: 'offers.list.offersInterest',
                                                                                        defaultMessage: 'et {offers} peuvent vous intéresser',
                                                                                        description: 'Subtitle offers can interest text'
                                                                                    },
                                                                                    {
                                                                                        offers: offersFiltered.notMatching.length
                                                                                    }
                                                                                )
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {
                                                offersFiltered[offerType].map(
                                                    (item, index) => (
                                                        <Element
                                                            name={item.id}
                                                            className={`col-xs-12 col-sm-6 shortDiv-${index % 2}`}
                                                            key={item.id}
                                                            onMouseEnter={
                                                                () => dispatch(
                                                                    isCardHovered(
                                                                        {
                                                                            isHovered: true,
                                                                            offerHovered: item.id
                                                                        }
                                                                    )
                                                                )
                                                            }
                                                            onMouseLeave={
                                                                () => dispatch(
                                                                    isCardHovered(isCardHoveredInitialState)
                                                                )
                                                            }
                                                        >
                                                            <SelectionCard
                                                                item={item}
                                                                selectItem={() => onItemSelected(offerType, index)}
                                                                seeDetails={seeDetails}
                                                            />
                                                        </Element>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </React.Fragment>
                    )
                )
            }

            {
                !isLargeScreen && (
                    <div>
                        <GoogleMapReact
                            bootstrapURLKeys={
                                {
                                    key: API_KEY,
                                    libraries: ['places', 'geometry']
                                }
                            }
                            center={
                                {
                                    lat: 0,
                                    lng: 0
                                }
                            }
                            defaultZoom={10}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={() => setIsApiLoaded(true)}
                        />
                    </div>
                )
            }
        </>
    )
}

export default OffersComponent
