import React from 'react'
import SvgStarSelection from '../../../../icons/StarSelection'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../index'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SelectionModel } from '../../types/selectionModel'
import { OfferDetailsModel } from '../../../../components/offerDetails/types/offerDetailsModel'

const useStyles = makeStyles(
	(theme) => (
		{
			svgContainerStyle: {
				'& svg': {
					cursor: 'pointer',
					position: 'absolute',
					top: 10,
					right: 10,
					padding: 8,
					borderRadius: '100%',
					background: (props: boolean) => props ? theme.palette.blue.main : 'white'
				}
			}
		}
	)
)

interface AcceptingProps {
	item: (SelectionModel & OfferDetailsModel)
	selectItem: () => void
}

const StarSelection: React.FC<AcceptingProps> = (
	{
		item,
		selectItem
	}
) => {
	const favorites = useSelector((state: RootState) => state.favorites)
	const isSelected = favorites.favorites.findIndex((favorite) => favorite.id === item.id) >= 0
	const classes = useStyles(isSelected)
	const theme = useTheme()

	const handleOnClickStar = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		event.stopPropagation()
		selectItem()
	}

	return (
		<span
			className={classes.svgContainerStyle}
			onClick={handleOnClickStar}
		>
			<SvgStarSelection
				color={isSelected ? '#FFFFFF' : theme.palette.blue['30']}
				width={16}
				height={16}
			/>
		</span>
	)
}

export default StarSelection