// Nom de fichier renvoyé sans accents et sans espaces (en gardant les _ et -)

export const formatFilename = (filename: string, replaceSpacesBy: string = '_') => {
	const lastDot = filename.lastIndexOf('.')
	let name = ''
	let ext = ''
	if (lastDot >= 0) {
		ext = filename.substring(lastDot)
		name = filename.substring(0 ,lastDot)
	} else {
		name = filename.toLowerCase()
	}

	const newFilename = name.normalize('NFD').replace(/[\u0300-\u036f]|[^a-zA-Z0-9\s_-]/g, "").replace(/\s+/g, replaceSpacesBy)
	return newFilename.concat(ext)
}