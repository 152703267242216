import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgFavorite = (
    {
        color,
        height,
        width
    }: IconsModel
) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
        <path
            d="M12 17.576c.114 0 .228.028.33.084l5.1 2.775-1.145-5.562a.644.644 0 01.204-.61l4.298-3.847-5.808-.662a.675.675 0 01-.535-.377L12 4.224 9.556 9.377a.673.673 0 01-.535.377l-5.808.662 4.298 3.847c.173.155.25.386.204.61L6.57 20.435l5.1-2.775a.686.686 0 01.33-.084m6.38 4.784a.692.692 0 01-.33-.084L12 18.983l-6.05 3.293a.693.693 0 01-.728-.042.645.645 0 01-.265-.658l1.358-6.599-5.098-4.564a.644.644 0 01-.184-.684.672.672 0 01.564-.449l6.89-.785 2.9-6.113A.676.676 0 0112 2c.264 0 .504.149.614.382l2.9 6.113 6.89.785c.261.03.481.205.563.449.081.243.01.51-.184.684l-5.098 4.564 1.358 6.599a.645.645 0 01-.265.658.693.693 0 01-.397.126"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)

export default SvgFavorite