import React, { useContext } from 'react'
import { Field, Form } from 'react-final-form'
import Button from '../../../../../components/button/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import arrayMutators from 'final-form-arrays'
import classNames from 'classnames'
import FormTextField from '../../../../../components/fields/FormTextField'
import { objectGetKeys } from '../../../../../utils/ieCompatibility'
import { firstStep } from '../../../enum/stepperEnum'
import { ResponsiveContext } from '../../../../../utils/context/responsiveContext'
import { makeStyles } from '@material-ui/core/styles'
import { MakeRequestModel } from '../../../types/makeRequestModel'
import { MakeRequestContext } from '../../../utils/MakeRequestContext'
import { isEmailOk } from '../../../../../utils/formUtils'
import { MenuItem } from '@material-ui/core'
import { positionEnum } from '../../../enum/positionEnum'
import selectColorEnum from '../../../../../enums/selectEnums/SelectColorEnum'
import styles from '../../../../../styles'
import FormSelectField from '../../../../../components/fields/FormSelectField'

const useStyles = makeStyles(
    (theme) => (
        {
            submitStyle: {
                marginTop: 30
            },
            fieldStyle: {
                marginTop: 10
            },
            menuItem: {
                ...styles(theme).text4,
                color: theme.palette.blue.main
            }
        }
    )
)

const UserForm = () => {
    const classes = useStyles()
    const intl = useIntl()
    const { request, nextStep } = useContext(MakeRequestContext)
    const { isLargeScreen } = useContext(ResponsiveContext)

    const validate = (values: Pick<MakeRequestModel, 'lastName' | 'firstName' | 'position' | 'mail'>) => {
        const errors: any = {}

        if (!values.lastName) {
            errors.lastName = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required field'
                }
            )
        }
        if (!values.firstName) {
            errors.firstName = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required field'
                }
            )
        }
        if (!values.position || (values.position && values.position.length === 0)) {
            errors.position = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required field'
                }
            )
        }
        if (!values.mail) {
            errors.mail = intl.formatMessage(
                {
                    id: 'global.errors.required',
                    defaultMessage: 'Requis*',
                    description: 'Required field'
                }
            )
        } else if (!isEmailOk(values.mail)) {
            errors.mail = intl.formatMessage(
                {
                    id: 'global.errors.formatMail',
                    defaultMessage: 'Le format du mail n\'est pas correct',
                    description: 'Incorrect mail'
                }
            )
        }

        return errors
    }

    const onSubmit = (values: Pick<MakeRequestModel, 'lastName' | 'firstName' | 'position' | 'mail'>) => {
        request.lastName = values.lastName
        request.firstName = values.firstName
        request.position = values.position
        request.mail = values.mail

        nextStep()
    }

    return (
        <Form
            mutators={{
                ...arrayMutators
            }}
            validate={validate}
            onSubmit={onSubmit}
            initialValues={
                {
                    position: ''
                }
            }
            render={
                (
                    {
                        handleSubmit
                    }
                ) => (
                    <form onSubmit={handleSubmit}>
                        {
                            objectGetKeys(firstStep).map(
                                (stepValue) => (
                                    <div
                                        className={
                                            classNames(
                                                'row',
                                                classes.fieldStyle
                                            )
                                        }
                                        key={stepValue}
                                    >
                                        <div className="col-xs-12">
                                            {
                                                stepValue === firstStep.position ? (
                                                    <Field
                                                        name={stepValue}
                                                        component={FormSelectField}
                                                        id={stepValue}
                                                        backgroundColor={selectColorEnum.grey}
                                                    >
                                                        <MenuItem
                                                            value=""
                                                            className={classes.menuItem}
                                                            disabled
                                                        >
                                                            <FormattedMessage
                                                                id="popinYf.makeRequest.firstStep.position"
                                                                defaultMessage="Votre fonction"
                                                                description="Default item value"
                                                            />
                                                        </MenuItem>

                                                        {
                                                            objectGetKeys(positionEnum).map(
                                                                (position) => (
                                                                    <MenuItem
                                                                        value={
                                                                            intl.formatMessage(
                                                                                {
                                                                                    id: `popinYf.makeRequest.firstStep.positions.${position}`,
                                                                                    defaultMessage: 'Associé',
                                                                                    description: 'Item value'
                                                                                }
                                                                            )
                                                                        }
                                                                        key={position}
                                                                        className={classes.menuItem}
                                                                    >
                                                                        <FormattedMessage
                                                                            id={`popinYf.makeRequest.firstStep.positions.${position}`}
                                                                            defaultMessage="Associé"
                                                                            description="Item value"
                                                                        />
                                                                    </MenuItem>
                                                                )
                                                            )
                                                        }
                                                    </Field>
                                                ) : (
                                                    <Field
                                                        name={stepValue}
                                                        component={FormTextField}
                                                        id={stepValue}
                                                        placeholder={
                                                            {
                                                                id: `popinYf.makeRequest.firstStep.${stepValue}`
                                                            }
                                                        }
                                                    />
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            )
                        }

                        <div className="row end-xs">
                            <div
                                className={
                                    classNames(
                                        'col-xs-4',
                                        classes.submitStyle
                                    )
                                }
                            >
                                <Button
                                    background="backgroundAccessibility"
                                    color="colorWhite"
                                    textStyle={isLargeScreen ? 'text7' : 'text8'}
                                    type="submit"
                                    size="mediumButton"
                                >
                                    <FormattedMessage
                                        id="global.btn.next"
                                        defaultMessage="Suivant"
                                        description="Next button"
                                    />
                                </Button>
                            </div>
                        </div>
                    </form>
                )
            }
        />
    )
}

export default UserForm