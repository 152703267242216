import React from 'react'
import { groupedLinksEnum } from './enum/menuEnum'
import links from '../../enums/linksEnum'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'
import Badge from '../badge/Badge'
import { badgeEnum } from '../badge/enum/badgeEnum'

interface AcceptingProps {
	menu: string
}

type MenuItemProps = AcceptingProps

const MenuItem: React.FC<MenuItemProps> = (
	{
		menu
	}
) => {
	const selections = useSelector((state: RootState) => state.selection)

	return (
		<>
			{
				groupedLinksEnum[menu as keyof typeof groupedLinksEnum].includes(links.selection) ? (
					<Badge
						badgeContent={selections.selections.visits.length + selections.selections.selectedOffers.length}
						type={badgeEnum.menu}
					>
						<FormattedMessage
							id={`menu.${menu}`}
							defaultMessage={menu}
							description="Menu item"
						/>
					</Badge>
				) : (
					<FormattedMessage
						id={`menu.${menu}`}
						defaultMessage={menu}
						description="Menu item"
					/>
				)
			}
		</>
	)
}

export default MenuItem
