import { FavoritesTypes } from '../types/types'
import { CLOSE_TOOLTIP, MANAGE_FAVORITES, SAVE_FAVORITES } from './actionTypes'
import { FavoritesReducerModel } from '../types/favoritesModel'

const initialState: FavoritesReducerModel = {
    favorites: [],
    displayTooltip: false
}

const favorites = (
    state: FavoritesReducerModel = initialState,
    action: FavoritesTypes
): FavoritesReducerModel => {
    switch (action.type) {
        case SAVE_FAVORITES: {
            return 'payload' in action && Array.isArray(action.payload) ? {
                favorites: action.payload,
                displayTooltip: false
            } : initialState
        }
        case MANAGE_FAVORITES: {
            if ('payload' in action) {
                const payload = action.payload

                if (!Array.isArray(payload) && payload.selected) {
                    return {
                        favorites: [
                            ...state.favorites,
                            {
                                ...payload
                            }
                        ],
                        displayTooltip: state.favorites.length === 0
                    }
                } else if (!Array.isArray(payload) && !payload.selected) {
                    const favorites = state.favorites

                    const index = favorites.findIndex(
                        (favorite) => favorite.id === payload.id
                    )

                    if (index >= 0) {
                        favorites.splice(index, 1)
                    }

                    return {
                        favorites: favorites,
                        displayTooltip: false
                    }
                }
            }

            return state
        }
        case CLOSE_TOOLTIP: {
            return {
                favorites: [...state.favorites],
                displayTooltip: false
            }
        }
        default:
            return state
    }
}

export default favorites