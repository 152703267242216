import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import errorsTypeEnum, { ErrorsTypeModel } from '../../enums/errorsTypeEnum'
import { FormattedMessage } from 'react-intl'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { objectGetValues } from '../../utils/ieCompatibility'

const useStyle = makeStyles(
	(theme) => styles(theme)
)

interface AcceptingProps {
	statusCode: number
}

type ErrorsType = AcceptingProps

const Errors: React.FC<ErrorsType> = (
	{
		statusCode
	}
) => {
	const classes = useStyle()
	const error: ErrorsTypeModel = objectGetValues(errorsTypeEnum).filter(
		(error) => error.status === statusCode
	)[0] || {
		status: 500,
		text: 'errors.default'
	}

	const statusClass: ClassValue = classNames(
		classes.title2,
		classes.colorBlue
	)

	const messageClass: ClassValue = classNames(
		classes.text8,
		classes.colorBlue
	)

	return (
		<div className={classes.textCenter}>
			<h1 className={statusClass}>{error.status}</h1>

			<h2 className={messageClass}>
				<FormattedMessage
					id={error.text}
					defaultMessage="Unauthorized"
					description="Error message"
				/>
			</h2>
		</div>
	)
}

export default Errors
