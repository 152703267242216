import { TokenTypes } from '../types/types'
import { SAVE_TOKEN } from './actionTypes'

const initialState: string = ''

const token = (
	state: string = initialState,
	action: TokenTypes
): string => {
	switch (action.type) {
		case SAVE_TOKEN: {
			return 'payload' in action ? action.payload : initialState
		}
		default:
			return state
	}
}

export default token
