import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'

export const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			cguContainerLargeScreenStyle: {
				padding: '0px 100px 100px'
			},
			cguContainerSmallScreenStyle: {
				padding: '0px 25px 25px'
			},
			detailsContainerStyle: {
				width: '100%',

				'& h1': {
					margin: 0,
					color: theme.palette.blue.main,
					wordBreak: 'break-word'
				},
				'& h2': {
					color: theme.palette.blue.main,
					wordBreak: 'break-word'
				},
				'& h3': {
					color: theme.palette.blue.main,
					wordBreak: 'break-word'
				},
				'& p, & li': {
					color: theme.palette.blue.main,
					wordBreak: 'break-word'
				},
				'& table': {
					width: '100%',
					borderCollapse: 'collapse',

					'& caption': {
						color: theme.palette.blue.main,
						textAlign: 'left'
					},
					'& thead': {
						background: 'white',

						'& th': {
							color: theme.palette.blue.main,
							textAlign: 'left'
						}
					},
					'& tbody tr': {
						background: theme.palette.blue['5'],

						'& td': {
							color: theme.palette.blue.main
						}
					},
					'& tbody tr:nth-child(2)': {
						background: theme.palette.blue['10']
					}
				},

				'& ul, & ol': {
					paddingLeft: 15,
					listStyle: 'url("img/listIcon.svg")'
				}
			},
			detailsContainerLargeScreenStyle: {
				'& p, & h1, & h2, & h3, & ul, & ol, & .encart': {
					marginTop: 0,
					marginBottom: 25
				},
				'& h1': styles(theme).title2,
				'& h2': styles(theme).title3,
				'& h3': styles(theme).text11,
				'& p, & li': styles(theme).text1,

				'& table': {
					'& caption': {
						...styles(theme).title3,
						marginBottom: 20
					},
					'& thead tr': {
						height: 43,

						'& th': {
							...styles(theme).text8,
							paddingLeft: 27
						}
					},
					'& tbody tr': {
						height: 43,

						'& td': {
							...styles(theme).text15,
							paddingLeft: 27
						}
					}
				},

				'& ul, & ol': {
					'& li': {
						paddingLeft: 33
					}
				}
			},
			detailsContainerSmallScreenStyle: {
				'& p, & h1, & h2, & h3, & ul, & ol, & .encart': {
					marginTop: 0,
					marginBottom: 25
				},
				'& h1': styles(theme).title3,
				'& h2': styles(theme).title7,
				'& h3': styles(theme).title4,
				'& p, & li': styles(theme).text2,

				'& table': {
					'& caption': {
						...styles(theme).title7,
						marginBottom: 15
					},
					'& thead tr': {
						height: 35,

						'& th': {
							...styles(theme).text4,
							paddingLeft: 15
						}
					},
					'& tbody tr': {
						height: 35,

						'& td': {
							...styles(theme).text3,
							paddingLeft: 15
						}
					}
				},

				'& ul, & ol': {
					'& li': {
						paddingLeft: 15
					}
				}
			},
			textContainerStyle: {
				'& > .link': {
					position: 'relative',
					background: 'white',

					'& > a': {
						position: 'absolute'
					}
				}
			},
			textContainerLargeScreenStyle: {
				'& > .link': {
					padding: '85px 95px',

					'& > a': {
						right: 95,
						top: 45
					}
				}
			},
			textContainerSmallScreenStyle: {
				'& > .link': {
					padding: '50px 25px 25px',

					'& > a': {
						top: 20,
						right: 25
					}
				}
			},
			titleStyle: {
				marginTop: '10px !important'
			}
		}
	)
)
