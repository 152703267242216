import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import SearchboxField from './SearchboxField'
import { CITIES } from '../../offers/utils/commonConst'

const SearchBoxCity = () => {
	return (
		<FieldArray
			name={CITIES}
			render={
				({ fields, meta }) => (
					<SearchboxField
						fields={fields}
						meta={meta}
						id="searchbox"
					/>
				)
			}
		/>
	)
}

export default SearchBoxCity