import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core'

const CustomDialogContent = withStyles(
	(theme) => (
		{
			root: {
				padding: 0,

				'&:first-child': {
					paddingTop: 0
				}
			}
		}
	)
)(DialogContent)

interface AcceptingProps {
	onCloseDialog: () => void,
	open: boolean,
	children: React.ReactChild,
	title?: string,
	fullScreen?: boolean,
	maxWidth?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined
}

type DialogProps = AcceptingProps

const Dialog: React.FC<DialogProps> = (
	{
		onCloseDialog,
		open,
		children,
		title,
		fullScreen = true,
		maxWidth = 'sm'
	}
) => {

	const handleClose = () => {
		onCloseDialog()
	}

	return (
		<MuiDialog
			open={open}
			onClose={handleClose}
			fullScreen={fullScreen}
			maxWidth={maxWidth}
		>
			{
				title &&
				<DialogTitle>
					{title}
				</DialogTitle>
			}
			<CustomDialogContent>
				{children}
			</CustomDialogContent>
		</MuiDialog>
	)
}

export default Dialog
