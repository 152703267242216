import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { Theme } from '@material-ui/core/styles'

const useStyles = makeStyles<Theme, {color: string | undefined}>(
    (theme) => ({
            ...styles(theme),
            circularProgressStyle: {
                width: '100%',
                height: '100%',
                marginBottom: 20,

                '& div': {
                    width: '100px !important',
                    height: '100px !important'
                }
            },
            circularProgressColor: {
                '& svg': {
                    color: props => props.color ?? theme.palette.green.main
                }
            }
        }
    )
)

interface AcceptingProps {
    size?: number | string,
    color?: string
}

type loaderProps = AcceptingProps

const Loader: React.FC<loaderProps> = (
    {
        size,
        color
    }
) => {
    const classes = useStyles({color})

    const circularProgressClass: ClassValue = classNames(
        classes.circularProgressStyle,
        classes.flex,
        classes.alignCenter,
        classes.flexCenter
    )

    return (
        <div
            className={
                classNames(
                    {
                        [circularProgressClass]: !size,
                        [classes.circularProgressColor]: color
                    }
                )
            }
        >
            <CircularProgress size={size} />
        </div>
    )
}

export default Loader