import { SelectionModel } from '../../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../../components/offerDetails/types/offerDetailsModel'
import { CitiesModel, SearchFormModel } from '../../../research/types/searchFormModel'
import { parisPolygon, polygons } from './polygons'

const PARIS_LAT = 48.856614
const PARIS_LNG = 2.3522219

const containsLocation = (lat: number, lng: number, mapsPolygon: any) => {
    const point = new google.maps.LatLng(lat, lng)

    return google.maps.geometry.poly.containsLocation(
        point,
        mapsPolygon
    )
}

const isParis = (city: CitiesModel) => {
    return city.lat === PARIS_LAT && city.lng === PARIS_LNG
}

export const offersFilter = (offers: (SelectionModel & OfferDetailsModel)[], formData: SearchFormModel, isApiLoaded: boolean) => {
    const offersMatching: (SelectionModel & OfferDetailsModel)[] = []
    const offersNotMatching: (SelectionModel & OfferDetailsModel)[] = []
    const dateDisponibilities: Date[] = []

    formData.disponibility.length > 0 && formData.disponibility[0].split(',').forEach(
        (disponibility) => {
            const [dayDisponibility, monthDisponibility, yearDisponibility] = disponibility.split('/')
            dateDisponibilities.push(new Date(+yearDisponibility, +monthDisponibility - 1, +dayDisponibility))
        }
    )

    const { min, max } = formData.surface

    const isStrictByDate = (offerDate: Date, isStrict: boolean) => dateDisponibilities.some(
        (disponibility) => {
            if (disponibility === new Date() || new Date(disponibility.getFullYear(), disponibility.getMonth() - 5, 1) < new Date()) {
                return offerDate <= disponibility
            }

            const minMonth = isStrict ? disponibility.getMonth() - 5 : disponibility.getMonth() - 11
            return new Date(disponibility.getFullYear(), minMonth, 1) <= offerDate && disponibility >= offerDate
        }
    )

    offers.forEach(
        (offer) => {
            let hasMatched: boolean = false

            if (isApiLoaded && formData.cities.length > 0 && Number(offer.availableArea) > min && Number(offer.divisibleFrom) < max && isStrictByDate(new Date(offer.indicativeAvailability), false)) {
                formData.cities.forEach(
                    (city, index) => {
                        polygons.forEach(
                            (polygon) => {
                                const mapsPolygon = new google.maps.Polygon(
                                    {
                                        paths: polygon
                                    }
                                )

                                if (containsLocation(city.lat, city.lng, mapsPolygon)) {
                                    if (containsLocation(offer.latitude, offer.longitude, mapsPolygon)) {
                                        hasMatched = true
                                    }
                                }
                            }
                        )

                        if (isParis(city)) {
                            const mapsPolygon = new google.maps.Polygon(
                                {
                                    paths: parisPolygon
                                }
                            )

                            if (containsLocation(offer.latitude, offer.longitude, mapsPolygon)) {
                                hasMatched = true
                            }
                        }

                        if (!hasMatched) {
                            const circle = new google.maps.Circle(
                                {
                                    center: {
                                        lat: city.lat,
                                        lng: city.lng
                                    },
                                    radius: 2000
                                }
                            )

                            const point = new google.maps.LatLng(offer.latitude, offer.longitude)
                            if (
                                google.maps.geometry.spherical.computeDistanceBetween(
                                    point,
                                    circle.getCenter()
                                ) <= circle.getRadius()
                            ) {
                                hasMatched = true
                            }
                        }

                        if (index === formData.cities.length - 1) {
                            hasMatched && isStrictByDate(new Date(offer.indicativeAvailability), true) ? offersMatching.push(offer) : offersNotMatching.push(offer)
                        }
                    }
                )
            } else if (formData.cities.length > 0 && Number(offer.availableArea) > min && Number(offer.divisibleFrom) < max && isStrictByDate(new Date(offer.indicativeAvailability), false)) {
                offersNotMatching.push(offer)
            } else if (formData.cities.length === 0 && Number(offer.availableArea) > min && Number(offer.divisibleFrom) < max && isStrictByDate(new Date(offer.indicativeAvailability), false)) {
                if (isStrictByDate(new Date(offer.indicativeAvailability), true)) {
                    offersMatching.push(offer)
                } else {
                    offersNotMatching.push(offer)
                }
            }
        }
    )

    return {
        matching: offersMatching,
        notMatching: offersNotMatching
    }
}
