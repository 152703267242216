import React, { useContext } from 'react'
import { DeckServices } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import DeckList from './DeckList'
import styles from '../../../styles'
import parse from 'html-react-parser'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => (
		{
			servicesContainerStyle: {
				margin: 'auto',

				'& > div:first-child': {
					'& h1': {
						margin: 0,
						color: theme.palette.blue.main,
					}
				},
				'& > div:nth-child(2)': {
					'& h2': {
						margin: 0,
						color: theme.palette.blue.main,
						...styles(theme).text13
					},
					'& span': {
						...styles(theme).text20
					},
					'& p': {
						margin: 0
					}
				}
			},
			servicesContainerLargeScreenStyle: {
				maxWidth: 1100,
				marginTop: 115,

				'& > div:first-child': {
					'& h1': {
						marginBottom: 50,
						...styles(theme).title1
					}
				},
				'& > div:nth-child(2)': {
					marginBottom: 60
				}
			},
			servicesContainerSmallScreenStyle: {
				marginTop: 65,

				'& > div:first-child': {
					'& h1': {
						marginBottom: 30,
						...styles(theme).title2
					}
				},
				'& > div:nth-child(2)': {
					marginBottom: 30,

					'& > div:first-child': {
						marginBottom: 20
					}
				}
			}
		}
	)
)

interface AcceptingProps {
	services: DeckServices
}

type deckServicesProps = AcceptingProps

const DeckServicesPage: React.FC<deckServicesProps> = (
	{
		services
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const servicesContainerClass: ClassValue = classNames(
		classes.servicesContainerStyle,
		isLargeScreen ? classes.servicesContainerLargeScreenStyle : classes.servicesContainerSmallScreenStyle
	)

	return (
		<div className={servicesContainerClass}>
			<div className="row">
				<div className="col-xs-12">
					<h1>
						<FormattedMessage
							id="deck.services.title"
							defaultMessage="Services"
							description="Services title"
						/>
					</h1>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12">
					<span>{parse(services.text)}</span>
				</div>
			</div>

			{
				services.list.map(
					(item, index) => (
						<DeckList
							key={item.title}
							item={item}
							index={index}
						/>
					)
				)
			}
		</div>
	)
}

export default DeckServicesPage