import React, { useContext, useMemo, useState } from 'react'
import { selectionEnum, selectionStatusEnum } from '../../../../modules/selection/enum/selectionEnum'
import GoogleMapReact from 'google-map-react'
import { PinModel } from '../../../../modules/selection/types/pinModel'
import SvgLocation from '../../../../icons/Location'
import { useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../index'
import { makeStyles } from '@material-ui/core/styles'
import InfoWindow from './InfoWindow'
import Link from 'react-scroll/modules/components/Link'
import SvgLocationFavorite from '../../../../icons/LocationFavorite'
import classNames from 'classnames'
import './infoWindowStyles.css'
import { OfferDetailsModel } from '../../../offerDetails/types/offerDetailsModel'
import { SelectionModel, SelectionReducerModel } from '../../../../modules/selection/types/selectionModel'
import { ResponsiveContext } from '../../../../utils/context/responsiveContext'
import { API_KEY } from './utils/globalConst'
import { useLocation } from 'react-router'
import links from '../../../../enums/linksEnum'
import { offersFilter } from '../../../../modules/offers/utils/map/offersFilter'
import { getFormData } from '../../../../modules/research/utils/getFormData'
import { OffersContext } from '../../../../modules/offers/utils/context/offersContext'
import { objIsNatureType } from '../../../../modules/research/utils/checkObjType'
import { natureIdEnum } from '../../../../enums/natureEnum'
import { POIs } from './enum/listPOI'
import { POIModel } from './types/POIModel'

const useStyles = makeStyles(
	(theme) => (
		{
			svgStyle: {
				cursor: 'pointer',
				transform: 'translate(-50%, -50%)'
			},
			iconContainerStyle: {
				'& a::before': {
					display: 'none'
				}
			},
			poiStyle: {
				transform: 'translate(-50%, -50%)',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				position: 'relative',
				zIndex: -1
			}
		}
	)
)

interface CenterModel {
	lat: number,
	lng: number
}

interface AcceptingProps {
	selections: SelectionReducerModel | (SelectionModel & OfferDetailsModel)[],
	hasTransit: boolean
}

const COORD_PARIS = {
	lat: 48.866667,
	lng: 2.333333
}

type mapComponentProps = AcceptingProps

const MapComponent: React.FC<mapComponentProps> = (
	{
		selections,
		hasTransit
	}
) => {
    const classes = useStyles()
    const theme = useTheme()
    const location = useLocation()
    const isCardHovered = useSelector((state: RootState) => state.isCardHover)
    const favorites = useSelector((state: RootState) => state.favorites)
    const { isLargeScreen } = useContext(ResponsiveContext)
    const { isApiLoaded, setIsApiLoaded } = useContext(OffersContext)

	const layerTypes: string[] = []
	let groupedSelections: (SelectionModel & OfferDetailsModel)[] = []

	groupedSelections = useMemo(
		() => {
			if ('selections' in selections) {
				return selections.selections.visits.concat(selections.selections.selectedOffers)
			}

			return selections
		}, [selections]
	)

	if (hasTransit) {
		layerTypes.push('TransitLayer')
	}

	const [defaultCenter, setDefaultCenter] = useState<CenterModel>(
		{
			lat: COORD_PARIS.lat,
			lng: COORD_PARIS.lng
		}
	)
	const [itemOpened, setItemOpened] = useState<string | null>(null)
	const [zoomLvl, setZoomLvl] = useState<number>(14)

    const onChildClicked = (key: (SelectionModel & OfferDetailsModel)) => {
        if (itemOpened === key.id) {
            setItemOpened(null)
        } else {
            setDefaultCenter(
                {
                    lat: key.latitude,
                    lng: key.longitude
                }
            )
            setItemOpened(key.id)
        }
    }

	const selectionType = (selection: (SelectionModel & OfferDetailsModel)) => {
		if ('selections' in selections) {
			if (selections.selections.visits.findIndex(
				(visit) => visit.id === selection.id
			) >= 0) {
				return selectionEnum.visits
			}

			return selectionEnum.selectedOffers
		} else if (location.pathname.includes(links.offers) || location.pathname.includes(links.research)) {
			const formData = getFormData(location.search)
			const offersMatching = []

			if (formData && !objIsNatureType(formData)) {
				const { matching } = offersFilter(selections.filter((selection) => selection.status === selectionStatusEnum.available && selection.offer_nature !== natureIdEnum.retail), formData, isApiLoaded)

				offersMatching.push(...matching)
			} else if (formData && objIsNatureType(formData)) {
				offersMatching.push(...selections.filter((selection) => selection.offer_nature === natureIdEnum.retail && selection.status === selectionStatusEnum.available))
			} else {
				offersMatching.push(...selections.filter((selection) => selection.status === selectionStatusEnum.available && selection.offer_nature !== natureIdEnum.retail))
			}

			const index = offersMatching.findIndex((offer) => offer.id === selection.id)

			if (index >= 0) {
				return selectionEnum.visits
			}
		}

		return selectionEnum.selectedOffers
	}

	const PinComponent = (pin: PinModel) => {
		const isOpened = pin.item.id === itemOpened
		const isItemHovered = isCardHovered.isHovered && isCardHovered.offerHovered === pin.item.id
		const isFavorites = favorites.favorites.findIndex((favorite) => favorite.id === pin.item.id) >= 0

		if (isFavorites) {
			return (
				<div className={classes.iconContainerStyle}>
					<Link
						to={pin.item.id}
						onClick={() => onChildClicked(pin.item)}
						offset={isLargeScreen ? -100 : -65}
						smooth
					>
						<SvgLocationFavorite
							color={theme.palette.blue.main}
							secondColor="#FFFFFF"
							width={35}
							height={35}
							className={
								classNames(
									classes.svgStyle,
									{
										'itemHovered': isItemHovered
									}
								)
							}
						/>
					</Link>

					{isOpened && <InfoWindow item={pin.item} />}
				</div>
			)
		}

		return (
			<div className={classes.iconContainerStyle}>
				<Link
					to={pin.item.id}
					onClick={() => onChildClicked(pin.item)}
					offset={isLargeScreen ? -100 : -65}
					smooth
				>
					<SvgLocation
						color={pin.type === selectionEnum.visits ? theme.palette.green.main : '#FFFFFF'}
						secondColor={pin.type === selectionEnum.visits ? '#FFFFFF' : theme.palette.blue.main}
						height={40}
						width={30}
						className={
							classNames(
								classes.svgStyle,
								{
									'itemHovered': isItemHovered
								}
							)
						}
						borderColor={pin.type === selectionEnum.visits ? theme.palette.green.main : theme.palette.blue.main}
					/>
				</Link>

				{isOpened && <InfoWindow item={pin.item} />}
			</div>
		)
	}

	const POIComponent = (poi: POIModel) => (
		<div className={classes.poiStyle}>
			{poi.icon}
		</div>
	)

    return (
        <GoogleMapReact
            bootstrapURLKeys={
                {
                    key: API_KEY,
                    libraries: ['places', 'geometry']
                }
            }
            center={defaultCenter}
            layerTypes={layerTypes}
            defaultZoom={12}
            onZoomAnimationEnd={(zoomLvl: number) => setZoomLvl(zoomLvl)}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={
                ({ map, maps }) => setIsApiLoaded(true)
            }
            options={
                {
                    styles: [
                        {
                            featureType: 'poi',
                            elementType: 'labels',
                            stylers: [{ 'visibility': 'off' }]
                        }
                    ]
                }
            }
            onClick={() => setItemOpened(null)}
        >
            {
                groupedSelections.map(
                    (item) => (
                        <PinComponent
                            key={item.id}
                            lat={item.latitude}
                            lng={item.longitude}
                            type={selectionType(item)}
                            item={item}
                        />
                    )
                )
            }

			{
				zoomLvl >= 14 && POIs.map(
					(poi) => (
						<POIComponent
							key={poi.name}
							{...poi}
						/>
					)
				)
			}
		</GoogleMapReact>
	)
}

export default MapComponent
