enum links {
	checkToken = '/token',
	homepage = '/homepage',
	selection = '/selection',
	research = '/research',
	offers = '/offers',
	deskDetails = '/desk',
	footer = '/footer',
	favorites = '/favorites'
}

export default links