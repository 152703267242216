import React, { useContext, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../styles'
import { ButtonBase, useTheme } from '@material-ui/core'
import Icon from '../../../components/icon/Icon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import YFManagerDetails from './YFManagerDetails'
import iconsEnum from '../../../enums/iconsEnum'
import { useDispatch, useSelector } from 'react-redux'
import YFDisplay from './YFDisplay'
import { RootState } from '../../../index'
import { isModalVisible } from '../../../components/dialog/reducer/selector'
import { modalTypes } from '../../../components/dialog/enum/modalTypes'
import { displayModal, hideModal } from '../../../components/dialog/reducer/actions'
import { typeYfDisplay } from '../enum/yfManagerEnums'
import { FormattedMessage } from 'react-intl'
import { ThemeContext } from '../../../utils/context/themeContext'
import links from '../../../enums/linksEnum'
import { useLocation } from 'react-router-dom'
import { ThemeType } from '../../../theme'

const GREY_BUTTON: string = 'greyButton'
const GREEN_BUTTON: string = 'greenButton'
const ICON_PLUS: string = iconsEnum.Plus

const Accordion = withStyles({
    root: {
        boxShadow: '0px -16px 32px 8px rgba(0, 0, 0, 0.2)',
        transition: 'width 300ms',
        backgroundColor: 'white',
        '&$expanded': {
            width: '570px !important',
            margin: 0
        }
    },
    rounded: {
        '&:last-child': {
            borderBottomLeftRadius: 40,
            borderBottomRightRadius: 40
        },
        '&:first-child': {
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40
        }
    },
    expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.blue.main,
        padding: 0,
        borderRadius: 40,
        transitionDuration: '300ms',
        color: 'white'
    },
    content: {
        transform: 'rotate(180deg)',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 8,
        '& svg': {
            fill: 'white'
        },

        '& #greenButton': {
            transitionDuration: '300ms',
            '& svg': {
                fill: theme.palette.textAccessibility.main,
                width: 16,
                height: 16
            }

        },
        '& #greyButton': {
            transitionDuration: '300ms',
            backgroundColor: theme.palette.blue['5'],
            '& svg': {
                fill: theme.palette.blue.main,
                width: 16,
                height: 16
            },
            transform: 'rotate(-45deg)'
        },
        '&$expanded': {
            margin: 8
        }

    },
    expanded: {}
}))(MuiAccordionSummary)

const AccordionDetails = withStyles({
    root: {
        transform: 'rotate(180deg)',
        padding: 0
    }
})(MuiAccordionDetails)

const useStyle = makeStyles<ThemeType, {smallPopin: boolean}>(
    (theme) => (
        {
            ...styles(theme),
            buttonLargeScreen: {
                width: 48,
                height: 48,
                borderRadius: 40,
                zIndex: 1121
            },
            yfLabel: {
                display: 'flex',
                alignItems: 'center',
                transitionDuration: '300ms'
            },
            yfContainer: {
                transform: 'rotate(180deg)',
                position: 'fixed',
                zIndex: 1120,
                bottom: 24,
                right: 40
            },
            yfTitleDiv: {
                flexDirection: 'column',
                marginLeft: 20,
                whiteSpace: 'nowrap'
            },
            yfSubTitleDiv: {
                marginTop: 2,
                whiteSpace: 'nowrap'
            },
            icon: {
                fill: 'white'
            },
            accordionStyle: {
                width: props => props.smallPopin ? 60 : 320,
                '&:hover': {
                    width: props => props.smallPopin ? 60 : 350
                }
            }
        }
    )
)

const SMALL_POPIN = [links.selection, links.favorites, links.offers]

const YFManagerPopIn = () => {
    const location = useLocation()
    const classes = useStyle({smallPopin: SMALL_POPIN.includes(location.pathname as links) || (location.pathname === links.research && location.search.length > 0)})
    const theme = useTheme()
    const isOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.youFirstManager))
    const yfManager = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    const { isVisualHandicap } = useContext(ThemeContext)

    const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false)

    const titlePopIn = classNames(
        classes.title5
    )
    const subTitlePopIn = classNames(
        classes.text3
    )
    const containerPopIn = classNames(
        classes.yfContainer
    )
    const buttonClass = classNames(
        classes.buttonLargeScreen
    )

    const handleExpand = () => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        if (isExpanded) {
            dispatch(
                displayModal(modalTypes.youFirstManager)
            )
        } else {
            dispatch(
                hideModal(modalTypes.youFirstManager)
            )
            setIsDisplayForm(false)
        }
    }

    return (
        <>
            {
                <div className={containerPopIn}>
                    <Accordion
                        TransitionProps={{
                            timeout: {
                                appear: 300,
                                enter: 300,
                                exit: 300
                            }
                        }}
                        onChange={handleExpand()}
                        expanded={isOpen}
                        className={classes.accordionStyle}
                    >
                        <AccordionSummary>
                            {
                                (SMALL_POPIN.includes(location.pathname as links) || (location.pathname === links.research && location.search.length > 0)) && !isOpen ? (
                                    <ButtonBase className={buttonClass}>
                                        <YFDisplay type={typeYfDisplay.POPIN}/>
                                    </ButtonBase>
                                ) : (
                                    <>
                                        <div className={classes.yfLabel}>
                                            <YFDisplay type={typeYfDisplay.POPIN}/>
                                            <div className={classes.yfTitleDiv}>
                                                <span className={titlePopIn}>
                                                    {yfManager.firstname} {yfManager.lastname}
                                                </span>

                                                <div className={classes.yfSubTitleDiv}>
                                                    <span className={subTitlePopIn}>
                                                        <FormattedMessage
                                                            id="popinYf.subtitle"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <ButtonBase
                                            id={isOpen ? GREY_BUTTON : GREEN_BUTTON}
                                            className={buttonClass}
                                            style={
                                                !isOpen ? isVisualHandicap ? {
                                                    backgroundColor: 'white'
                                                } : {
                                                    backgroundColor: theme.palette.green.main
                                                } : undefined
                                            }
                                        >
                                            <Icon icon={ICON_PLUS} size={12}/>
                                        </ButtonBase>
                                    </>
                                )
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <YFManagerDetails
                                isDisplayForm={isDisplayForm}
                                onDisplayForm={setIsDisplayForm}
                            />
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </>
    )
}

export default YFManagerPopIn