import errorCatcher from '../../../utils/errorCatcher'
import httpResponse, { httpStatus } from '../../../enums/httpResponseEnum'
import { Dispatch } from 'react'

interface TokenResponseModel {
	statut: string,
	token: string
}

const GET_TOKEN = 'get_token'

export const checkToken = (token: string, dispatch: Dispatch<any>) => {
	return new Promise(
		(resolve, reject) => {
			fetch(`/cms/api/token/${token}/validate`)
				.then(
					(response) => {
						if (response.ok) {
							response.json()
								.then(
									(token: TokenResponseModel) => {
										if (token.statut === httpResponse.ok) {
											resolve(token.token)
										} else {
											reject(500)
										}
									}
								)
								.catch(
									(error) => {
										dispatch(
											errorCatcher(error, GET_TOKEN)
										)

										reject()
									}
								)
						} else {
							if (response.status === httpStatus.unauthorized || response.status === httpStatus.internal || response.status === httpStatus.notFound) {
								reject(response.status)
							} else {
								response.json()
									.then(
										(data: any) => {
											dispatch(
												errorCatcher(data, GET_TOKEN)
											)

											reject()
										}
									)
									.catch(
										(error: any) => {
											dispatch(
												errorCatcher(error, GET_TOKEN)
											)

											reject()
										}
									)
							}
						}
					}
				)
				.catch(
					(error) => {
						dispatch(
							errorCatcher(error, GET_TOKEN)
						)

						reject()
					}
				)
		}
	)
}