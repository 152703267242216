import { SelectionReducerModel } from '../types/selectionModel'
import { IsCardHovered, SelectionTypes } from '../types/types'
import { IS_CARD_HOVERED, LOAD_SELECTION, SAVE_SELECTION } from './actionTypes'
import { IsCardHoveredModel } from '../types/isCardHoveredModel'

const initialState: SelectionReducerModel = {
	selections: {
		visits: [],
		selectedOffers: []
	},
	loaded: false
}

export const isCardHoveredInitialState: IsCardHoveredModel = {
	isHovered: false,
	offerHovered: ''
}

const selection = (
	state: SelectionReducerModel = initialState,
	action: SelectionTypes
): SelectionReducerModel => {
	switch (action.type) {
		case SAVE_SELECTION: {
			return 'payload' in action && 'selections' in action.payload ? action.payload : initialState
		}
		case LOAD_SELECTION: {
			return {
				...state,
				loaded: false
			}
		}
		default:
			return state
	}
}

export const isCardHover = (
	state: IsCardHoveredModel = isCardHoveredInitialState,
	action: IsCardHovered
): IsCardHoveredModel => {
	switch (action.type) {
		case IS_CARD_HOVERED: {
			return 'payload' in action ? action.payload : isCardHoveredInitialState
		}
		default:
			return state
	}
}

export default selection
