import React from 'react'
import OffersDisplay from '../../components/offersDisplay/OffersDisplay'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'
import FavoritesComponent from './components/FavoritesComponent'
import { SelectionModel } from '../selection/types/selectionModel'
import { OfferDetailsModel } from '../../components/offerDetails/types/offerDetailsModel'

const Favorites = () => {
	const favorites = useSelector((state: RootState) => state.favorites)

	return (
		<OffersDisplay
			selections={
				favorites.favorites.sort(
					(a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
						const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : Number(a.divisibleFrom)
						const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : Number(b.divisibleFrom)

						return tmpB - tmpA
					}
				)
			}
			offers={<FavoritesComponent />}
		/>
	)
}

export default Favorites