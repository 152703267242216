import fetchFactory from '../../../utils/fetchFactory'
import { configEnum } from '../../../enums/fetchFactoryEnum'

export const manageFavorites = (userId: string, offerId: string, isSelected: boolean) => {
	return fetchFactory('/cms/api/favorites', {
		method: configEnum.post,
		body: JSON.stringify(
			{
				demandeId: userId,
				id: offerId,
				selected: isSelected ? 1 : 0
			}
		)
	})
}
