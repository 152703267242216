import React from 'react'
import { FormControl, InputBase, Select, SelectProps, withStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../styles'
import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import MenuItem from '@material-ui/core/MenuItem'
import selectColorEnum from '../../enums/selectEnums/SelectColorEnum'
import classNames from 'classnames'
import selectSizeEnum from '../../enums/selectEnums/SelectSizeEnum'

const CustomSelect = withStyles({
	select: {},
	iconOpen: {
		transform: 'none'
	}
})(Select)

const CustomInput = withStyles({
	root: {
		height: '100%'
	},
	input: {
		'&:focus': {
			backgroundColor: 'transparent',
			borderRadius: 40
		}
	}
})(InputBase)

const useStyle = makeStyles((theme) => ({
		...styles(theme),
		selectStyle: {
			width: '100%'
		},
		formControl: {
			minWidth: 120,
			borderRadius: 40,
			'& svg': {
				paddingRight: 24,
				width: 24
			}
		},
		smallFormControl: {
			minWidth: 82,
			borderRadius: 24,
			'& svg': {
				paddingRight: 18,
				marginLeft: 10,
				width: 18
			}
		},
		formColorGrey: {
			backgroundColor: theme.palette.blue['5']
		},
		formColorWhite: {
			backgroundColor: 'white'
		},
		dropDown: {
			minWidth: 120,
			boxShadow: '0 25px 50px 0 rgba(18, 41, 65, 0.1)'
		},
		smallDropDown: {
			minWidth: 82,
			boxShadow: '0 25px 50px 0 rgba(18, 41, 65, 0.1)'
		},
		menuItemRoot: {
			padding: '15px 24px',
			paddingRight: 72,
			'&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
				backgroundColor: theme.palette.accessibility.main,

				'& span': {
					color: 'white'
				}
			}
		},
		smallMenuItemRoot: {
			minHeight: 10,
			padding: '10px 18px',
			paddingRight: 46,
			'&$menuItemSelected, &$menuItemSelected:focus, &$menuItemSelected:hover': {
				backgroundColor: theme.palette.accessibility.main,

				'& span': {
					color: 'white'
				}
			}
		},
		menuItemSelected: {},
		bigCustomSelect: {
			display: 'flex',
			alignItems: 'center',
			padding: '15px 0px',
			paddingLeft: 20,
			'&.MuiSelect-select.MuiSelect-select': {
				paddingRight: 72
			}
		},
		smallCustomSelect: {
			display: 'flex',
			padding: '10px 0px',
			paddingLeft: 18,
			'&.MuiSelect-select.MuiSelect-select': {
				paddingRight: 46
			}
		},
		listPadding: {
			padding: 0
		}
	}
))

interface AcceptingProps {
	inputTextStyle?: string,
	value?: any,
	onChange?: SelectInputProps['onChange'],
	onBlur?: (event: any) => void,
	backgroundColor?: string,
	size?: string,
	multiple?: boolean
}

type SelectFieldProps = AcceptingProps & Pick<SelectProps, 'renderValue'>

const SelectField: React.FC<SelectFieldProps> = (
	{
		inputTextStyle,
		value,
		onChange,
		children,
		backgroundColor,
		size,
		multiple = false,
		renderValue,
		onBlur
	}
) => {
	const classes = useStyle()

	const whichColor = (backgroundColor: string | undefined) => {
		switch (backgroundColor) {
			case selectColorEnum.grey :
				return classes.formColorGrey
			case selectColorEnum.white:
				return classes.formColorWhite
			default :
				return classes.formColorWhite
		}
	}

	const whichFormControl = (size: string | undefined) => {
		switch (size) {
			case selectSizeEnum.big :
				return classes.formControl
			case selectSizeEnum.small :
				return classes.smallFormControl
			default :
				return classes.formControl
		}
	}

	const whichMenuItemRoot = (size: string | undefined) => {
		switch (size) {
			case selectSizeEnum.big :
				return classes.menuItemRoot
			case selectSizeEnum.small :
				return classes.smallMenuItemRoot
			default :
				return classes.menuItemRoot
		}
	}

	const whichCustomSelect = (size: string | undefined) => {
		switch (size) {
			case selectSizeEnum.big :
				return classes.bigCustomSelect
			case selectSizeEnum.small :
				return classes.smallCustomSelect
			default :
				return classes.bigCustomSelect
		}
	}

	const whichDropDown = (size: string | undefined) => {
		switch (size) {
			case selectSizeEnum.big :
				return classes.dropDown
			case selectSizeEnum.small :
				return classes.smallDropDown
			default :
				return classes.dropDown
		}
	}

	const formClass = classNames(
		whichFormControl(size),
		whichColor(backgroundColor),
		classes.selectStyle
	)

	const customClass = {
		classes: {
			root: whichMenuItemRoot(size),
			selected: classes.menuItemSelected
		},
		ListItemClasses: {
			root: inputTextStyle
		}
	}

	const modifyChildren = (children: React.ReactNode): any =>
		React.Children.map(children, child => {
			if (!React.isValidElement(child)) {
				return child
			}
			let elementChild: React.ReactElement = child
			if (elementChild.type === MenuItem) {
				return React.cloneElement(elementChild, customClass)
			}
			return elementChild
		})

	return (
		<FormControl className={formClass}>
			<CustomSelect
				value={value}
				onChange={onChange}
				onClose={onBlur}
				displayEmpty
				multiple={multiple}
				input={<CustomInput className={inputTextStyle} />}
				IconComponent={ExpandMoreIcon}
				MenuProps={{
					classes: {
						paper: whichDropDown(size),
						list: classes.listPadding
					},
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left'
					}
				}}
				classes={{
					select: whichCustomSelect(size)
				}}
				renderValue={renderValue}
			>
				{React.Children.map(children, child => modifyChildren(child))}
			</CustomSelect>
		</FormControl>
	)
}

export default SelectField