import { configEnum, responseTypes } from '../../../enums/fetchFactoryEnum'
import fetchFactory from '../../../utils/fetchFactory'
import { CustomDocDownloadModel } from '../types/customDocModel'

export const downloadCustomDoc = (data: CustomDocDownloadModel) => fetchFactory('/cms/fr/api/sharepoint/download',
    {
        method: configEnum.post,
        body: JSON.stringify(data)
    },
    responseTypes.blob
)