export enum typeYfManagerEnum {
    couple = 'couple',
    manager = 'manager',
    default = 'default'
}

export enum typeYfManagerRequestEnum {
    couple = 221310001,
    manager = 221310000
}

export enum typeYfDisplay {
    POPIN = 'POPIN',
    REQUEST = 'REQUEST',
    PICTURE = 'PICTURE'
}

export const defaultYfDisplay = 'DEFAULT'
