import React, { useContext } from 'react'
import { DeckSpaces } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import DeckList from './DeckList'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import parse from 'html-react-parser'

const useStyles = makeStyles(
	(theme) => (
		{
			spacesContainerStyle: {
				margin: 'auto',

				'& h1': {
					margin: 0,
					color: theme.palette.blue.main
				},
				'& > div:nth-child(2)': {
					'& p': {
						margin: 0,
						color: theme.palette.blue.main
					},
					'& > div:first-child p': {
						...styles(theme).text13
					},
					'& > div:last-child p': {
						...styles(theme).text20
					}
				}
			},
			spacesContainerLargeScreenStyle: {
				maxWidth: 1100,
				marginTop: 105,

				'& h1': {
					marginBottom: 50,
					...styles(theme).title1
				},
				'& > div:nth-child(2)': {
					marginBottom: 60
				}
			},
			spacesContainerSmallScreenStyle: {
				marginTop: 75,

				'& h1': {
					marginBottom: 20,
					...styles(theme).title2
				},
				'& > div:nth-child(2)': {
					marginBottom: 30,

					'& > div:first-child': {
						marginBottom: 20
					}
				}
			}
		}
	)
)

interface AcceptingProps {
	spaces: DeckSpaces
}

type DeckSpacesProps = AcceptingProps

const DeckSpacesPage: React.FC<DeckSpacesProps> = (
	{
		spaces
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const spacesContainerClass: ClassValue = classNames(
		classes.spacesContainerStyle,
		isLargeScreen ? classes.spacesContainerLargeScreenStyle : classes.spacesContainerSmallScreenStyle
	)

	return (
		<div className={spacesContainerClass}>
			<div className="row">
				<div className="col-xs-12">
					<h1>
						<FormattedMessage
							id="deck.spaces.title"
							defaultMessage="Les espaces"
							description="Spaces title"
						/>
					</h1>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12">
					<p>{parse(spaces.text)}</p>
				</div>
			</div>

			{
				spaces.list.map(
					(item, index) => (
						<DeckList
							key={item.title}
							item={item}
							index={index}
						/>
					)
				)
			}
		</div>
	)
}

export default DeckSpacesPage