import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../styles'
import Icon from '../../../components/icon/Icon'
import { typeYfDisplay } from '../enum/yfManagerEnums'
import iconsEnum from '../../../enums/iconsEnum'
import { useSelector } from 'react-redux'
import { RootState } from '../../../index'

const useStyle = makeStyles(
	(theme) => (
		{
			...styles(theme),
			popinThumbnail: {
				height: 48,
				width: 48,
				borderRadius: 50
			},
			requestThumbnail: {
				borderRadius: 50
			}
		}
	)
)

interface AcceptingProps {
	type: string
}

type YFDisplayProps = AcceptingProps

const YFDisplay: React.FC<YFDisplayProps> = (
	{
		type
	}
) => {
	const classes = useStyle()
	const user = useSelector((state: RootState) => state.user)

	switch (type) {
		case  typeYfDisplay.POPIN:
			return user.thumbnail ? <img className={classes.popinThumbnail} src={user.thumbnail} alt="User thumbnail" /> : <Icon size="48px" icon={iconsEnum.Hello} />
		case  typeYfDisplay.REQUEST:
			return <img className={classes.requestThumbnail} src="img/default_thumbnail.png" alt="yf Manager icon" />
		case  typeYfDisplay.PICTURE:
			return <img src="img/yfm_img/defaultYFM.png" alt="yf Manager icon" />
		default:
			return <></>
	}

}

export default YFDisplay