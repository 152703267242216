import React from 'react'
import IcomoonReact from 'icomoon-react'
import iconSet from '../../selection.json'

interface ModelIcon {
	color?: string,
	size: string | number,
	icon: string,
	className?: string
}

const Icon: React.FC<ModelIcon> = (
	{
		color, size, icon, className
	}
) => {

	return (
		<IcomoonReact
			className={className}
			iconSet={iconSet}
			color={color}
			size={size}
			icon={icon}
		/>
	)
}
export default Icon