import React, { useContext } from 'react'
import languages from './enum/languagesEnum'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import styles from '../../styles'
import { useIntl } from 'react-intl'
import { LangContext } from './langContext'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { objectGetKeys } from '../../utils/ieCompatibility'
import { ButtonBase } from '@material-ui/core'
import SvgHandicap from '../../icons/Handicap'
import { ThemeContext } from '../../utils/context/themeContext'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			profileStyle: {
				display: 'flex',
				alignItems: 'center',

				'& button': {
					marginRight: 15
				}
			},
			profileLargeScreenStyle: {
				justifyContent: 'flex-end',
				paddingRight: 40
			},
			profileSmallScreenStyle: {
				paddingLeft: 24
			},
			langStyle: {
				whiteSpace: 'nowrap',
				color: 'white',

				'& span': {
					cursor: 'pointer',
					color: theme.palette.blue['30'],
					textTransform: 'uppercase'
				},
				'& span:first-child': {
					marginRight: 5
				},
				'& span:last-child': {
					marginLeft: 5
				}
			},
			selectedLang: {
				color: `${theme.palette.green.main} !important`
			}
		}
	)
)

const LanguageSelector = () => {
	const classes = useStyles()
	const intl = useIntl()
	const theme = useTheme()
	const { switchLang } = useContext(LangContext)
	const { isLargeScreen } = useContext(ResponsiveContext)
	const { isVisualHandicap, switchVisualHandicap } = useContext(ThemeContext)

	const profileClass: ClassValue = classNames(
		classes.profileStyle,
		{
			'col-sm-2 col-lg-3 col-xl-4': isLargeScreen,
			[classes.profileLargeScreenStyle]: isLargeScreen,
			[classes.profileSmallScreenStyle]: !isLargeScreen
		}
	)

	const langClass: ClassValue = classNames(
		classes.text4,
		classes.langStyle
	)

	const buttonClass: ClassValue = classNames(
		isVisualHandicap ? [classes.backgroundWhite] : [classes.backgroundBlue]
	)

	const handleHandicap = () => {
		switchVisualHandicap(!isVisualHandicap)
	}

	return (
		<div
			className={profileClass}
		>
			<ButtonBase className={buttonClass} onClick={handleHandicap}>
				<SvgHandicap color={isVisualHandicap ? theme.palette.blue.main : 'white'} />
			</ButtonBase>

			<span className={langClass}>
				{
					objectGetKeys(languages).map(
						(language, index) => (
							<React.Fragment key={index}>
								<span
									onClick={() => switchLang(language)}
									className={intl.locale === language ? classes.selectedLang : ''}
								>
									{language}
								</span>

								{index < Object.keys(languages).length - 1 ? '|' : ''}
							</React.Fragment>
						)
					)
				}
			</span>
		</div>
	)
}

export default LanguageSelector
