import { Dispatch } from 'react'
import fetchFactory from '../../../utils/fetchFactory'
import errorCatcher from '../../../utils/errorCatcher'
import { loadOffers, saveOffers } from '../reducer/actions'
import { saveSelection } from '../../selection/reducer/actions'
import { SelectionModel } from '../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../components/offerDetails/types/offerDetailsModel'

const GET_OFFERS = 'get_offers'

export const getOffers = (user: string, language: string, dispatch: Dispatch<any>) => {
    dispatch(
        loadOffers()
    )

    return fetchFactory(`/cms/${language}/api/content/offers?id=${user}`)
        .then(
            (offers: any) => {
                dispatch(
                    saveOffers(
                        {
                            offers: offers,
                            loaded: true
                        }
                    )
                )
                dispatch(
                    saveSelection(
                        {
                            selections: {
                                visits: offers.filter((offer: (SelectionModel & OfferDetailsModel)) => offer.type === 0).sort(
                                    (a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
                                        const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : Number(a.divisibleFrom)
                                        const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : Number(b.divisibleFrom)

                                        return tmpB - tmpA
                                    }
                                )
                                    .map(
                                        (visit: (SelectionModel & OfferDetailsModel)) => (
                                            {
                                                ...visit,
                                                latitude: Number(visit.latitude),
                                                longitude: Number(visit.longitude)
                                            }
                                        )
                                    ),
                                selectedOffers: offers.filter((offer: (SelectionModel & OfferDetailsModel)) => offer.type === 1).sort(
                                    (a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
                                        const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : Number(a.divisibleFrom)
                                        const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : Number(b.divisibleFrom)

                                        return tmpB - tmpA
                                    }
                                )
                                    .map(
                                        (selected: (SelectionModel & OfferDetailsModel)) => (
                                            {
                                                ...selected,
                                                latitude: Number(selected.latitude),
                                                longitude: Number(selected.longitude)
                                            }
                                        )
                                    )
                            },
                            loaded: true
                        }
                    )
                )
            }
        )
        .catch(
            (error) => dispatch(
                errorCatcher(error, GET_OFFERS)
            )
        )
}