import React, { useContext } from 'react'
import { OfferDetailsModel } from '../../../offerDetails/types/offerDetailsModel'
import { SelectionModel } from '../../../../modules/selection/types/selectionModel'
import { makeStyles } from '@material-ui/core/styles'
import OfferDetails from '../../../offerDetails/OfferDetails'
import { fromComponentEnum } from '../../../offerDetails/enum/fromComponentEnum'
import styles from '../../../../styles'
import { ResponsiveContext } from '../../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { useHistory } from 'react-router'
import links from '../../../../enums/linksEnum'
import { selectionStatusEnum } from '../../../../modules/selection/enum/selectionEnum'
import { FormattedMessage } from 'react-intl'
import { redirectToWithQuery } from '../../../../utils/formatter/formatRouter'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			infoWindowContainerStyle: {
				position: 'relative',
				zIndex: 1,
				top: 15,
				width: 'max-content',
				backgroundColor: 'white',
				boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
				cursor: 'pointer',

				'&::after': {
					content: '""',
					width: 20,
					height: 20,
					transform: 'rotate(-45deg)',
					background: '#fff',
					position: 'absolute',
					zIndex: -1,
					top: -10,
					left: '50%'
				},
				'& > div': {
					'& > div:first-child': {
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
						margin: 'auto'
					},
					'& > div:last-child': {
						paddingTop: 20,
						paddingBottom: 20,
						paddingLeft: 0
					}
				},
				'& h1': {
					color: theme.palette.blue.main,
					margin: 0,
					...styles(theme).title10
				}
			},
			infoWindowContainerLargeScreenStyle: {
				maxWidth: 430
			},
			infoWindowContainerSmallScreenStyle: {
				maxWidth: 350
			},
			disabledDesign: {
				cursor: 'inherit',

				'& img': {
					filter: 'grayscale(100)'
				},
				'& h1, & span': {
					opacity: 0.5
				}
			},
			disabled: {
				width: '89%',
				height: '100%',
				position: 'absolute',

				'& > div': {
					width: '100%',
					height: '100%',
					background: theme.palette.blue.main,
					opacity: 0.5
				},
				'& h2': {
					...styles(theme).title10,
					color: 'white',
					position: 'absolute',
					left: 0,
					top: '50%',
					transform: 'translate(0%, -50%)',
					margin: 0,
					textAlign: 'center',
					width: '100%'
				}
			},
			addressContainerStyle: {
				margin: '5px 0px'
			},
			suiteAdressStyle: {
				textTransform: 'uppercase',
				marginBottom: 15
			}
		}
	)
)

interface AcceptingProp {
	item: (SelectionModel & OfferDetailsModel)
}

type infoWindowProps = AcceptingProp

const InfoWindow: React.FC<infoWindowProps> = (
	{
		item
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const infoWindowContainerClass: ClassValue = classNames(
		classes.infoWindowContainerStyle,
		isLargeScreen ? classes.infoWindowContainerLargeScreenStyle : classes.infoWindowContainerSmallScreenStyle,
		{
			[classes.disabledDesign]: (item.status !== selectionStatusEnum.available || item.availableArea === '0')
		}
	)

	const addressClass: ClassValue = classNames(
		classes.text22,
		classes.colorBlue
	)

	const suiteAddressClass: ClassValue = classNames(
		addressClass,
		classes.suiteAdressStyle
	)

	const addressContainer: ClassValue = classNames(
		'col-xs-12',
		classes.addressContainerStyle
	)

	const seeDetails = () => {
		redirectToWithQuery(history, `${links.deskDetails}/${item.id}`)
	}

	return (
		<div
			className={infoWindowContainerClass}
			onClick={item.status === selectionStatusEnum.available && item.availableArea > '0' ? seeDetails : undefined}
			style={
				{
					left: isLargeScreen ? -215 : -175
				}
			}
		>
			<div className="row">
				<div className="col-xs-4">
					<img
						src={item.picture}
						alt={item.alternative}
						width="100%"
					/>

					{
						(item.status !== selectionStatusEnum.available || item.availableArea === '0') && (
							<div className={classes.disabled}>
								<div>
								</div>

								<h2>
									<FormattedMessage
										id="selection.rent"
										defaultMessage="Déjà loué"
										description="Already rent text"
									/>
								</h2>
							</div>
						)
					}
				</div>

				<div className="col-xs-8">
					<div className="row">
						<div className="col-xs-12">
							<h1>{item.name}</h1>
						</div>
						<div className={addressContainer}>
							<span className={addressClass}>{item.address}</span>
						</div>
						<div className="col-xs-12">
							<span className={suiteAddressClass}>{`${item.postalCode} ${item.city}`}</span>
						</div>
					</div>

					<OfferDetails
						item={
							{
								availableArea: item.availableArea,
								divisibleFrom: item.divisibleFrom,
								indicativeAvailability: item.indicativeAvailability
							}
						}
						from={fromComponentEnum.map}
					/>
				</div>
			</div>
		</div>
	)
}

export default InfoWindow
