import React, { useContext } from 'react'
import { DeckContacts } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'

const useStyles = makeStyles(
	(theme) => (
		{
			contactsContainerStyle: {
				background: theme.palette.blue.main,

				'& > div': {
					margin: 'auto',

					'& > div:first-child h1': {
						margin: 0,
						color: 'white'
					},
					'& > div:last-child': {
						'& h2, & p': {
							margin: 0,
							color: 'white'
						},
						'& > div': {
							wordBreak: 'break-word',
							marginBottom: 35,

							'& h2': {
								...styles(theme).title4
							},
							'& p': {
								...styles(theme).text15
							},
							'& p:last-child': {
								marginTop: 5
							}
						}
					}
				}
			},
			contactsContainerLargeScreenStyle: {
				marginTop: 130,

				'& > div': {
					maxWidth: 1100,
					padding: '215px 0 0',

					'& > div:first-child h1': {
						marginBottom: 60,
						...styles(theme).title1
					}
				}
			},
			contactsContainerSmallScreenStyle: {
				marginTop: 70,

				'& > div': {
					padding: '70px 0 0',

					'& > div:first-child h1': {
						marginBottom: 40,
						...styles(theme).title2
					}
				}
			}
		}
	)
)

interface AcceptingProps {
	contacts: DeckContacts[]
}

type deckContactsProps = AcceptingProps

const DeckContactsPage: React.FC<deckContactsProps> = (
	{
		contacts
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const contactsContainerClass: ClassValue = classNames(
		classes.contactsContainerStyle,
		isLargeScreen ? classes.contactsContainerLargeScreenStyle : classes.contactsContainerSmallScreenStyle
	)

	return (
		<div className={contactsContainerClass}>
			<div className="marginContent">
				<div className="row">
					<div className="col-xs-12">
						<h1>
							<FormattedMessage
								id="deck.contacts.title"
								defaultMessage="Nos partenaires commercialisateurs"
								description="Contacts title"
							/>
						</h1>
					</div>
				</div>

				<div className="row">
					{
						contacts.map(
							(contact) => (
								<div
									className="col-xs-12 col-md-4"
									key={contact.email}
								>
									<h2>{contact.firstName} {contact.lastName}</h2>
									<p>{contact.company}</p>

									<br />

									<p>{contact.email}</p>
									<p>{contact.phone}</p>
								</div>
							)
						)
					}
				</div>
			</div>
		</div>
	)
}

export default DeckContactsPage