import React, { useContext } from 'react'
import { DeckTechnical } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import parse from 'html-react-parser'
import styles from '../../../styles'
import Button from '../../../components/button/Button'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => (
		{
			technicalContainerStyle: {
				margin: 'auto',

				'& > div:first-child h1': {
					margin: 0,
					color: theme.palette.blue.main
				},
				'& > div:nth-child(2)': {
					'& h2': {
						color: theme.palette.blue.main,
						...styles(theme).text0
					},
					'& p': {
						margin: 0,
						color: theme.palette.blue.main,
						...styles(theme).text20
					}
				}
			},
			technicalContainerLargeScreenStyle: {
				marginTop: 110,
				maxWidth: 1100,

				'& > div:first-child h1': {
					marginBottom: 50,
					...styles(theme).title1
				},
				'& > .details': {
					'& .col-md-6:first-child': {
						paddingRight: 15
					},
					'& .col-md-6:last-child': {
						paddingLeft: 15
					},
					'& h2': {
						margin: '30px 0 10px'
					}
				},
				'& > .downloadButton': {
					marginTop: 60
				}
			},
			technicalContainerSmallScreenStyle: {
				marginTop: 130,

				'& > div:first-child h1': {
					marginBottom: 30,
					...styles(theme).title2
				},
				'& > .details': {
					'& h2': {
						margin: '20px 0 10px'
					}
				},
				'& > .downloadButton': {
					marginTop: 50
				}
			}
		}
	)
)

interface AcceptingProps {
	technical: DeckTechnical
}

type deckTechnicalProps = AcceptingProps

const DeckTechnicalPage: React.FC<deckTechnicalProps> = (
	{
		technical
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const technicalContainerClass: ClassValue = classNames(
		classes.technicalContainerStyle,
		isLargeScreen ? classes.technicalContainerLargeScreenStyle : classes.technicalContainerSmallScreenStyle
	)

	const extractParts = (description: string, minSize: number) => {
		let firstPart = description.substr(0, minSize)

		const rest = description.substr(minSize)
		const titleFromRest = rest.split('<h2>')
		firstPart += titleFromRest[0]

		titleFromRest.shift()

		return [firstPart, titleFromRest.join('<h2>')]
	}

	const splitDescription = (description: string) => {
		const extractedFirstPart = extractParts(description, description.length / 2)
		const firstPart = extractedFirstPart[0]
		const secondPart = extractedFirstPart[1] ? `<h2>${extractedFirstPart[1]}` : ''

		return [firstPart, secondPart]
	}

	const descriptionSplitted = splitDescription(technical.description)

	const downloadDocument = () => {
		const name = intl.formatMessage(
			{
				id: 'deck.technical.filename',
				defaultMessage: 'dossierTechnique',
				description: 'Filename'
			}
		)

		const a = document.createElement('a')
		a.href = technical.download || ''
		a.target = '_blank'
		a.download = name
		a.click()
	}

	return (
		<div className={technicalContainerClass}>
			<div className="row">
				<div className="col-xs-12">
					<h1>
						<FormattedMessage
							id="deck.technical.title"
							defaultMessage="Eléments techniques généraux"
							description="Technical title"
						/>
					</h1>
				</div>
			</div>

			<div className="row details">
				<div className="col-xs-12 col-md-6">
					<p>{parse(descriptionSplitted[0])}</p>
				</div>

				<div className="col-xs-12 col-md-6">
					<p>{parse(descriptionSplitted[1])}</p>
				</div>
			</div>

			{
				technical.download && (
					<div className="row center-xs downloadButton">
						<div className="col-xs-12 col-md-5">
							<Button
								background="backgroundBlue"
								color="colorWhite"
								textStyle="text8"
								size="bigButton"
								onClick={downloadDocument}
							>
								<FormattedMessage
									id="deck.technical.download"
									defaultMessage="Télécharger le dossier technique complet"
									description="Download button"
								/>
							</Button>
						</div>
					</div>
				)
			}
		</div>
	)
}

export default DeckTechnicalPage