import React, { ChangeEvent } from 'react'
import Slider from '@material-ui/core/Slider'
import { FieldRenderProps } from 'react-final-form'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import styles from '../../styles'
import { useIntl } from 'react-intl'
import thousandsSeparator from '../../utils/thousandsSeparator'

const CustomSlider = withStyles(
    (theme) => (
        {
            root: {
                color: theme.palette.accessibility.main,
                height: 6
            },
            thumb: {
                height: 12,
                width: 12,
                boxSizing: 'content-box',
                backgroundColor: theme.palette.accessibility.main,
                border: '5px solid white',
                marginTop: -8,
                marginLeft: -15,
                boxShadow: '0 2px 5px 0 rgba(18, 41, 65, 0.2)',
                '&:focus, &:hover, &$active': {
                    boxShadow: '0 2px 5px 0 rgba(18, 41, 65, 0.2)'
                }
            },
            active: {},
            track: {
                height: 8,
                borderRadius: 4
            },
            rail: {
                height: 6,
                borderRadius: 4,
                backgroundColor: theme.palette.blue['20']
            },
            markLabel: {
                ...styles(theme).text9,
                fontWeight: 'bold',
                color: 'rgba(18, 41, 65, 0.6)'
            },
            mark: {
                display: 'none'
            }
        }
    )
)(Slider)

const useStyles = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            errorStyle: {
                color: '#B92D00',
                fontSize: 10
            },
            errorColor: {
                color: '#B92D00 !important'
            }
        }
    )
)

interface AcceptingProps {
    id: string,
    label?: string,
    min?: number,
    max?: number
}

const SLIDER_STEP = 250

type FormSliderProps =
    FieldRenderProps<{ min: number, max: number }>
    & AcceptingProps

const FormSlider: React.FC<FormSliderProps> = (
    {
        input,
        meta: {
            touched,
            error
        },
        id,
        label,
        min = 0,
        max = 0
    }
) => {
    const classes = useStyles()
    const intl = useIntl()

    const value: number[] = [input.value.min, input.value.max]
    const marks = [
        {
            value: min,
            label: intl.formatMessage(
                {
                    id: 'global.unit',
                    defaultMessage: '{value} m²',
                    description: 'Unit'
                },
                {
                    value: thousandsSeparator(min, true)
                }
            )
        },
        {
            value: max,
            label: intl.formatMessage(
                {
                    id: 'global.unit',
                    defaultMessage: '{value} m²',
                    description: 'Unit'
                },
                {
                    value: thousandsSeparator(max, true)
                }
            )
        }
    ]

    const labelClass: ClassValue = classNames(
        classes.text5,
        classes.colorBlue,
        {
            [classes.errorColor]: touched && error
        }
    )

    const onSliderChanged = (event: ChangeEvent<{}>, newValue: number | number[]) => {
        if (Array.isArray(newValue)) {
            input.onChange({ min: newValue[0], max: newValue[1] })
        } else {
            input.onChange(newValue)
        }
    }

    return (
        <>
			<span
                className={labelClass}
            >
				{label}
			</span>

            <CustomSlider
                {...input}
                id={id}
                value={value}
                onChange={onSliderChanged}
                marks={marks}
                valueLabelDisplay="off"
                step={SLIDER_STEP}
                min={min}
                max={max}
            />

            {
                touched && (
                    (typeof error === 'string' || error instanceof String) &&
                    <span className={classes.errorStyle}>{error}</span>
                )
            }
        </>
    )
}

export default FormSlider