import React, { createContext, ReactChild, useState } from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '../../theme'

const isVisualHandicap: boolean = false

export const ThemeContext = createContext(
	{
		isVisualHandicap,
		switchVisualHandicap: (isVisualHandicap: boolean) => {
		}
	}
)

export default (props: { children: ReactChild }) => {
	const [isVisualHandicap, setIsVisualHandicap] = useState<boolean>(false)

	const switchVisualHandicap = (isVisualHandicap: boolean) => {
		setIsVisualHandicap(isVisualHandicap)
	}

	return (
		<ThemeContext.Provider
			value={{
				isVisualHandicap,
				switchVisualHandicap
			}}
		>
			<MuiThemeProvider theme={createTheme(isVisualHandicap)}>
				{props.children}
			</MuiThemeProvider>
		</ThemeContext.Provider>
	)
}
