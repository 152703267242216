enum iconsEnum {
	Amphitheater = 'Amphitheatre',
	Animation = 'Animation',
	ApartmentPlan = 'Plan_appartement',
	Attachment = 'Piece-jointe',
	Bar = 'Bar',
	Bathroom = 'Salle_eau',
	Biodiversity = 'Biodiversite',
	Book = 'Livre',
	Breakfast = 'Petit_dejeuner',
	BuildingDocuments = 'DocumentsImmeuble',
	CO2 = 'CO2',
	Cafeteria = 'Cafeteria',
	Calendar = 'Calendrier',
	Camera = 'Camera',
	Capsules = 'Capsules',
	Cave = 'Cave',
	Check = 'Check',
	Coffee = 'Cafe',
	Conciergerie = 'Conciergerie',
	Contracts = 'Contrats',
	Creative_room = 'Creative_room',
	CrockeryKit = 'Kit_vaisselle',
	Cross = 'Croix',
	Development = 'Developpement',
	DoubleBed = 'Lit_double',
	Down = 'Bas',
	Download = 'Telechargement',
	ElectricBollard = 'Borne_electrique',
	Elevator = 'Ascenseur',
	Email = 'Email',
	Environnement = 'Environnement',
	Faq = 'Faq',
	Faq2 = 'Faq-2',
	FoodWithdrawal = 'Retrait_food',
	FriendlySpaces = 'Espaces_conviviaux',
	GameRoom = 'Salle_jeux',
	Garage = 'Garage',
	Garage2 = 'Garage2',
	Garden = 'Jardin',
	Heater = 'Chauffage',
	Hello = 'Hello',
	Home = 'Accueil',
	Hotline = 'Hotline',
	Household = 'Menage',
	Human = 'Humain',
	ITDesk = 'IT-Desk',
	Information = 'Information',
	Laundromat = 'Laverie',
	Left = 'Gauche',
	LocalTrash = 'Local_poubelle',
	Location = 'Localisation',
	Lockers = 'Lockers',
	Logout = 'Deconnexion',
	Lounge = 'Lounge',
	LuggageStorage = 'Bagagerie',
	Manager = 'Manager',
	Mandate = 'Mandat',
	MarketingReport = 'RapportCommercialisation',
	MeetindRoom = 'Salle_reunion',
	Minus = 'Moins',
	Mobile = 'Mobile',
	Motorcycle = 'Moto',
	Motorcycle_Helmet = 'Casque_moto',
	MyTrack = 'MonSuivi',
	Network = 'Reseau',
	Newsletter = 'Newsletter',
	Notification = 'Notification',
	Offices = 'Bureaux',
	PMR = 'PMR',
	Parcel = 'Colis',
	ParcelWithdrawal = 'Retrait_colis',
	Parking = 'Parking',
	Pdf = 'Pdf',
	Plane = 'Avion',
	Planet = 'Planete',
	Plus = 'Plus',
	Private_offices = 'Bureaux_privatisables',
	Profile = 'Profil',
	Pushchair = 'Poussette',
	Quiet_room = 'Quiet_room',
	Reception_capacity = 'Capacite_accueil',
	RelaxationArea = 'Espace_detente',
	Rents = 'Loyers',
	Reparations = 'Reparations',
	Reprography = 'Reprographie',
	Residences = 'Residences',
	Restaurant = 'Restaurant',
	Right = 'Droite',
	Search1 = 'Recherche',
	Search2 = 'Recherche-2',
	Services = 'Services',
	SharedSpace1 = 'Espace_partage_1',
	SharedSpace2 = 'Espace_partage_2',
	SimpleBed = 'Lit_simple',
	Staircase = 'Escalier',
	Students = 'Etudiants',
	Target = 'Cible',
	TechnicalArea = 'Local_technique',
	Timetables = 'Horaires',
	Up = 'Haut',
	WashingMachine = 'Machine_laver',
	Default = 'Droite'
}

export default iconsEnum