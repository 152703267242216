import React, { useContext, useEffect, useRef, useState } from 'react'
import '../App.css'
import styles from '../styles'
import Menu from '../components/menu/Menu'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SnackBar from '../components/snackBar/SnackBar'
import Root from './Root'
import Loader from '../components/loader/Loader'
import { ResponsiveContext } from '../utils/context/responsiveContext'
import links from '../enums/linksEnum'
import { useDispatch, useSelector } from 'react-redux'
import { userInfo } from '../modules/profile/api/userInfo'
import errorCatcher from '../utils/errorCatcher'
import { convertUser } from '../modules/profile/utils/userMapper'
import { saveUser } from '../modules/profile/reducer/actions'
import { useHistory, useLocation } from 'react-router'
import Errors from '../components/errors/Errors'
import { RootState } from '../index'
import localStorageEnum from '../enums/localStorageEnum'
import httpResponse, { httpStatus } from '../enums/httpResponseEnum'
import { useIntl } from 'react-intl'
import AppFooter from '../modules/footer/AppFooter'
import WidgetFavorites from '../components/widgetFavorites/WidgetFavorites'
import { savePromoted } from '../modules/homepage/reducer/actions'
import { getFavorites } from '../modules/favorites/api/getFavorites'
import YFPopIn from '../modules/yf_popin/YFPopIn'
import { getOffers } from '../modules/offers/api/getOffers'
import { Helmet } from 'react-helmet'
import { checkToken } from '../modules/checkToken/api/checkToken'
import { saveToken } from '../modules/checkToken/reducer/actions'

const useStyles = makeStyles((theme) => ({
        ...styles(theme),
        containerStyle: {
            display: 'flex',
            flexDirection: 'column'
        },
        appLargeScreenStyle: {
            paddingTop: 100
        },
        appSmallScreenStyle: {
            paddingTop: 65
        },
        appWithMarginSmallScreenStyle: {
            paddingBottom: 80,
            marginLeft: 20,
            marginRight: 20
        },
        appMaxWidth: {
            maxWidth: 1100,
            margin: 'auto'
        },
        appMarginLeft: {
            marginLeft: 40
        },
        loaderContainerStyle: {
            height: '100vh'
        },
        appFlex: {
            flex: 1,
            display: 'flex',

            '& > .fullWidth': {
                width: '100%'
            }
        }
    }
))

const GET_USER_INFO = 'get_user_info'
const WITHOUT_MENU = [links.deskDetails]
const WITH_MARGIN_SMALL_SCREEN = [links.homepage]

const App = () => {
    const classes = useStyles()
    const intl = useIntl()
    const oldLanguage = useRef(intl.locale)
    const history = useHistory()
    const location = useLocation()
    const user = useSelector((state: RootState) => state.user)
    const selections = useSelector((state: RootState) => state.selection)
    const favorites = useSelector((state: RootState) => state.favorites)
    const dispatch = useDispatch()
    const { isLargeScreen } = useContext(ResponsiveContext)
    const [error, setError] = useState<JSX.Element | null>(null)

    const [loading, setLoading] = React.useState<boolean>(true)

    const containerClass: ClassValue = classNames(
        'App',
        classes.containerStyle
    )

    const appClass: ClassValue = classNames(
        classes.appFlex,
        {
            [classes.appLargeScreenStyle]: isLargeScreen && !location.pathname.includes(links.deskDetails),
            [classes.appSmallScreenStyle]: !isLargeScreen && !location.pathname.includes(links.deskDetails),
            [classes.appWithMarginSmallScreenStyle]: !isLargeScreen && WITH_MARGIN_SMALL_SCREEN.includes(location.pathname as links),
            [classes.appMarginLeft]: isLargeScreen && (location.pathname === links.selection || location.pathname === links.favorites)
        }
    )

    useEffect(
        () => {
            const tokenParam = new URLSearchParams(location.search).get(localStorageEnum.token)

            if (tokenParam && user && !user.loaded) {
                checkToken(tokenParam, dispatch)
                    .then(
                        (token) => {
                            if (typeof token === 'string') {
                                localStorage.setItem(localStorageEnum.token, token)
                                localStorage.setItem(localStorageEnum.url_token, tokenParam)

                                dispatch(
                                    saveToken(token)
                                )
                            }

                            userInfo()
                                .then(
                                    (user: any) => {
                                        if (user.statut === httpResponse.ok) {
                                            dispatch(
                                                saveUser(convertUser(user))
                                            )

                                            dispatch(
                                                savePromoted(user.promoted)
                                            )
                                        }
                                    }
                                )
                                .catch(
                                    (error) => {
                                        if (error.status && error.status === httpStatus.forbidden) {
                                            localStorage.removeItem(localStorageEnum.token)
                                        } else {
                                            dispatch(
                                                errorCatcher(error, GET_USER_INFO)
                                            )
                                        }
                                    }
                                )
                                .finally(
                                    () => setLoading(false)
                                )
                        }
                    )
                    .catch(
                        (code?: number) => {
                            if (code) {
                                setError(<Errors statusCode={code}/>)
                            }

                            setLoading(false)
                        }
                    )
            } else {
                if (!tokenParam) {
                    setError(<Errors statusCode={httpStatus.unauthorized} />)
                }

                setLoading(false)
            }
        }, [dispatch, history, location.pathname, user, location.search]
    )

    useEffect(
        () => {
            if (user.id !== '0' && (!selections.loaded || oldLanguage.current !== intl.locale)) {
                getFavorites(user.id, intl.locale, dispatch).then()

                getOffers(user.id, intl.locale, dispatch).then(() => oldLanguage.current = intl.locale)
            }
        }, [dispatch, intl.locale, selections.loaded, user.id]
    )

    if (loading) {
        return (
            <>
                <div className={classes.loaderContainerStyle}>
                    <Loader/>
                </div>
            </>
        )
    }

    if (error) {
        return <>
            {error}
        </>
    }

    return (
        <div
            id="outer-container"
            className={containerClass}
        >
            <Helmet htmlAttributes={{ lang: intl.locale }}/>

            {
                WITHOUT_MENU.findIndex((menu) => location.pathname.includes(menu)) < 0 && (
                    <header className="App-header">
                        <Menu/>
                    </header>
                )
            }

            <div
                id="App"
                className={appClass}
            >
                <Root/>
            </div>

            {
                favorites.favorites.length > 0 && location.pathname !== links.favorites && (
                    <WidgetFavorites/>
                )
            }

            {
                !location.pathname.includes(links.deskDetails) && (
                    <AppFooter/>
                )
            }

            <YFPopIn/>

            <SnackBar/>
        </div>
    )
}

export default App
