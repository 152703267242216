import { SAVE_FOOTER } from './actionTypes'
import { FooterType } from '../types/types'
import { PageModel } from '../types/footerModel'

export const saveFooter = (footer: PageModel[]): FooterType => {
	return ({
		type: SAVE_FOOTER,
		payload: footer
	})
}
