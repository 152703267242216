import React, { useEffect, useState } from 'react'
import { DeckVideo } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
    () => (
        {
            videoContainerStyle: {
                maxWidth: 1100,
                margin: 'auto',

                '& img': {
                    width: '100%'
                },
                '& video': {
                    width: '100%'
                }
            }
        }
    )
)

interface AcceptingProps {
    video: DeckVideo
}

const DEFAULT_YOUTUBE_URL = 'https://www.youtube.com/embed/'

type DeckVideoProps = AcceptingProps

const DeckVideoPage: React.FC<DeckVideoProps> = (
    {
        video
    }
) => {
    const classes = useStyles()
    const [youtubeEmbed, setYoutubeEmbed] = useState<string>('')
    const videoContainerClass: ClassValue = classNames(
        'row',
        classes.videoContainerStyle
    )

    useEffect(
        () => {
            if (video && video.video) {
                let splittedVideo = video.video.split('v=')

                if (splittedVideo.length > 1) {
                    setYoutubeEmbed(`${DEFAULT_YOUTUBE_URL}${splittedVideo[1]}`)
                } else if (video.video.split('youtu.be').length > 1) {
                    splittedVideo = video.video.split('/')

                    setYoutubeEmbed(`${DEFAULT_YOUTUBE_URL}${splittedVideo[splittedVideo.length - 1]}`)
                }
            }
        }, [video]
    )

    if (youtubeEmbed.length === 0) {
        return (
            <></>
        )
    }

    return (
        <div className={videoContainerClass}>
            <div className="col-xs-12">
                <iframe
                    title="YouTube video"
                    width="100%"
                    height="500"
                    src={youtubeEmbed}
                />
            </div>
        </div>
    )
}

export default DeckVideoPage