import React, { useContext } from 'react'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../../../../components/fields/FormTextField'
import classNames from 'classnames'
import Button from '../../../../../components/button/Button'
import { FormattedMessage, useIntl } from 'react-intl'
import { MakeRequestContext } from '../../../utils/MakeRequestContext'
import { ResponsiveContext } from '../../../../../utils/context/responsiveContext'
import { MakeRequestModel, PostMakeRequestModel } from '../../../types/makeRequestModel'
import { makeStyles } from '@material-ui/core/styles'
import { FieldArray } from 'react-final-form-arrays'
import FormFile from '../../../../../components/fields/FormFile'
import { sendMessage } from '../../../api/sendMessage'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../index'
import errorCatcher from '../../../../../utils/errorCatcher'
import ReCAPTCHA from 'react-google-recaptcha'
import { displaySnackbar } from '../../../../../components/snackBar/reducer/actions'

const useStyles = makeStyles(
	() => (
		{
			fieldStyle: {
				marginTop: 10
			},
			subText: {
				fontSize: 10,
				marginTop: 20
			},
			buttonsRow: {
				marginTop: 20,
				alignItems: 'center'
			},
			noPadding: {
				padding: 0
			},
			buttonMargin: {
				marginTop: 10
			}
		}
	)
)

const TITLE = 'title'
const MESSAGE = 'message'
const FILE_NAME: string = 'attachments'
const SEND_MESSAGE: string = 'send_message'
const CAPTCHA_ERROR: string = 'captcha'

const MessageForm = () => {
	const classes = useStyles()
	const intl = useIntl()
	const user = useSelector((state: RootState) => state.user)
	const dispatch = useDispatch()
	const { request, nextStep } = useContext(MakeRequestContext)
	const { isLargeScreen } = useContext(ResponsiveContext)
	const [captcha, setCaptcha] = React.useState<boolean>(false)

	const validate = (values: Pick<MakeRequestModel, 'title' | 'message'>) => {
		const errors: any = {}

		if (!values.title) {
			errors.title = intl.formatMessage(
				{
					id: 'global.errors.required',
					defaultMessage: 'Requis*',
					description: 'Required field'
				}
			)
		}
		if (!values.message) {
			errors.message = intl.formatMessage(
				{
					id: 'global.errors.required',
					defaultMessage: 'Requis*',
					description: 'Required field'
				}
			)
		}

		return errors
	}

	const onSubmit = (values: Pick<MakeRequestModel, 'title' | 'message' | 'attachments'>) => {
		if (captcha) {
			const data: PostMakeRequestModel = {
				...request,
				subject: values.title,
				message: values.message,
				attachments: values.attachments,
				id_demande: user.id
			}

			sendMessage(user.email, data)
				.then(
					() => nextStep()
				)
				.catch(
					(error: any) => dispatch(
						errorCatcher(error, SEND_MESSAGE)
					)
				)
		} else {
			dispatch(displaySnackbar({
				id: CAPTCHA_ERROR,
				message: intl.formatMessage({
					id: 'global.errors.validateCaptcha',
					defaultMessage: 'Vous devez valider le CAPTCHA',
					description: 'error extension file'
				}),
				open: true,
				hideIcon: true
			}))
		}
	}


	return (
		<>
			<Form
				mutators={{
					...arrayMutators
				}}
				validate={validate}
				onSubmit={onSubmit}
				render={
					(
						{
							handleSubmit
						}
					) => (
						<form onSubmit={handleSubmit}>
							<div
								className={
									classNames(
										'row',
										classes.fieldStyle
									)
								}
							>
								<div className="col-xs-12">
									<Field
										name={TITLE}
										component={FormTextField}
										id={TITLE}
										placeholder={
											{
												id: 'popinYf.makeRequest.secondStep.title'
											}
										}
									/>
								</div>
							</div>

							<div
								className={
									classNames(
										'row',
										classes.fieldStyle
									)
								}
							>
								<div className="col-xs-12">
									<Field
										name={MESSAGE}
										component={FormTextField}
										id={MESSAGE}
										placeholder={
											{
												id: 'popinYf.makeRequest.secondStep.message'
											}
										}
										multiline
									/>
								</div>
							</div>

							<div
								className={
									classNames(
										'row',
										classes.fieldStyle
									)
								}
							>
								<div className="col-xs-12">
									<FieldArray
										name={FILE_NAME}
										render={
											(
												{
													fields,
													meta
												}
											) => (
												<FormFile
													fields={fields}
													meta={meta}
													label={
														<FormattedMessage id="popinYf.makeRequest.secondStep.attachment" />
													}
													id={FILE_NAME}
												/>
											)
										}
									/>
								</div>
							</div>


							<div className={classNames('row', classes.buttonsRow)}>
								<div className={classNames('col-xs-12', 'col-md-8', classes.noPadding)}>
									<ReCAPTCHA sitekey={user.captcha}
									           onChange={() => {
										           setCaptcha(true)
									           }}
									           size={isLargeScreen ? 'normal' : 'compact'}
									           onExpired={() => {
										           setCaptcha(false)
									           }}
									           hl={intl.locale}>
									</ReCAPTCHA>
								</div>

								<div
									className={
										classNames(
											'col-xs-12',
											'col-md-4',
											!isLargeScreen ? classes.buttonMargin : ''
										)
									}
								>
									<Button
										background="backgroundAccessibility"
										color="colorWhite"
										textStyle={isLargeScreen ? 'text7' : 'text8'}
										type="submit"
										size="mediumButton"
									>
										<FormattedMessage
											id="global.btn.send"
											defaultMessage="Envoyer"
											description="Send button"
										/>
									</Button>
								</div>
							</div>
						</form>
					)
				}
			/>

			<div className={classes.subText}>
				Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Gecina pour gérer votre demande de contact car elles sont nécessaires au traitement de votre demande. Elles sont conservées pour la durée
				nécessaire à nos échanges dans le respect des prescriptions légales applicables et sont destinées collaborateurs intervenant dans les échanges.
				Conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 ("RGPD") et à la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés modifiée (Loi «Informatique et
				Libertés»), vous bénéficiez, dans les limites de cette règlementation, d’un droit d’accès, de rectification et de suppression des données à caractère personnel vous concernant, ainsi que d’un droit à la limitation du traitement, à la
				portabilité des données et d’un droit d’opposition pour des motifs légitimes au traitement de ces données et du droit de définir des directives sur le sort de ces données après votre mort.
				<br />
				Vous pouvez exercer ces droits auprès de notre DPO par mail à : protectiondesdonnees@gecina.fr .En cas de doute sur votre identité, il vous sera demandé de joindre la photocopie d’un justificatif d’identité, afin que nous puissions
				procéder aux vérifications nécessaires.
				<br />
				Pour en savoir plus sur le traitement de vos données personnelles, vous pouvez également consulter la politique de protection des données personnelles présente sur le Site en cliquant sur le lien suivant : <a
				href="https://explore.bureau.youfirst.co/footer/593" target="_blank" rel="noopener noreferrer">Protection des données personnelles</a><br />
				Nous vous informons par ailleurs de l’existence de la liste d'opposition au démarchage téléphonique «Bloctel», sur laquelle vous pouvez vous inscrire ici : <a href="https://conso.bloctel.fr/" target="_blank"
				                                                                                                                                                               rel="noopener noreferrer">https://conso.bloctel.fr/</a>
			</div>
		</>
	)
}

export default MessageForm