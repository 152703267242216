import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import MuiBadge from '@material-ui/core/Badge'
import { badgeEnum } from './enum/badgeEnum'
import { ThemeContext } from '../../utils/context/themeContext'

const useStyles = makeStyles(
	(theme) => (
		{
			badge: {
				'& .MuiBadge-badge': {
					top: (props: { type: badgeEnum }) => props.type === badgeEnum.menu ? -10 : 10,
					right: (props: { type: badgeEnum }) => props.type === badgeEnum.menu ? -10 : 10,
					color: (props: { isVisualHandicap: boolean }) => props.isVisualHandicap ? `${theme.palette.blue.main} !important` : 'white !important',
					backgroundColor: (props: {isVisualHandicap: boolean}) => props.isVisualHandicap ? 'white' : theme.palette.green.main,
					...styles(theme).text14
				}
			}
		}
	)
)

interface AcceptingProps {
	badgeContent: number | string,
	type: badgeEnum
}

type badgeProps = AcceptingProps

const Badge: React.FC<badgeProps> = (
	{
		badgeContent,
		type,
		children
	}
) => {
	const { isVisualHandicap } = useContext(ThemeContext)
	const classes = useStyles({ type, isVisualHandicap })

	return (
		<MuiBadge
			className={classes.badge}
			badgeContent={badgeContent}
			color="primary"
		>
			{children}
		</MuiBadge>
	)
}

export default Badge
