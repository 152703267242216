import React, { useContext } from 'react'
import { DeckPictures } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import DeckSlider from './DeckSlider'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
    (theme) => (
        {
            picturesContainerStyle: {
                margin: 'auto',

                '& > div:first-child h1': {
                    margin: 0,
                    color: theme.palette.blue.main
                }
            },
            picturesContainerLargeScreenStyle: {
                maxWidth: 1100,
                marginTop: 215,
                marginBottom: 110,

                '& > div:first-child h1': {
                    ...styles(theme).title1
                }
            },
            picturesContainerSmallScreenStyle: {
                marginTop: 10,
                marginBottom: 50,

                '& > div:first-child h1': {
                    ...styles(theme).title2
                }
            },
            bluePath: {
                '& .sliderFullScreen': {
                    '& path': {
                        stroke: theme.palette.blue.main
                    }
                }
            }
        }
    )
)

interface AcceptingProps {
    pictures: DeckPictures[]
}

type deckPicturesProps = AcceptingProps

const DeckPicturesPage: React.FC<deckPicturesProps> = (
    {
        pictures
    }
) => {
    const classes = useStyles()
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

    const picturesContainerClass: ClassValue = classNames(
        classes.picturesContainerStyle,
        isLargeScreen ? classes.picturesContainerLargeScreenStyle : classes.picturesContainerSmallScreenStyle,
        {
            [classes.bluePath]: !isTabletteScreen
        }
    )

    return (
        <div className={picturesContainerClass}>
            <div className="row">
                <div className="col-xs-12">
                    <h1>
                        <FormattedMessage
                            id="deck.pictures.title"
                            defaultMessage="Photos"
                            description="Pictures title"
                        />
                    </h1>
                </div>
            </div>

            <DeckSlider content={pictures}/>
        </div>
    )
}

export default DeckPicturesPage