import React, { Dispatch } from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import styles from '../../styles'
import { connect } from 'react-redux'
import { hideSnackbar } from './reducer/actions'
import { makeStyles } from '@material-ui/core/styles'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import { SnackbarTypes } from './reducer/types'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	snackbarStyle: {
		width: '50%',
		wordBreak: 'break-word'
	},
	verticalBottom: {
		verticalAlign: 'bottom',
		marginRight: '20px'
	}
}))

interface ModelSnackBarData {
	id: string,
	message: string,
	open: boolean,
	hideIcon?: boolean
}

interface StoreProps {
	snackBarData: ModelSnackBarData,
	hideSnackbar: () => void
}

type SnackBarProps = StoreProps
const SnackBar: React.FC<SnackBarProps> = (
	{
		snackBarData,
		hideSnackbar
	}
) => {

	const vertical = 'bottom'
	const horizontal = 'center'
	const classes = useStyles()

	const handleClose = () => {
		hideSnackbar()
	}

	return (
		<>
			<MuiSnackbar
				className={classes.snackbarStyle}
				anchorOrigin={{ vertical, horizontal }}
				key={snackBarData.id}
				open={snackBarData.open}
				onClose={handleClose}
				ContentProps={{
					'aria-describedby': snackBarData.id
				}}
				message={
					<span id={snackBarData.id}>
						{!snackBarData.hideIcon && <DoneOutlineIcon className={classes.verticalBottom} />}
						<span
							className={
								[
									classes.text4,
									classes.colorWhite
								].join(' ')
							}
						>
							{snackBarData.message}
						</span>
					</span>
				}
			/>
		</>
	)
}

const mapStateToProps = (state: any) => {
	return {
		snackBarData: state.snackBar
	}
}

const mapDispatchToProps = (dispatch: Dispatch<SnackbarTypes>) => {
	return {
		hideSnackbar: () => {
			dispatch(hideSnackbar())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar)
