import React, { useContext } from 'react'
import { DeckKeyPoints } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import parse from 'html-react-parser'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'

const useStyles = makeStyles(
    (theme) => (
        {
            keyPointsContainerStyle: {
                background: theme.palette.blue['5'],

                '& .row': {
                    margin: 'auto',
                    paddingLeft: '0.5rem',

                    '& .picture': {
                        position: 'relative',
                        padding: 0,

                        '& .imgContainer': {
                            zIndex: 1,

                            '& img': {
                                width: '100%',
                                height: '100%'
                            }
                        }
                    }
                }
            },
            keyPointsContainerLargeScreenStyle: {
                marginTop: 115,

                '& .row': {
                    maxWidth: 1100,

                    '& .picture': {
                        marginTop: -20,

                        '& .imgContainer': {
                            width: '100%',
                            zIndex: 1,
                            backgroundColor: theme.palette.green.main,
                            position: 'absolute',
                            left: 20,
                            top: 50,
                            paddingBottom: 20,
                            boxSizing: 'border-box',

                            '& img': {
                                marginLeft: -20,
                                marginTop: -20
                            }
                        }
                    }
                },
                '& > div:last-child': {
                    padding: '120px 0 100px',

                    '& h1': {
                        margin: 0,
                        color: theme.palette.blue.main,
                        marginBottom: 60,
                        ...styles(theme).title1
                    },
                    '& span': {
                        '& ul, & ol': {
                            color: theme.palette.blue.main,
                            listStyleImage: 'url("/img/deck/bullet.svg")',
                            paddingLeft: 20,
                            marginBottom: 65,
                            ...styles(theme).text12,

                            '& li': {
                                marginBottom: 10,
                                paddingLeft: 30
                            }
                        },
                        '& p': {
                            margin: 0,
                            color: theme.palette.blue['70'],
                            ...styles(theme).text15
                        }
                    }
                }
            },
            keyPointsContainerSmallScreenStyle: {
                marginTop: 150,

                '& .row': {
                    paddingRight: '0.5rem',

                    '& .picture': {
                        marginBottom: 30,

                        '& .imgContainer': {
                            width: '95%',
                            height: '95%'
                        },
                        '& div:last-child': {
                            backgroundColor: theme.palette.green.main,
                            position: 'absolute',
                            left: 20,
                            bottom: -10,
                            width: '95%',
                            height: '97%',
                            zIndex: 0
                        }
                    },
                    '& > div:first-child': {
                        marginTop: 30
                    }
                },
                '& > div:first-child': {
                    '& h1': {
                        margin: 0,
                        color: theme.palette.blue.main,
                        marginBottom: 30,
                        ...styles(theme).title2
                    },
                    '& span': {
                        '& ul, & ol': {
                            color: theme.palette.blue.main,
                            listStyleImage: 'url("/img/deck/bullet.svg")',
                            paddingLeft: 20,
                            marginBottom: 35,
                            ...styles(theme).text20,

                            '& li': {
                                marginBottom: 10,
                                paddingLeft: 15
                            }
                        },
                        '& p': {
                            margin: 0,
                            color: theme.palette.blue['70'],
                            ...styles(theme).text15
                        }
                    }
                }
            }
        }
    )
)

interface AcceptingProps {
    keyPoints: DeckKeyPoints
}

type DeckKeyPointsProps = AcceptingProps

const DeckKeyPointsPage: React.FC<DeckKeyPointsProps> = (
    {
        keyPoints
    }
) => {
    const classes = useStyles()
    const { isLargeScreen } = useContext(ResponsiveContext)

    const keyPointsContainerClass: ClassValue = classNames(
        classes.keyPointsContainerStyle,
        isLargeScreen ? classes.keyPointsContainerLargeScreenStyle : classes.keyPointsContainerSmallScreenStyle
    )

    const listClass: ClassValue = classNames(
        'col-xs-12',
        {
            'col-md-6': keyPoints.picture
        }
    )

    return (
        <div className={keyPointsContainerClass}>
            <div className="row between-md marginContent">
                {
                    keyPoints.picture && isLargeScreen && (
                        <div className="picture col-md-4">
                            <div className="imgContainer">
                                <img src={keyPoints.picture} alt="Keypoints"/>
                                {
                                    keyPoints.caption && (
                                        <span>{keyPoints.caption}</span>
                                    )
                                }
                            </div>
                        </div>
                    )
                }


                <div className={listClass}>
                    <h1>
                        <FormattedMessage
                            id="deck.keyPoints.title"
                            defaultMessage="Points clés"
                            description="Key points title"
                        />
                    </h1>

                    <span>{parse(keyPoints.text)}</span>
                </div>

                {
                    keyPoints.picture && !isLargeScreen && (
                        <div className="picture col-xs-12">
                            <div className="imgContainer">
                                <img src={keyPoints.picture} alt="Keypoints"/>
                                {
                                    keyPoints.caption && (
                                        <span>{keyPoints.caption}</span>
                                    )
                                }
                            </div>
                            <div></div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default DeckKeyPointsPage