import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useDispatch } from 'react-redux'
import Loader from '../../components/loader/Loader'
import Errors from '../../components/errors/Errors'
import localStorageEnum from '../../enums/localStorageEnum'
import links from '../../enums/linksEnum'
import { checkToken } from './api/checkToken'
import { saveToken } from './reducer/actions'
import { removeUser } from '../profile/reducer/actions'
import { loadSelection } from '../selection/reducer/actions'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'

interface MatchParamsModel {
	token: string
}

const CheckToken = () => {
	const history = useHistory()
	const match = useRouteMatch<MatchParamsModel>()
	const dispatch = useDispatch()

	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<JSX.Element | null>(null)

	useEffect(
		() => {
			localStorage.removeItem(localStorageEnum.token)
			localStorage.removeItem(localStorageEnum.url_token)
			dispatch(
				removeUser()
			)
			dispatch(
				loadSelection()
			)

			const tokenParam = match.params.token

			if (!error) {
				checkToken(tokenParam, dispatch)
					.then(
						(token) => {
							if (typeof token === 'string') {
								localStorage.setItem(localStorageEnum.token, token)
								localStorage.setItem(localStorageEnum.url_token, tokenParam)

								dispatch(
									saveToken(token)
								)
							}
							redirectToWithQuery(history, links.homepage)
						}
					)
					.catch(
						(code?: number) => {
							if (code) {
								setError(<Errors statusCode={code} />)
							}
						}
					)
					.finally(
						() => setLoading(false)
					)
			}
		}, [dispatch, error, history, match.params.token]
	)

	if (loading || !error) {
		return <Loader />
	}

	return (
		<>
			{error}
		</>
	)
}

export default CheckToken
