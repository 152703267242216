import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgFullScreen = (
    {
        height,
        width,
        isOpacity = true,
        color = '#FFF'
    }: IconsModel
) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 26 26">
        <path
            d="M1 6.455V1h5.455m13.09 0H25v5.455m0 8.727v5.454h-5.455m-13.09 0H1v-5.454m5.455-8.727h13.091v8.727H6.455z"
            stroke={color}
            strokeWidth={2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="square"
            opacity={isOpacity ? 0.6 : 1}
        />
    </svg>
)

export default SvgFullScreen