import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgCommercial = (
    {
        color,
        width = 15,
        height = 15
    }: IconsModel
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={width}
        height={height}
    >
        <defs>
            <filter id="commercial_svg__a">
                <feColorMatrix
                    in="SourceGraphic"
                    values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                />
            </filter>
            <path id="commercial_svg__b" d="M0 .07h38.922v47.78H0z"/>
        </defs>
        <g
            filter="url(#commercial_svg__a)"
            transform="translate(-289 -662)"
            fill="none"
            fillRule="evenodd"
        >
            <g transform="translate(289.36 662)">
                <mask id="commercial_svg__c" fill={color}>
                    <use xlinkHref="#commercial_svg__b"/>
                </mask>
                <path
                    fill="#1D1D1B"
                    d="M6.083 6.91l21.895 5.604-2.972 33.462-23.38-5.983L6.083 6.91zm29.562 2.843L25.83 7.297l-.347 1.452 8.817 2.207 2.944 28.633c-3.836-.569-8.2 2.417-10.675 5.301l2.976-33.516-5.54-1.418v-4.37c0-3.042-2.417-5.516-5.388-5.516-2.97 0-5.388 2.474-5.388 5.516h1.46c0-2.217 1.762-4.021 3.928-4.021s3.928 1.804 3.928 4.02v3.997L4.859 5.056 0 41.118l26.306 6.732v-.003l.003.001c1.508-3.087 7.417-7.99 11.538-6.583l1.075.367-3.277-31.879z"
                    mask="url(#commercial_svg__c)"
                />
            </g>
            <path
                fill="#1D1D1B"
                d="M307.191 691.854c-.906.71-2.275.864-4.067.456-1.474-.334-2.46-.968-2.93-1.882-.609-1.184-.277-2.672.051-3.61l8.286 1.994c-.075 1.066-.392 2.3-1.34 3.042zm3.212-8.856l-3.53 2.909V682h-1.493v4.543l-6.03-1.45-.25.57c-.52 1.188-1.242 3.475-.237 5.428.68 1.321 2 2.213 3.925 2.65.761.173 1.47.259 2.124.259 1.284 0 2.358-.333 3.207-.998 1.257-.985 1.922-2.631 1.922-4.761v-.576l-2.307-.555 3.625-2.987-.957-1.125z"
            />
        </g>
    </svg>
)

export default SvgCommercial