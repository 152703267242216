export enum deckEnum {
	presentation = 'presentation',
	location = 'location',
	keyPoints = 'keyPoints',
	spaces = 'spaces',
	visit = 'visit',
	technical = 'technical',
	csr = 'csr',
	services = 'services',
	pictures = 'pictures',
	contacts = 'contacts'
}
