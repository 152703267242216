import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import links from '../../enums/linksEnum'
import SvgStarSelection from '../../icons/StarSelection'
import { makeStyles } from '@material-ui/core/styles'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import Badge from '../badge/Badge'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../index'
import { badgeEnum } from '../badge/enum/badgeEnum'
import { ClickAwayListener, Tooltip, withStyles } from '@material-ui/core'
import { closeTooltip } from '../../modules/favorites/reducer/actions'
import { useIntl } from 'react-intl'
import Fade from '@material-ui/core/Fade'
import styles from '../../styles'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'

const useStyles = makeStyles(
    (theme) => (
        {
            widgetFavoritesContainerStyle: {
                cursor: 'pointer',
                position: 'fixed',
                zIndex: 2,

                '& svg': {
                    padding: 15,
                    borderRadius: '100%',
                    background: theme.palette.blue.main,
                    border: `1px solid ${theme.palette.blue['50']}`
                }
            },
            widgetFavoritesContainerLargeScreenStyle: {
                bottom: 30,
                left: 35
            },
            widgetFavoritesContainerSmallScreenStyle: {
                bottom: 25,
                left: 15
            }
        }
    )
)

const CustomTooltip = withStyles(
    (theme) => (
        {
            tooltip: {
                background: theme.palette.blue.main,
                color: 'white',
                ...styles(theme).text3
            },
            arrow: {
                color: theme.palette.blue.main
            }
        }
    )
)(Tooltip)

const WidgetFavorites = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const intl = useIntl()
    const favorites = useSelector((state: RootState) => state.favorites)
    const dispatch = useDispatch()
    const { isLargeScreen } = useContext(ResponsiveContext)

    const [hasScrolled, setHasScrolled] = useState<boolean>(false)

    const widgetFavoritesContainerClass: ClassValue = classNames(
        classes.widgetFavoritesContainerStyle,
        isLargeScreen ? classes.widgetFavoritesContainerLargeScreenStyle : classes.widgetFavoritesContainerSmallScreenStyle
    )

    useEffect(
        () => {
            setHasScrolled(false)
        }, [location.pathname, location.search]
    )

    useEffect(
        () => {
            window.addEventListener('scroll', (event) => {
                if (!hasScrolled && window.pageYOffset > 20) {
                    setHasScrolled(true)
                }

                if (!favorites.displayTooltip && !isLargeScreen) {
                    dispatch(
                        closeTooltip()
                    )
                }
            })
        }, [hasScrolled, dispatch, favorites.displayTooltip, isLargeScreen]
    )

    const goToFavorites = () => {
        redirectToWithQuery(history, links.favorites)
    }

    const handleTooltipClose = useCallback(
        () => {
            dispatch(
                closeTooltip()
            )
        }, [dispatch]
    )

    useEffect(
        () => {
            if (favorites.displayTooltip && !isLargeScreen) {
                setTimeout(() => handleTooltipClose(), 2000)
            }
        }, [favorites.displayTooltip, handleTooltipClose, isLargeScreen]
    )

    const WidgetComponent = () => (
        <CustomTooltip
            PopperProps={{
                disablePortal: true
            }}
            TransitionComponent={Fade}
            placement={isLargeScreen ? 'right' : 'top'}
            arrow
            onClose={handleTooltipClose}
            open={favorites.displayTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
                intl.formatMessage(
                    {
                        id: 'favorites.tooltip',
                        defaultMessage: 'Vous avez mis une offre dans vos favoris. Retrouvez ici tous vos favoris',
                        description: 'Tooltip text'
                    }
                )
            }
        >
            <div
                className={widgetFavoritesContainerClass}
                onClick={goToFavorites}
            >
                <Badge
                    badgeContent={favorites.favorites.length > 9 ? '9+' : favorites.favorites.length}
                    type={badgeEnum.favorites}
                >
                    <SvgStarSelection
                        color="#FFFFFF"
                        width={22}
                        height={22}
                    />
                </Badge>
            </div>
        </CustomTooltip>
    )

    if (isLargeScreen) {
        return (
            <ClickAwayListener onClickAway={handleTooltipClose}>
                {WidgetComponent()}
            </ClickAwayListener>
        )
    }

    return (
        <WidgetComponent/>
    )
}

export default WidgetFavorites
