import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/App'
import * as serviceWorker from './serviceWorker'
import { createBrowserHistory } from 'history'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'
import { intlReducer, Provider } from 'react-intl-redux'
import './font/montserrat/montserrat.css'
import './font/blackerdisplay/blackerdisplay.css'
import LangProvider from './components/langContext/langContext'
import { CookiesProvider } from 'react-cookie'
import reducers from './config/reducers'
import { reducer as formReducer } from 'redux-form'
import localStorageEnum from './enums/localStorageEnum'
import ResponsiveProvider from './utils/context/responsiveContext'
import ThemeProvider from './utils/context/themeContext'
import { updateGTM } from './utils/gtm/updateGTM'

interface DefaultConfModel {
	login_uri: string
}

const history = createBrowserHistory()

const rootReducer = combineReducers({
	...reducers,
	router: connectRouter(history),
	intl: intlReducer,
	form: formReducer
})

export type RootState = ReturnType<typeof rootReducer>

const reactInit = () => {
	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	history.listen(
		(location) => {
			updateGTM(location as unknown as Location)
		}
	)

	// En mode dév, on utilise le devtools
	// @ts-ignore
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		//@ts-ignore
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	let store = createStore(
		rootReducer,
		compose(...enhancers)
	)

	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ThemeProvider>
						<LangProvider>
							<ResponsiveProvider>
								<CookiesProvider>
									<App />
								</CookiesProvider>
							</ResponsiveProvider>
						</LangProvider>
					</ThemeProvider>
				</ConnectedRouter>
			</Provider>

		</React.StrictMode>,
		document.getElementById('root')
	)
}

// TODO Changer pour fetch
localStorage.setItem(localStorageEnum.commonUri,
	JSON.stringify(
		{
			login_uri: 'https://youfirstlogin.marredondo.ipsosenso.com/login'
		}
	)
)

reactInit()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()