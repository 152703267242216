import React, { useContext, useState } from 'react'
import { DeckPictures, DeckPlans, VisitPerspectives } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import SwiperComponent from 'swiper'
import 'swiper/swiper.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import formatNumber from '../../../utils/formatter/formatNumber'
import SvgFullScreen from '../../../icons/FullScreen'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import styles from '../../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import { isModalVisible } from '../../../components/dialog/reducer/selector'
import { modalTypes } from '../../../components/dialog/enum/modalTypes'
import { displayModal, hideModal } from '../../../components/dialog/reducer/actions'

const useStyles = makeStyles(
    (theme) => (
        {
            sliderContainerStyle: {
                position: 'relative',

                '& .sliderImage': {
                    '& img': {
                        width: '100%'
                    }
                },
                '& .sliderDetails': {
                    background: theme.palette.blue.main,

                    '& .index': {
                        display: 'flex',

                        '& span': {
                            color: 'white'
                        },
                        '& span:last-child': {
                            opacity: 0.6
                        },
                        '& .rectangle': {
                            width: 59,
                            height: 1,
                            opacity: 0.6,
                            backgroundColor: 'white'
                        }
                    },
                    '& .title p': {
                        margin: 0,
                        color: 'white'
                    },
                    '& .sliderButton': {
                        height: '100%',
                        background: theme.palette.accessibility.main,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',

                        '& svg': {
                            color: 'white'
                        }
                    }
                },
                '& .sliderFullScreen': {
                    cursor: 'pointer'
                }
            },
            sliderContainerLargeScreenStyle: {
                bottom: -65,

                '& .sliderImage': {
                    '& img': {
                        height: 600,
                        verticalAlign: 'bottom'
                    }
                },
                '& .sliderDetails': {
                    height: 100,

                    '& .index': {
                        '& span': {
                            ...styles(theme).text13
                        },
                        '& .rectangle': {
                            margin: '13px 20px'
                        }
                    },
                    '& .title p': {
                        ...styles(theme).text1_opacity
                    },
                    '& svg': {
                        width: 30,
                        height: 30
                    }
                }
            },
            sliderContainerSmallScreenStyle: {
                bottom: -25,

                '& .sliderImage': {
                    position: 'relative',

                    '& .sliderFullScreen': {
                        position: 'absolute',
                        top: 10,
                        right: 10
                    }
                },
                '& .sliderDetails': {
                    height: 50,
                    position: 'relative',
                    bottom: 4,

                    '& .index': {
                        '& span': {
                            ...styles(theme).text1
                        },
                        '& .rectangle': {
                            margin: '12px 5px 0'
                        }
                    },
                    '& .title p': {
                        ...styles(theme).text2_opacity
                    }
                }
            },
            sliderContainerModalLargeScreenStyle: {
                bottom: 0,

                '& > div': {
                    padding: 0,

                    '& .swiper-container': {
                        background: theme.palette.blue.main
                    }
                },
                '& .sliderImage': {
                    display: 'flex',
                    background: theme.palette.blue.main
                }
            },
            sliderContainerModalSmallScreenStyle: {
                bottom: 0
            }
        }
    )
)

interface AcceptingProps {
    content: DeckPlans[] | DeckPictures[] | VisitPerspectives[]
}

type deckSliderProps = AcceptingProps

const DeckSlider: React.FC<deckSliderProps> = (
    {
        content
    }
) => {
    const classes = useStyles()
    const isOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.deck))
    const dispatch = useDispatch()
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

    const [swiper, setSwiper] = useState<SwiperComponent>()
    const [index, setIndex] = useState<number>(0)

    const sliderContainerClass: ClassValue = classNames(
        'row',
        classes.sliderContainerStyle,
        isLargeScreen ? classes.sliderContainerLargeScreenStyle : classes.sliderContainerSmallScreenStyle,
        {
            [classes.sliderContainerModalLargeScreenStyle]: isLargeScreen && isOpen,
            [classes.sliderContainerModalSmallScreenStyle]: !isLargeScreen && isOpen
        }
    )

    const onButtonClicked = (direction: 'previous' | 'next') => {
        if (direction === 'previous') {
            swiper && swiper.slidePrev()
        } else {
            swiper && swiper.slideNext()
        }
    }

    const openImage = () => {
        if (isOpen) {
            dispatch(
                hideModal(modalTypes.deck)
            )
        } else {
            dispatch(
                displayModal(modalTypes.deck, { content })
            )
        }
    }

    return (
        <div className={sliderContainerClass}>
            <div className="col-xs-12">
                <Swiper
                    onSwiper={setSwiper}
                    loop
                    onSlideChange={s => setIndex(s.realIndex)}
                >
                    {
                        content.map(
                            (plan) => (
                                <SwiperSlide key={plan.title}>
                                    <div className="sliderImage">
                                        <img src={plan.picture} alt={plan.caption ? plan.caption : plan.title}/>

                                        {
                                            !isLargeScreen && !isTabletteScreen && (
                                                <div
                                                    className="sliderFullScreen"
                                                    onClick={openImage}
                                                >
                                                    <SvgFullScreen width={24} height={20} isOpacity={false}/>
                                                </div>
                                            )
                                        }
                                    </div>
                                </SwiperSlide>
                            )
                        )
                    }
                </Swiper>

                <div className="sliderDetails row middle-xs">
                    <div className="index col-xs-3 center-xs">
                        <span>{formatNumber((index + 1).toString(), 2)}</span>

                        <div className="rectangle"></div>

                        <span>{formatNumber(content.length.toString(), 2)}</span>
                    </div>

                    <div className="title col-xs-5 col-md-6">
                        <p>{content[index].title}</p>
                    </div>

                    {
                        isTabletteScreen && (
                            <div
                                className="sliderFullScreen col-xs-2 col-md-1"
                                onClick={openImage}
                            >
                                <SvgFullScreen width={24} height={20}/>
                            </div>
                        )
                    }

                    <div
                        className="sliderButton col-xs-2 col-sm-1"
                        onClick={() => onButtonClicked('previous')}
                    >
                        <KeyboardArrowLeftIcon/>
                    </div>

                    <div
                        className="sliderButton col-xs-2 col-sm-1"
                        onClick={() => onButtonClicked('next')}
                    >
                        <KeyboardArrowRightIcon/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeckSlider
