import React from 'react'
import { MakeRequestModel } from '../types/makeRequestModel'

export const request: Pick<MakeRequestModel, 'firstName' | 'lastName' | 'position' | 'mail'> = {
	firstName: '',
	lastName: '',
	position: '',
	mail: ''
}

export const MakeRequestContext = React.createContext({
		request: request, // Data filled step by step
		step: 1,
		finishForm: () => {},
		nextStep: () => {
		},
		resetStep: () => {
		}
	}
)
