import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../../styles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { ResponsiveContext } from '../../../../utils/context/responsiveContext'
import UserForm from './step1/UserForm'
import MessageForm from './step2/MessageForm'
import RequestSummary from './step3/RequestSummary'

const useStyle = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            stepperLargeScreenStyle: {
                margin: '15px auto 0'
            },
            stepperSmallScreenStyle: {
                marginTop: 40
            }
        }
    )
)

interface AcceptingProps {
    step: number
}

type StepperProps = AcceptingProps

const Stepper: React.FC<StepperProps> = (
    {
        step
    }
) => {
    const classes = useStyle()
    const { isLargeScreen } = useContext(ResponsiveContext)

    const stepperClass: ClassValue = classNames(
        {
            [classes.stepperLargeScreenStyle]: isLargeScreen && step < 3,
            [classes.stepperSmallScreenStyle]: !isLargeScreen
        }
    )

    const getComposant = () => {
        switch (step) {
            case 1:
                return <UserForm/>
            case 2:
                return <MessageForm/>
            case 3:
                return <RequestSummary/>
            default:
                return <UserForm/>
        }
    }

    return (
        <div className={stepperClass}>
            <div>
                {getComposant()}
            </div>
        </div>
    )
}

export default Stepper