import React from 'react'
import { POIModel } from '../types/POIModel'

export const POIs: POIModel[] = [
    {
        name: 'Tour Eiffel',
        lat: 48.85828,
        lng: 2.29442,
        icon: <img src="/img/map/eiffelTower.png" alt="Tour Eiffel icon" height={52} />
    },
    {
        name: 'Arc de triomphe',
        lat: 48.87379,
        lng: 2.29532,
        icon:  <img src="/img/map/triomphe.png" alt="Triomphe icon" width={38} />
    },
    {
        name: 'Sacré-cœur',
        lat: 48.88682,
        lng: 2.34309,
        icon:  <img src="/img/map/sacreCoeur.png" alt="Sacré coeur icon" height={53} />
    },
    {
        name: 'Notre-Date',
        lat: 48.85317,
        lng: 2.34990,
        icon:  <img src="/img/map/notreDame.png" alt="Notre dame icon" height={50} />
    },
    {
        name: 'Louvre',
        lat: 48.86117,
        lng: 2.33580,
        icon:  <img src="/img/map/louvre.png" alt="Louvre icon" width={52} />
    },
    {
        name: 'Gare Montparnasse',
        lat: 48.84110,
        lng: 2.32174,
        icon:  <img src="/img/map/train.png" alt="Train icon" width={28}/>
    },
    {
        name: 'Gare de Lyon',
        lat: 48.84444,
        lng: 2.37437,
        icon:  <img src="/img/map/train.png" alt="Train icon" width={28} />
    },
    {
        name: 'Gare du Nord',
        lat: 48.88111,
        lng: 2.35531,
        icon:  <img src="/img/map/train.png" alt="Train icon" width={28} />
    },
    {
        name: 'Gare Saint-Lazare',
        lat: 48.87651,
        lng: 2.32541,
        icon:  <img src="/img/map/train.png" alt="Train icon" width={28} />
    },
    {
        name: 'Gare de l\'Est',
        lat: 48.87642,
        lng: 2.35883,
        icon:  <img src="/img/map/train.png" alt="Train icon" width={28} />
    },
    {
        name: 'Opéra',
        lat: 48.87106,
        lng: 2.33221,
        icon:  <img src="/img/map/opera.png" alt="Opera icon" width={52} />
    }
]