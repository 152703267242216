import { IntlShape } from 'react-intl'


export const checkTrimester = (date: Date | string, intl: IntlShape) => {
	if (new Date(date).toString() !== 'Invalid Date') {
		let nTrimester=Math.floor((new Date(date).getMonth())/3) + 1;

		return intl.formatMessage(
			{
				id: `offers.research.selects.trimester${nTrimester.toString()}`,
				defaultMessage: '1er trimestre',
				description: 'Date message'
			}, {
				date: new Date(date).getFullYear()
			}
		)
	}
	let nTrimester=Math.floor((Number(date.toString().split('/')[1]))/3)+1;
	return intl.formatMessage(
		{
			id: `offers.research.selects.trimester${nTrimester.toString()}`,
			defaultMessage: '1er trimestre',
			description: 'Date message'
		}, {
			date: date.toString().split('/')[2]
		}
	)
}

export const numberTrimester = (date: Date | string, intl: IntlShape) => {
	if (new Date(date).toString() !== 'Invalid Date') {
		let nTrimester=Math.floor((new Date(date).getMonth())/3) + 1;
		return intl.formatMessage(
			{
				id: `offers.research.selects.trimester${nTrimester.toString()}`,
				defaultMessage: '1er semestre',
				description: 'Date message'
			}, {
				date: new Date(date).getFullYear()
			}
		).charAt(0)
	}

	let nTrimester=Math.floor((Number(date.toString().split('/')[1]))/3)+1;
	return intl.formatMessage(
		{
			id: `offers.research.selects.trimester${nTrimester.toString()}`,
			defaultMessage: '1er semestre',
			description: 'Date message'
		}, {
			date: date.toString().split('/')[2]
		}
	).charAt(0)
}
