import React from 'react'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../styles'
import { ColumnsModel, LinesModel } from './types/tableModel'

const StyledTableCell = withStyles(
	(theme) => (
		{
			head: {
				boxSizing: 'border-box',
				height: 65,
				textAlign: 'center',
				color: theme.palette.blue.main,
				...styles(theme).text15,
				borderBottom: 'inherit'
			},
			body: {
				boxSizing: 'border-box',
				height: 65,
				textAlign: 'center',
				color: theme.palette.blue.main,
				...styles(theme).text15
			}
		}
	)
)(TableCell)

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			noBorder: {
				'& tr:last-child td': {
					borderBottom: 'inherit'
				}
			},
			backgroundEven: {
				background: theme.palette.blue['5']
			},
			backgroundOdd: {
				background: theme.palette.blue['10']
			}
		}
	)
)

interface AcceptingProps {
	columns: ColumnsModel[],
	lines: LinesModel[]
}

type TableProps = AcceptingProps

const Table: React.FC<TableProps> = (
	{
		columns,
		lines
	}
) => {
	const classes = useStyles()

	return (
		<TableContainer>
			<MuiTable aria-label="simple table">
				<TableHead>
					<TableRow>
						{
							columns.map((column) => (
									<StyledTableCell
										key={column.label}
										style={{ width: column.width }}
									>
										<span>{column.label}</span>
									</StyledTableCell>
								)
							)
						}
					</TableRow>
				</TableHead>
				<TableBody className={classes.noBorder}>
					{
						(lines || []).map((line, index) => (
								<TableRow
									key={line.id ? line.id : index}
									className={
										index % 2 ? classes.backgroundEven : classes.backgroundOdd
									}
								>
									{
										(columns || []).map((column) => (
											<StyledTableCell key={column.label}>
												{
													<div>
														{(column.render && column.render(line)) || line[column.value]}
													</div>
												}
											</StyledTableCell>
										))
									}
								</TableRow>
							)
						)
					}
				</TableBody>
			</MuiTable>
		</TableContainer>
	)
}

export default Table
