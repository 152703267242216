import { FavoritesTypes } from '../types/types'
import { CLOSE_TOOLTIP, MANAGE_FAVORITES, SAVE_FAVORITES } from './actionTypes'
import { SelectionModel } from '../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../components/offerDetails/types/offerDetailsModel'

export const saveFavorites = (payload: (SelectionModel & OfferDetailsModel)[]): FavoritesTypes => (
	{
		type: SAVE_FAVORITES,
		payload: payload
	}
)

export const manageFavorites = (payload: (SelectionModel & OfferDetailsModel)): FavoritesTypes => (
	{
		type: MANAGE_FAVORITES,
		payload: payload
	}
)


export const closeTooltip = (): FavoritesTypes => (
	{
		type: CLOSE_TOOLTIP
	}
)