import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../styles'

const useStyles = makeStyles(
	(theme) => (
		{
			titleContainer: {
				...styles(theme).title3,
				color: theme.palette.blue.main,
				margin: 0
			}
		}
	)
)

const Header = () => {
	const classes = useStyles()

	return (
		<h1 className={classes.titleContainer}>
			<FormattedMessage
				id="popinYf.makeRequest.title"
				defaultMessage="Votre demande"
				description="Title of the popin"
			/>
		</h1>
	)
}

export default Header
