import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../index'
import parse from 'html-react-parser'
import styles from '../../styles'
import Button from '../../components/button/Button'
import { useHistory } from 'react-router'
import links from '../../enums/linksEnum'
import { animateScroll as scroll } from 'react-scroll'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { getHomepageBackground } from './api/getHomepageBackground'
import Loader from '../../components/loader/Loader'
import languages from '../../components/langContext/enum/languagesEnum'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'

SwiperCore.use([Autoplay])

const useStyle = makeStyles(
	(theme) => (
		{
			...styles(theme),
			homepageContainerStyle: {
				position: 'relative',
				width: '100%',
				minHeight: '80vh'
			},
			backgroundHomepage: {
				width: '100%',
				height: '100%',
				minHeight: 800,
				position: 'absolute',

				'& > div': {
					position: 'relative',
					height: '100%',

					'& img': {
						width: '100%',
						minHeight: 800
					},

					'& > div': {
						backgroundColor: theme.palette.blue.main,
						opacity: 0.5,
						width: '100%',
						height: '100%',
						position: 'absolute',
						top: 0,
						left: 0,
						minHeight: 800,
						zIndex: 0
					}
				}
			},
			textContainerStyle: {
				'& h1': {
					marginTop: 0
				}
			},
			textContainerLargeScreenStyle: {
				width: '50%',
				margin: '40px auto',
				position: 'relative',
				maxWidth: 1100,

				'& > div:first-child': {
					backgroundColor: 'white',
					position: 'relative',
					zIndex: 1,
					minHeight: 430,
					padding: 80,

					'& h1': {
						marginBottom: 40
					}
				},
				'& .rectangle': {
					width: 173,
					height: 397,
					backgroundColor: theme.palette.green.main,
					position: 'absolute',
					right: '-20px',
					bottom: '-20px',
					padding: 0
				},
				'& > div > div:last-child': {
					'& button': {
						width: 'fit-content'
					},
					'& > div:last-child': {
						marginTop: 25,
						padding: 0
					},
					'& > div:first-child': {
						marginTop: 40,
						padding: 0
					}
				}
			},
			textContainerSmallScreen: {
				width: '100%',

				'& > div:first-child': {
					paddingTop: 40,

					'& h1': {
						marginBottom: 20
					}
				},
				'& > div > div:last-child': {
					'& > div:last-child': {
						marginTop: 25,
						padding: 0
					},
					'& > div:first-child': {
						marginTop: 40,
						padding: 0
					}
				}
			}
		}
	)
)

const Homepage = () => {
	const classes = useStyle()
	const history = useHistory()
	const intl = useIntl()
	const selections = useSelector((state: RootState) => state.selection)
	const promoted = useSelector((state: RootState) => state.promoted)
	const message = useSelector((state: RootState) => state.user.homepage_message_en && intl.locale === languages.en ? state.user.homepage_message_en : state.user.homepage_message_fr)
	const dispatch = useDispatch()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const [loading, setLoading] = useState<boolean>(true)
	const [backgroundPictures, setBackgroundPictures] = useState<string[]>(['img/homepage/homepage_bg1.jpg'])

	const homepageContainer: ClassValue = classNames(
		classes.homepageContainerStyle
	)

	const backgroundHomepageClass: ClassValue = classNames(
		{
			[classes.backgroundHomepage]: isLargeScreen
		}
	)

	const textContainerClass: ClassValue = classNames(
		classes.textContainerStyle,
		isLargeScreen ? classes.textContainerLargeScreenStyle : classes.textContainerSmallScreen
	)

	const titleClass: ClassValue = classNames(
		classes.colorBlue,
		isLargeScreen ? classes.title1 : classes.title3
	)

	const textClass: ClassValue = classNames(
		classes.colorBlue,
		classes.text20
	)

	useEffect(
		() => {
			getHomepageBackground()
				.then(
					(pictures: any) => {
						if (pictures.length > 0) {
							setBackgroundPictures(pictures)
						}
					}
				)
				.finally(
					() => setLoading(false)
				)
		}, [dispatch]
	)

	const seeSelection = () => {
		scroll.scrollToTop()
		redirectToWithQuery(history, links.selection)
	}

	const seeOffers = () => {
		redirectToWithQuery(history, links.offers)
	}

	const openDeck = () => {
		redirectToWithQuery(history, `${links.deskDetails}/${promoted}`)
	}

	if (loading) {
		return (
			<Loader />
		)
	}

	return (
		<div className={homepageContainer}>
			{
				isLargeScreen && (
					<div className={backgroundHomepageClass}>
						<div>
							<Swiper
								loop
								autoplay={{
									delay: 5000,
									disableOnInteraction: false
								}}
							>
								{
									backgroundPictures.map(
										(picture) => (
											<SwiperSlide key={picture}>
												<div className="sliderImage">
													<img
														src={picture}
														alt="Background img"
													/>
												</div>
											</SwiperSlide>
										)
									)
								}
							</Swiper>

							<div></div>
						</div>
					</div>
				)
			}

			<div className={textContainerClass}>
				<div>
					<h1 className={titleClass}>
						<FormattedMessage
							id="homepage.title"
							defaultMessage="Bienvenue"
							description="Homepage's title"
						/>
					</h1>

					<span className={textClass}>{message && parse(message.replace(/\n/g, '<br />'))}</span>

					<div>
						{
							promoted && promoted.length > 0 && (
								<div>
									<Button
										background="backgroundAccessibility"
										color="colorWhite"
										textStyle="text8"
										border="borderAccessibility"
										onClick={openDeck}
									>
										<FormattedMessage
											id="homepage.lastVisit"
											defaultMessage="Retrouvez votre dernière visite"
											description="Custom selection button"
										/>
									</Button>
								</div>
							)
						}

						{
							selections.selections.selectedOffers.length > 0 && (
								<div>
									<Button
										background={promoted && promoted.length > 0 ? 'backgroundWhite' : 'backgroundAccessibility'}
										color={promoted && promoted.length > 0 ? 'colorAccessibility' : 'colorWhite'}
										textStyle="text8"
										border="borderAccessibility"
										onClick={seeSelection}
									>
										<FormattedMessage
											id="homepage.customSelection"
											defaultMessage="Découvrez votre sélection personnalisée"
											description="Custom selection button"
										/>
									</Button>
								</div>
							)
						}
						{
							selections.selections.selectedOffers.length === 0 && (
								<div>
									<Button
										background={promoted && promoted.length > 0 ? 'backgroundWhite' : 'backgroundAccessibility'}
										color={promoted && promoted.length > 0 ? 'colorAccessibility' : 'colorWhite'}
										textStyle="text8"
										border="borderAccessibility"
										onClick={seeOffers}
									>
										<FormattedMessage
											id="homepage.allOffers"
											defaultMessage="Retrouvez toutes nos offres"
											description="All offers button"
										/>
									</Button>
								</div>
							)
						}
					</div>
				</div>

				{
					isLargeScreen && <div className="rectangle"></div>
				}
			</div>
		</div>
	)
}

export default Homepage
