import fetchFactory from '../../../utils/fetchFactory'
import {configEnum} from '../../../enums/fetchFactoryEnum'
import {CustomDocModel} from '../types/customDocModel'

export const getCustomDoc = (content: CustomDocModel) => {
    return fetchFactory('/cms/fr/api/sharepoint/list',
        {
            method: configEnum.post,
            body: JSON.stringify(content)
        }
    )
}