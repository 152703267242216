import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Element from 'react-scroll/modules/components/Element'
import { isCardHovered } from '../../selection/reducer/actions'
import { manageFavorites as manageFavoritesAction } from '../reducer/actions'
import { isCardHoveredInitialState } from '../../selection/reducer/reducers'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { RootState } from '../../../index'
import { useHistory } from 'react-router'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import { manageFavorites } from '../../selection/api/manageFavorites'
import errorCatcher from '../../../utils/errorCatcher'
import links from '../../../enums/linksEnum'
import SelectionCard from '../../../components/offersDisplay/components/SelectionCard'
import { SelectionModel } from '../../selection/types/selectionModel'
import { OfferDetailsModel } from '../../../components/offerDetails/types/offerDetailsModel'
import { redirectToWithQuery } from '../../../utils/formatter/formatRouter'

const useStyles = makeStyles(
	(theme) => (
		{
			selectionTitles: {
				'& > h1': {
					color: theme.palette.blue.main,
					marginTop: 0
				},

				'& > .title': {
					marginTop: 30
				},
				'& > h1:nth-child(2)': {
					marginTop: 22
				},
				'& div div': {
					padding: 0
				},
				'& .shortDiv-0': {
					paddingRight: 10
				},
				'& .shortDiv-1': {
					paddingLeft: 10
				},
				'& svg': {
					zIndex: 1
				}
			},
			selectionTitlesLargeScreenStyle: {
				'& > h1': {
					marginBottom: 20,
					...styles(theme).title1
				}
			},
			selectionTitlesSmallScreenStyles: {
				'& > h1': {
					marginBottom: 15,
					...styles(theme).title1
				}
			}
		}
	)
)

const MANAGE_FAVORITE = 'manage_favorite'

const FavoritesComponent = () => {
	const classes = useStyles()
	const history = useHistory()
	const user = useSelector((state: RootState) => state.user)
	const favorites = useSelector((state: RootState) => state.favorites)
	const dispatch = useDispatch()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const selectionTitlesClass: ClassValue = classNames(
		classes.selectionTitles,
		isLargeScreen ? classes.selectionTitlesLargeScreenStyle : classes.selectionTitlesSmallScreenStyles
	)

	const onItemSelected = (favorite: (SelectionModel & OfferDetailsModel)) => {
		manageFavorites(user.id, favorite.id, !favorite.selected)
			.then(
				() => {
					dispatch(
						manageFavoritesAction(
							{
								...favorite,
								selected: !favorite.selected
							}
						)
					)
				}
			)
			.catch(
				(error: any) => dispatch(
					errorCatcher(error, MANAGE_FAVORITE)
				)
			)
	}

	const seeDetails = (id: string) => {
		redirectToWithQuery(history, `${links.deskDetails}/${id}`)
	}

	return (
		<div
			className={selectionTitlesClass}
		>
			<h1 className="title">
				<FormattedMessage
					id="favorites.title"
					defaultMessage="Vos favoris"
					description="Favorites title"
				/>
			</h1>

			<div className="row selectionCardContainer">
				{
					favorites.favorites.sort(
						(a: (SelectionModel & OfferDetailsModel), b: (SelectionModel & OfferDetailsModel)) => {
							const tmpA = Number(a.divisibleFrom) === 0 ? Number(a.availableArea) : Number(a.divisibleFrom)
							const tmpB = Number(b.divisibleFrom) === 0 ? Number(b.availableArea) : Number(b.divisibleFrom)

							return tmpB - tmpA
						}
					)
						.map(
							(item, index) => {
								return (
									<Element
										name={item.id}
										className={`col-xs-12 col-sm-6 shortDiv-${index % 2}`}
										key={item.id}
										onMouseEnter={
											() => dispatch(
												isCardHovered(
													{
														isHovered: true,
														offerHovered: item.id
													}
												)
											)
										}
										onMouseLeave={
											() => dispatch(
												isCardHovered(isCardHoveredInitialState)
											)
										}
									>
										<SelectionCard
											item={item}
											selectItem={() => onItemSelected(item)}
											seeDetails={seeDetails}
										/>
									</Element>
								)
							}
						)
				}
			</div>
		</div>
	)
}

export default FavoritesComponent
