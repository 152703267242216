export enum positionEnum {
    associate = '221310000',
    dirBuyer = '221310038',
    dirAdmin = '221310008',
    dirAdminFin = '221310009',
    dirCom = '221310010',
    dirOpe = '221310039',
    dirHR = '221310040',
    dirServ = '221310035',
    dirSysInfo = '221310014',
    dirExpl = '221310011',
    dirDev = '221310041',
    dirFin = '221310012',
    dirImmo = '221310042',
    dirJur = '221310015',
    dirTec = '221310016',
    fund = '221310043',
    ger = '221310018',
    member = '221310044',
    resBuyer = '221310045',
    resImmo = '221310046',
    resJur = '221310031',
    resTec = '221310032',
    other = '221310002'
}