import flatten from 'flat'
import { ModelTranslations } from '../components/langContext/languagesModel'

const target = {
	global: {
		unit: '{value} m² ',
		unitStandalone: ' m²',
		btn: {
			save: 'Enregistrer',
			cancel: 'Annuler',
			accept: 'Accepter',
			refuse: 'Refuser',
			next: 'Suivant',
			validate: 'Valider',
			send: 'Envoyer'
		},
		errors: {
			required: 'Requis*',
			formatMail: 'Le format du mail n\'est pas correct',
			emptyOffers: 'Aucune offre n\'a pu être récupérée',
			authorizedExt: 'Fichiers autorisés : .png, .jpg, .jpeg, .gif, .pdf',
			validateCaptcha: 'Merci de valider le CAPTCHA'
		}
	},
	app: {
		details: 'En savoir plus',
		back: 'Retour'
	},
	errors: {
		default: 'Une erreur est survenue, veuillez réessayer plus tard',
		unauthorized: 'Vous n\'êtes pas autorisé à accéder à ce contenu'
	},
	menu: {
		close: 'Fermer',
		homepage: 'Accueil',
		selection: 'Votre sélection',
		research: 'Votre recherche',
		offers: 'Toutes nos offres',
		documents: 'Vos documents',
		profile: 'Votre profil',
		logout: 'Se déconnecter'
	},
	homepage: {
		title: 'Bienvenue',
		lastVisit: 'Retrouvez votre dernière visite',
		customSelection: 'Découvrez votre sélection personnalisée',
		allOffers: 'Retrouvez toutes nos offres'
	},
	selection: {
		visits: 'Vos visites',
		visit: 'Votre dernière visite',
		selectedOffers: 'Notre sélection personnalisée',
		availableArea: 'Surface disponible ',
		around: '(environ)',
		divisibleFrom: 'Divisible à partir de',
		indicativeAvailability: 'Disponibilité indicative',
		availability: 'Disponibilité',
		immediate: 'Immédiate',
		nature: {
			label: 'Nature du bail',
			commercial: 'Bail commercial',
			derogatory: 'Bail dérogatoire',
			prestations_services: 'Contrat de prestations de services'
		},
		elementDisplayed: {
			offers: 'Carte',
			map: 'Voir la liste'
		},
		rent: 'Déjà loué'
	},
	deck: {
		menu: {
			presentation: 'Présentation',
			location: 'Localisation',
			keyPoints: 'Points clés',
			spaces: 'Espaces',
			visit: 'Visite Virtuelle',
			technical: 'Technique',
			csr: 'RSE',
			services: 'Services',
			pictures: 'Photos',
			contacts: 'Contacts'
		},
		presentation: {
			title: 'Présentation',
			printError: 'Erreur lors de l\'impression du deck'
		},
		location: {
			title: 'Localisation et transports',
			caption: 'Immeubles du patrimoine Gecina',
			stop: 'Accès en transports en commun',
			metro: 'Métro',
			rer: 'RER',
			bus: 'Bus',
			tramway: 'Tramway',
			transilien: 'Transilien'
		},
		keyPoints: {
			title: 'Points clés'
		},
		spaces: {
			title: 'Les espaces'
		},
		visit: {
			title: {
				virtual: 'Visite virtuelle',
				presentation: 'Présentation 3D'
			},
			fullScreen: 'Voir en plein écran',
			perspective: 'Perspectives'
		},
		surfaces: {
			title: 'Tableau des surfaces disponibles',
			level: 'Niveau',
			surface: 'Surface utile brute locative (environ)',
			garden: 'Terrasses / Jardin',
			availableSurface: 'Surface disponible',
			estimation: 'Capacitaire maximal estimé'
		},
		plans: {
			title: 'Plans'
		},
		technical: {
			title: 'Eléments techniques généraux',
			download: 'Télécharger le dossier technique complet',
			filename: 'dossier Technique'
		},
		csr: {
			title: 'Engagements, labels et certifications'
		},
		services: {
			title: 'Services'
		},
		pictures: {
			title: 'Photos'
		},
		contacts: {
			title: 'Nos partenaires commercialisateurs'
		},
		credit: {
			credit: 'Crédits photos :',
			nonContractual: 'Non contractuel - données confidentielles'
		}
	},
	footer: {
		details: {
			error: 'La page demandée n\'a pas été trouvée'
		}
	},
	favorites: {
		title: 'Vos favoris',
		tooltip: 'Vous avez mis une offre dans vos favoris. Retrouvez ici tous vos favoris'
	},
	offers: {
		research: {
			title: 'Quel est votre projet ?',
			subtitle: 'Découvrez nos lieux de vie et de travail au cœur de la vie urbaine',
			selects: {
				cities: 'Saisissez les villes',
				disponibility: 'Disponibilité',
				disponibilityPlaceholder: 'Date de disponibilité',
				disponibleNow: 'Disponible immédiatement',
				surface: 'Surface',
				untilSurface: 'Jusqu\'à {max} m²',
				concatSurface: 'De {min} m² à {max} m²',
				firstSemester: '1er semestre {date}',
				secondSemester: '2e semestre {date}',
				trimester1: '1er trimestre {date}',
				trimester2: '2e trimestre {date}',
				trimester3: '3e trimestre {date}',
				trimester4: '4e trimestre {date}',
				oneSemester: '1 semestre sélectionné',
				semesters: '{semesters} semestres sélectionnés'
			},
			errors: {
				minGreaterThanMax: 'Le minimum ne peut pas être supérieur au maximum'
			},
			submit: 'Voir les offres',
			seeAllOffers: 'Découvrez toutes nos offres',
			commercial: {
				title: 'Vous êtes intéressé par un local commercial ?',
				button: 'Cliquez-ici'
			}
		},
		list: {
			offer: '{value} offre',
			match: 'correspond à votre recherche',
			matches: 'correspondent à votre recherche',
			offerInterest: ' et 1 peut vous intéresser',
			offersInterest: ' et {offers} peuvent vous intéresser',
			canInterest: 'Cela peut aussi vous intéresser',
			noMatching: 'Nous n’avons malheureusement pas d’offres correspondant à vos critères'
		}
	},
	cookie: {
		title: 'Gestion des cookies',
		text: 'Ce site utilise Google Analytics. En acceptant, vous nous autorisez à déposer des cookies à des fins de mesure d\'audience. {br} Pour plus d\'information, consultez notre page {page}',
		link: 'Politique de gestion des cookies'
	},
	popinYf: {
		subtitle: 'Votre contact YouFirst',
		listening: 'Je suis à votre écoute',
		sendMessage: 'Envoyer un message',
		phone: 'Téléphone',
		email: 'Email',
		makeRequest: {
			title: 'Votre demande',
			firstStep: {
				lastName: 'Votre nom',
				firstName: 'Votre prénom',
				position: 'Votre fonction',
				mail: 'Votre adresse email',
				positions: {
					associate: 'Associé',
					dirBuyer: 'Directeur achats',
					dirAdmin: 'Directeur administratif',
					dirAdminFin: 'Directeur Administratif et Financier',
					dirCom: 'Directeur commercial',
					dirOpe: 'Directeur des opérations',
					dirHR: 'Directeur des ressources humaines',
					dirServ: 'Directeur des services généraux',
					dirSysInfo: 'Directeur des systèmes informatiques',
					dirExpl: 'Directeur d\'exploitation',
					dirDev: 'Directeur du développement',
					dirFin: 'Directeur financier',
					dirImmo: 'Directeur immobilier',
					dirJur: 'Directeur juridique',
					dirTec: 'Directeur technique',
					fund: 'Fondateur',
					ger: 'Gérant',
					member: 'Membre du COMEX',
					resBuyer: 'Responsable achats',
					resImmo: 'Responsable immobilier',
					resJur: 'Responsable juridique',
					resTec: 'Responsable technique',
					other: 'Autre'
				}
			},
			secondStep: {
				title: 'L\'objet de votre demande',
				message: 'Saisir plus de détails',
				attachment: 'Ajouter une pièce jointe'
			},
			thirdStep: {
				title: 'Votre message a bien été envoyé.',
				message: 'Votre équipe YouFirst vous répondra dès que possible',
				finish: 'Terminer',
				newMessage: 'Envoyer un nouveau message'
			}
		}
	},
	map: {
		transit: 'Transports'
	}
}

export default flatten<typeof target, ModelTranslations>(target)
