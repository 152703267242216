import { IsCardHovered, SelectionTypes } from '../types/types'
import { SelectionReducerModel } from '../types/selectionModel'
import { IS_CARD_HOVERED, LOAD_SELECTION, SAVE_SELECTION } from './actionTypes'
import { IsCardHoveredModel } from '../types/isCardHoveredModel'

export const saveSelection = (selection: SelectionReducerModel): SelectionTypes => (
	{
		type: SAVE_SELECTION,
		payload: selection
	}
)

export const loadSelection = (): SelectionTypes => (
	{
		type: LOAD_SELECTION
	}
)

export const isCardHovered = (payload: IsCardHoveredModel): IsCardHovered => (
	{
		type: IS_CARD_HOVERED,
		payload: payload
	}
)
