import React, { useContext, useMemo } from 'react'
import { SearchFormModel } from '../../types/searchFormModel'
import arrayMutators from 'final-form-arrays'
import SearchBoxCity from '../SearchboxCity'
import { Field, Form } from 'react-final-form'
import { formatDate } from '../../../../utils/formatter/formatDate'
import SelectSurfaceForm from '../../../../components/fields/SelectSurfaceForm'
import { FormattedMessage, useIntl } from 'react-intl'
import { loadDates } from '../../utils/loadDates'
import links from '../../../../enums/linksEnum'
import { useHistory } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { OnChange } from 'react-final-form-listeners'
import { CITIES, DISPONIBILITY_NAME, MAX, MIN, SURFACE_NAME } from '../../../offers/utils/commonConst'
import { ResponsiveContext } from '../../../../utils/context/responsiveContext'
import FormSelectField, { RenderValue } from '../../../../components/fields/FormSelectField'
import { MenuItem } from '@material-ui/core'
import { checkSemester } from '../../utils/checkSemester'
import styles from '../../../../styles'
import { localeEnum } from '../../../../components/langContext/enum/languagesEnum'
import { redirectToWithQuery } from '../../../../utils/formatter/formatRouter'
import queryString from 'query-string'

const useStyles = makeStyles(
    (theme) => (
        {
            formStyle: {
                backgroundColor: theme.palette.blue['5'],
                alignItems: 'center',

                '& > .col-xs-12': {
                    padding: 0
                },
                '& .row': {
                    alignItems: 'center'
                },
                '& .middle-xs': {
                    marginBottom: 0
                },
                '& .MuiTextField-root, & .MuiFormControl-root': {
                    backgroundColor: 'white !important'
                }
            },
            formLargeScreenStyle: {
                padding: '15px 0 15px 30px'
            },
            formSmallScreenStyle: {
                padding: '15px 20px 5px',

                '& .col-xs-12': {
                    padding: 0
                },
                '& form > .row > .col-xs-12': {
                    marginBottom: 10
                }
            },
            menuItem: {
                ...styles(theme).text4,
                color: theme.palette.blue.main
            }
        }
    )
)

interface AcceptingProps {
    formData: SearchFormModel
}

type OffersHeaderProps = AcceptingProps

const OffersHeader: React.FC<OffersHeaderProps> = (
    {
        formData
    }
) => {
    const classes = useStyles()
    const intl = useIntl()
    const history = useHistory()
    const {isLargeScreen} = useContext(ResponsiveContext)
    const disponibilities: Date[] = useMemo(
        () => (
            loadDates()
        ), []
    )

    const initialValues: SearchFormModel = useMemo(
        () => (
            {
                cities: formData.cities,
                disponibility: formData.disponibility.length > 0 ? formData.disponibility[0].split(',') : [],
                surface: {
                    min: formData.surface.min,
                    max: formData.surface.max
                }
            }
        ), [formData.cities, formData.surface.max, formData.surface.min, formData.disponibility]
    )

    const onSubmit = (values: SearchFormModel) => {
        redirectToWithQuery(history, links.research, `?token=${queryString.parse(history.location.search).token}&cities=${JSON.stringify(values.cities)}&disponibility=${values.disponibility}&surface=${values.surface.min},${values.surface.max}`)
    }

    const validate = (values: SearchFormModel) => {
        const errors: any = {}

        if (values.surface.min > values.surface.max) {
            errors.surface = {
                min: intl.formatMessage(
                    {
                        id: 'offers.research.errors.minGreaterThanMax',
                        defaultMessage: 'Le minimum ne peut pas être supérieur au maximum',
                        description: 'Min / Max error'
                    }
                )
            }
        }

        return errors
    }

    return (
        <div
            className={
                classNames(
                    'row',
                    classes.formStyle,
                    isLargeScreen ? classes.formLargeScreenStyle : classes.formSmallScreenStyle
                )
            }
        >
            <div className="col-xs-12">
                <Form
                    mutators={{
                        ...arrayMutators
                    }}
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmit}
                    render={
                        (
                            {
                                handleSubmit,
                                values,
                                pristine
                            }
                        ) => (
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <SearchBoxCity/>
                                        <OnChange name={CITIES}>
                                            {
                                                (value) => {
                                                    onSubmit(
                                                        {
                                                            ...values,
                                                            cities: value
                                                        }
                                                    )
                                                }
                                            }
                                        </OnChange>
                                    </div>

                                    <div className="col-xs-12 col-md-3">
                                        <Field
                                            name={DISPONIBILITY_NAME}
                                            component={FormSelectField}
                                            id="disponibility"
                                            renderValue={() => RenderValue(DISPONIBILITY_NAME)}
                                            multiple
                                        >
                                            <MenuItem value="">
                                                <span className={classes.menuItem}>
                                                    <FormattedMessage
                                                        id="offers.research.selects.disponibilityPlaceholder"
                                                        defaultMessage="Date de disponibilité"
                                                        description="Placeholder item"
                                                    />
                                                </span>
                                            </MenuItem>

                                            {
                                                disponibilities.map(
                                                    (dateDisponibility) => (
                                                        <MenuItem
                                                            key={dateDisponibility.toLocaleString()}
                                                            value={formatDate(dateDisponibility, localeEnum.fr)}
                                                        >
                                                            <span className={classes.menuItem}>
                                                                {
                                                                    checkSemester(new Date(dateDisponibility), intl)
                                                                }
                                                            </span>
                                                        </MenuItem>
                                                    )
                                                )
                                            }
                                        </Field>
                                        <OnChange name={DISPONIBILITY_NAME}>
                                            {
                                                () => onSubmit(values)
                                            }
                                        </OnChange>
                                    </div>

                                    <div className="col-xs-12 col-md-3">
                                        <Field
                                            name={SURFACE_NAME}
                                            component={SelectSurfaceForm}
                                            id="surface"
                                            min={MIN}
                                            max={MAX}
                                        />
                                        <OnChange name={SURFACE_NAME}>
                                            {
                                                () => {
                                                    if (values.surface.min < values.surface.max && !pristine) {
                                                        onSubmit(values)
                                                    }
                                                }
                                            }
                                        </OnChange>
                                    </div>
                                </div>
                            </form>
                        )
                    }
                />
            </div>
        </div>
    )
}

export default OffersHeader
