import React, { useContext } from 'react'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import classNames from 'classnames'
import { ButtonBase, Theme } from '@material-ui/core'
import IconNumberComponent from './IconNumberComponent'
import { useSelector } from 'react-redux'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { RootState } from '../../../index'
import MakeRequest from '../../contactYF/MakeRequestModal'
import { UserModel } from '../../profile/types/userModel'
import SvgMobile from '../../../icons/Mobile'
import { useTheme } from '@material-ui/core/styles'

const useStyle = makeStyles<Theme, { user: UserModel }>((theme) => ({
		...styles(theme),
		yfDetailsDiv: {
			maxHeight: '80vh',
			overflow: 'auto',
			display: 'flex',
			flexDirection: 'column',
			width: '100%'
		},
		largeHeaderDiv: {
			display: 'flex',
			boxSizing: 'border-box',
			backgroundImage: props => `url('${props.user.picture ? props.user.picture : 'img/yfm_img/defaultYFM.png'}')`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right bottom',
			borderTopLeftRadius: 40,
			borderTopRightRadius: 40,
			minHeight: 306
		},
		smallHeaderDiv: {
			borderTopLeftRadius: 40,
			borderTopRightRadius: 40,
			marginBottom: '6%'
		},
		titleDiv: {
			width: '50%',
			marginTop: '12%',
			marginLeft: '6%',
			textAlign: 'left'
		},
		smallSubTitleDiv: {
			display: 'flex',
			alignItems: 'flex-end',
			marginRight: '10%'
		},
		managerImgBackground: {
			width: 100
		},
		yfManagerButtons: {
			flexDirection: 'row',
			justifyContent: 'center',
			height: 64,
			width: '100%',
			'& button': {
				width: '100%',
				height: '100%'
			}
		},
		blueButton: {
			backgroundColor: theme.palette.blue.main
		},
		greenButton: {
			backgroundColor: theme.palette.accessibility.main
		},
		yfManagerNumberDiv: {
			margin: '50px 35px 60px'
		},
		subTitlePopIn: {
			marginLeft: '1%'
		},
		handleBreakLine: {
			whiteSpace: 'pre-line'
		}
	}
))

interface AcceptingProps {
	isDisplayForm: boolean,
	onDisplayForm: (isDisplayForm: boolean) => void
}

type yfManagerDetailsProps = AcceptingProps

const YFManagerDetails: React.FC<yfManagerDetailsProps> = (
	{
		isDisplayForm,
		onDisplayForm
	}
) => {
	const user = useSelector((state: RootState) => state.user)
	const classes = useStyle({ user })
	const intl: IntlShape = useIntl()
	const theme = useTheme()
	const yfManager = useSelector((state: RootState) => state.user)
	const { isLargeHeightScreen } = useContext(ResponsiveContext)

	const titleCoverClass = classNames(
		classes.colorBlue,
		classes.title2
	)

	const yfTextButton = classNames(
		classes.colorWhite,
		classes.text8,
		classes.handleBreakLine
	)

	const yfManagerNumberDivClass = classNames(
		classes.yfManagerNumberDiv
	)

	const yfManagerButtons = classNames(
		classes.yfManagerButtons
	)

	const subTitleDiv = classNames(
		isLargeHeightScreen ? [classes.largeSubTitleDiv] : [classes.smallSubTitleDiv]
	)

	return (
		<div className={classes.yfDetailsDiv}>
			{
				isDisplayForm ? (
					<MakeRequest onFormFinished={() => onDisplayForm(false)} />
				) : (
					<>
						<div className={classes.largeHeaderDiv}>
							<div className={classes.titleDiv}>
								<div className={subTitleDiv}>
									<span className={titleCoverClass}>
										<FormattedMessage
											id="popinYf.listening"
											defaultMessage="Je suis à votre écoute"
											description="Popin title"
										/>
									</span>
								</div>
							</div>
						</div>
						<div className={yfManagerButtons}>
							<ButtonBase
								className={classes.greenButton}
								onClick={() => onDisplayForm(true)}
							>
			                    <span className={yfTextButton}>
			                       <FormattedMessage
				                       id="popinYf.sendMessage"
			                       />
			                    </span>
							</ButtonBase>
						</div>
						<div className={yfManagerNumberDivClass}>
							<div className="row">
								<div className="col-xs-12">
									<IconNumberComponent
										iconName={<SvgMobile color={theme.palette.blue.main} />}
										title={
											intl.formatMessage({
												id: 'popinYf.phone'
											})
										}
										number={yfManager.phone}
									/>
								</div>
							</div>
						</div>
					</>
				)
			}
		</div>
	)
}

export default YFManagerDetails