import React, { useContext } from 'react'
import { DeckVisit } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import DeckSlider from './DeckSlider'
import { getKeyFromVisitTypeValue } from '../enum/visitTypesEnum'
import Button from '../../../components/button/Button'

const useStyles = makeStyles(
	(theme) => (
		{
			visitContainerStyle: {
				background: theme.palette.blue.main,

				'& > div': {
					margin: 'auto',

					'& h1': {
						margin: 0,
						color: 'white'
					}
				}
			},
			visitContainerLargeScreenStyle: {
				marginTop: 100,
				paddingBottom: 30,

				'& > div': {
					maxWidth: 1100,

					'& h1': {
						marginTop: 115,
						...styles(theme).title1
					}
				}
			},
			visitContainerSmallScreenStyle: {
				marginTop: 50,
				paddingBottom: 15,

				'& > div': {
					'& h1': {
						marginTop: 30,
						...styles(theme).title2
					}
				}
			},
			perspectiveContainerStyle: {
				margin: 'auto',

				'& > div:first-child h1': {
					margin: 0,
					color: theme.palette.blue.main
				}
			},
			perspectiveContainerLargeScreenStyle: {
				maxWidth: 1100,
				marginTop: 50,
				marginBottom: 110,

				'& > div:first-child h1': {
					...styles(theme).title1
				}
			},
			perspectiveContainerSmallScreenStyle: {
				marginTop: 10,
				marginBottom: 50,

				'& > div:first-child h1': {
					...styles(theme).title2
				}
			},
			bluePath: {
				'& .sliderFullScreen': {
					'& path': {
						stroke: theme.palette.blue.main
					}
				}
			},
			iframeStyle: {
				height: '36vw',
				marginBottom: 30
			}
		}
	)
)

interface AcceptingProps {
	visit: DeckVisit
}

type DeckVisitProps = AcceptingProps

const DeckVisitPage: React.FC<DeckVisitProps> = (
	{
		visit
	}
) => {
	const classes = useStyles()
	const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

	const visitContainerClass: ClassValue = classNames(
		classes.visitContainerStyle,
		isLargeScreen ? classes.visitContainerLargeScreenStyle : classes.visitContainerSmallScreenStyle,
		{
			[classes.bluePath]: !isTabletteScreen
		}
	)

	const perspectiveContainerClass: ClassValue = classNames(
		classes.perspectiveContainerStyle,
		isLargeScreen ? classes.perspectiveContainerLargeScreenStyle : classes.perspectiveContainerSmallScreenStyle,
		{
			[classes.bluePath]: !isTabletteScreen
		}
	)

	return (
		<>
			<div className={visitContainerClass}>
				<div className="row">
					<div className="col-xs-12">
						<h1>
							<FormattedMessage
								id={`deck.visit.title.${getKeyFromVisitTypeValue(visit.type)}`}
								defaultMessage="Visite virtuelle"
								description="Visit title"
							/>
						</h1>
					</div>
				</div>

				<div className="row center-xs">
					<div className="col-xs-12">
						<iframe
							className={classes.iframeStyle}
							title="YouTube video"
							width="100%"
							src={visit.url}
						/>
					</div>

					<div className="col-xs-12 col-md-4">
						<Button
							background="backgroundAccessibility"
							color="colorWhite"
							textStyle="text8"
							border="borderAccessibility"
							onClick={() => window.open(visit.url, '_blank')}
						>
							<FormattedMessage
								id="deck.visit.fullScreen"
								defaultMessage="Voir en plein écran"
								description="Fullscreen button"
							/>
						</Button>
					</div>
				</div>
			</div>
			{(visit.perspectives && visit.perspectives.length) &&
			<div className="marginContent">
				<div className={perspectiveContainerClass}>
					<div className="row">
						<div className="col-xs-12">
							<h1>
								<FormattedMessage
									id="deck.visit.perspective"
									defaultMessage="Perspectives"
									description="Perspectives title"
								/>
							</h1>
						</div>
					</div>

					<DeckSlider content={visit.perspectives} />

				</div>
			</div>
			}
		</>
	)
}

export default DeckVisitPage
