import React from 'react'
import MuiLink from '@material-ui/core/Link'
import styles from '../../styles'
import './link.css'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyle = makeStyles(
	(theme) => (
		{
			...styles(theme),
			linkStyle: {
				display: 'flex',
				alignItems: 'center',
				width: 'fit-content'
			}
		}
	)
)

interface ModelLink {
	color: string,
	textStyle: string,
	children: React.ReactNode,
	onClick?: () => void,
	ariaLabel?: string,
	hasChevron?: boolean,
	hasChevronLeft?: boolean,
	className?: ClassValue
}

type LinkProps = ModelLink

const Link: React.FC<LinkProps> = (
	{
		color,
		textStyle,
		children,
		onClick,
		ariaLabel,
		hasChevron = true,
		hasChevronLeft = false,
		className= ''
	}
) => {
	const classes = useStyle()

	const linkClass: ClassValue = classNames(
		classes[color as keyof typeof styles],
		classes[textStyle as keyof typeof styles],
		classes.cursorPointer,
		classes.linkStyle,
		className
	)

	return (
		<MuiLink
			onClick={onClick}
			className={linkClass}
			color="initial"
			underline="none"
			aria-label={ariaLabel}
		>
			{
				hasChevronLeft &&
				<ChevronLeftIcon />
			}

			{children}

			{
				hasChevron &&
				<ChevronRightIcon />
			}
		</MuiLink>
	)
}

export default Link
