import React, { useState } from 'react'
import selectColorEnum from '../../enums/selectEnums/SelectColorEnum'
import { Field, FieldRenderProps } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import FormTextField from './FormTextField'
import FormSlider from './FormSlider'
import { FormattedMessage, useIntl } from 'react-intl'
import SelectField from './SelectField'
import thousandsSeparator from '../../utils/thousandsSeparator'

const useStyles = makeStyles(
    (theme) => (
        {
            surfaceFormContainer: {
                padding: '20px 25px 30px'
            },
            hideElement: {
                display: 'none'
            },
            errorStyle: {
                color: '#B92D00',
                fontSize: 10,
                paddingLeft: 10
            },
            renderedValue: {
                display: 'flex',
                alignItems: 'center',

                '& img': {
                    width: 30,
                    marginRight: 20
                },
                '& span': {
                    marginTop: 10
                }
            }
        }
    )
)

interface AcceptingProps {
    min: number,
    max: number
}

type SelectSurfaceFormProps =
    AcceptingProps
    & FieldRenderProps<{ min: number, max: number }>

const SelectSurfaceForm: React.FC<SelectSurfaceFormProps> = (
    {
        min,
        max,
        input,
        meta: {
            touched,
            error
        }
    }
) => {
    const classes = useStyles()
    const intl = useIntl()
    const [valueMin, setValueMin] = useState<number>(input.value.min)
    const [valueMax, setValueMax] = useState<number>(input.value.max)

    const combineIconValue = (value: JSX.Element) => (
        <div className={classes.renderedValue}>
            <img src="/img/offers/surface.png" alt="Surface icon"/>

            <span>{value}</span>
        </div>
    )

    return (
        <>
            <SelectField
                backgroundColor={selectColorEnum.grey}
                renderValue={
                    () => (
                        <Field
                            name={input.name}
                            render={
                                () => {
                                    if (valueMax === 0) {
                                        return combineIconValue(
                                            <FormattedMessage
                                                id="offers.research.selects.surface"
                                                defaultMessage="Surface"
                                                description="Surface placeholder"
                                            />
                                        )
                                    }
                                    if (valueMin === 0) {
                                        return combineIconValue(
                                            <FormattedMessage
                                                id="offers.research.selects.untilSurface"
                                                defaultMessage="Jusqu' à {max} m²"
                                                description="Until surface txt"
                                                values={
                                                    {
                                                        max: thousandsSeparator(valueMax, true)
                                                    }
                                                }
                                            />
                                        )
                                    }

                                    return combineIconValue(
                                        <FormattedMessage
                                            id="offers.research.selects.concatSurface"
                                            defaultMessage="{min} m² et {max} m²"
                                            description="Concat surface txt"
                                            values={
                                                {
                                                    min: thousandsSeparator(valueMin, true),
                                                    max: thousandsSeparator(valueMax, true)
                                                }
                                            }
                                        />
                                    )
                                }
                            }
                        />
                    )
                }
                onBlur={(value: any) => input.onChange({ min: valueMin, max: valueMax })}
            >
                <div>
                    <div
                        className={classes.surfaceFormContainer}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="row">
                            <div className="col-xs-6">
                                <Field
                                    name={`${input.name}.min`}
                                >
                                    {
                                        ({ input, meta }) => (
                                            <FormTextField
                                                input={
                                                    {
                                                        onChange: (value: any) => setValueMin(Number(value.target.value)),
                                                        onBlur: () => {
                                                        },
                                                        onFocus: () => {
                                                        },
                                                        name: input.name,
                                                        value: valueMin.toString()
                                                    }
                                                }
                                                meta={meta}
                                                id="surface_min"
                                                type="number"
                                                label="Min"
                                                suffix={
                                                    intl.formatMessage(
                                                        {
                                                            id: 'global.unitStandalone',
                                                            defaultMessage: ' m²',
                                                            description: 'Unit'
                                                        }
                                                    )
                                                }
                                            />
                                        )
                                    }
                                </Field>
                            </div>

                            <div className="col-xs-6">
                                <Field
                                    name={`${input.name}.max`}
                                >
                                    {
                                        ({ input, meta }) => (
                                            <FormTextField
                                                input={
                                                    {
                                                        onChange: (value: any) => setValueMax(Number(value.target.value)),
                                                        onBlur: () => {
                                                        },
                                                        onFocus: () => {
                                                        },
                                                        name: input.name,
                                                        value: valueMax.toString()
                                                    }
                                                }
                                                meta={meta}
                                                id="surface_max"
                                                type="number"
                                                label="Max"
                                                suffix={
                                                    intl.formatMessage(
                                                        {
                                                            id: 'global.unitStandalone',
                                                            defaultMessage: ' m²',
                                                            description: 'Unit'
                                                        }
                                                    )
                                                }
                                            />
                                        )
                                    }
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <Field
                                    name={input.name}
                                >
                                    {
                                        ({ input, meta }) => (
                                            <FormSlider
                                                input={
                                                    {
                                                        onChange: (value: { min: number, max: number }) => {
                                                            setValueMin(value.min)
                                                            setValueMax(value.max)
                                                        },
                                                        onBlur: () => {
                                                        },
                                                        onFocus: () => {
                                                        },
                                                        name: input.name,
                                                        value: { min: valueMin, max: valueMax }
                                                    }
                                                }
                                                meta={meta}
                                                id="surface"
                                                min={min}
                                                max={max}
                                            />
                                        )
                                    }
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>
            </SelectField>

            {
                touched && (
                    (typeof error === 'string' || error instanceof String) &&
                    <span className={classes.errorStyle}>{error}</span>
                )
            }
        </>
    )
}

export default SelectSurfaceForm