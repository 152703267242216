import { IntlShape } from 'react-intl'

export const checkSemester = (date: Date | string, intl: IntlShape) => {
	if (new Date(date).toString() !== 'Invalid Date') {
		return intl.formatMessage(
			{
				id: `offers.research.selects.${new Date(date).getMonth() <= 6 ? 'firstSemester' : 'secondSemester'}`,
				defaultMessage: '1er semestre',
				description: 'Date message'
			}, {
				date: new Date(date).getFullYear()
			}
		)
	}

	return intl.formatMessage(
		{
			id: `offers.research.selects.${Number(date.toString().split('/')[1]) <= 6 ? 'firstSemester' : 'secondSemester'}`,
			defaultMessage: '1er semestre',
			description: 'Date message'
		}, {
			date: date.toString().split('/')[2]
		}
	)
}

export const numberSemester = (date: Date | string, intl: IntlShape) => {
	if (new Date(date).toString() !== 'Invalid Date') {
		return intl.formatMessage(
			{
				id: `offers.research.selects.${new Date(date).getMonth() + 1 <= 6 ? 'firstSemester' : 'secondSemester'}`,
				defaultMessage: '1er semestre',
				description: 'Date message'
			}, {
				date: new Date(date).getFullYear()
			}
		).charAt(0)
	}

	return intl.formatMessage(
		{
			id: `offers.research.selects.${new Date(date).getMonth() + 1 <= 6 ? 'firstSemester' : 'secondSemester'}`,
			defaultMessage: '1er semestre',
			description: 'Date message'
		}, {
			date: date.toString().split('/')[2]
		}
	).charAt(0)
}
