export const objectGetValues = (obj: any) => {
	if (Object.values) {
		return Object.values(obj)
	}
	else {
		const values = []

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				values.push(obj[key])
			}
		}

		return values
	}
}

export const objectGetKeys = (obj: any) => {
	if (Object.keys) {
		return Object.keys(obj)
	}
	else {
		const keys = []

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				keys.push(key)
			}
		}

		return keys
	}
}
