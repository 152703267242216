import { OffersType } from '../types/types'
import { LOAD_OFFERS, SAVE_OFFERS } from './actionTypes'
import { OffersReducerModel } from '../types/offersModel'

const initialState: OffersReducerModel = {
	offers: [],
	loaded: false
}

const offers = (
	state: OffersReducerModel = initialState,
	action: OffersType
): OffersReducerModel => {
	switch (action.type) {
		case SAVE_OFFERS: {
			return 'payload' in action ? action.payload : initialState
		}
		case LOAD_OFFERS: {
			return {
				...state,
				loaded: false
			}
		}
		default:
			return state
	}
}

export default offers