import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgStarSelection = (
    {
        color,
        height,
        width,
        className
    }: IconsModel
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 17 17"
        className={className}
    >
        <path
            d="M15.976 6.788a.489.489 0 00-.41-.326l-5.011-.572-2.109-4.446c-.08-.169-.254-.277-.446-.277s-.366.108-.446.277L5.445 5.89l-5.01.572a.485.485 0 00-.41.326.465.465 0 00.133.497l3.708 3.32-.988 4.799a.468.468 0 00.193.479c.155.11.362.12.53.03L8 13.518l4.4 2.395a.511.511 0 00.53-.03.47.47 0 00.192-.48l-.988-4.799 3.708-3.319a.468.468 0 00.134-.497"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)

export default SvgStarSelection