import React from 'react'
import styles from '../../styles'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { ClassValue } from 'classnames/types'
import links from '../../enums/linksEnum'
import { objectGetValues } from '../../utils/ieCompatibility'
import menuEnum, { groupedLinksEnum } from './enum/menuEnum'
import MenuItem from './MenuItem'
import LanguageSelector from '../langContext/LanguageSelector'
import localStorageEnum from '../../enums/localStorageEnum'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'

const useStyles = makeStyles(theme => {
	return {
		...styles(theme),
		lineHeight: {
			height: '100%',
			flexWrap: 'nowrap',

			'& p': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
				height: '100%',
				margin: 0,
				boxSizing: 'border-box'
			}
		},
		menuStyle: {
			marginRight: 40,

			'&:hover': {
				'& span': {
					color: 'white',
					opacity: 1
				}
			}
		}
	}
})

interface AcceptingProps {
	onClick: (link: string) => void,
	linkIsSelected: (linksArray: any, link: string) => string
}

type MenuLargeScreenProps = AcceptingProps

const MenuLargeScreen: React.FC<MenuLargeScreenProps> = (
	props
) => {
	const {
		onClick,
		linkIsSelected
	} = props
	const classes = useStyles()
	const location = useLocation()
	const selections = useSelector((state: RootState) => state.selection)

	const link = location.pathname
	const url_token = localStorage.getItem(localStorageEnum.url_token)

	const linksClass: ClassValue = classNames(
		'row',
		classes.lineHeight
	)
	const linkClass: ClassValue = classNames(
		classes.menuStyle,
		classes.cursorPointer
	)

	return (
		<>
			<div
				className="col-sm-7 col-lg-7 col-xl-6"
			>
				<div
					className={linksClass}
				>
					{
						objectGetValues(menuEnum).map(
							(menu: string) => {
								const groupedLinks = groupedLinksEnum[menu as keyof typeof groupedLinksEnum]

								if (
									(groupedLinks.includes(links.selection) && url_token === process.env.REACT_APP_GENERIC_URL)
									||
									(groupedLinks.includes(links.selection) && selections.selections.visits.concat(selections.selections.selectedOffers).length === 0)
								) {
									return <React.Fragment key={menu}></React.Fragment>
								}

								return (
									<div
										className={linkClass}
										onClick={() => onClick(links[menu as keyof typeof links])}
										key={menu}
									>
										<p
											className={classes.text8}
										>
											<span
												className={linkIsSelected(groupedLinks, link)}
											>
												<MenuItem menu={menu} />
											</span>
										</p>
									</div>
								)
							}
						)
					}
				</div>
			</div>

			<LanguageSelector />
		</>
	)
}

export default MenuLargeScreen
