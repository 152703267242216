import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgLocationFavorite = (
    {
        color,
        height,
        width,
        secondColor = '#122941',
        className
    }: IconsModel
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox="0 0 42 42"
    >
        <defs>
            <filter
                id="locationFavorite_svg__a"
                width="175%"
                height="700%"
                x="-37.5%"
                y="-300%"
                filterUnits="objectBoundingBox"
            >
                <feGaussianBlur in="SourceGraphic" stdDeviation="3 0"/>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <ellipse
                cx={16}
                cy={43.5}
                fill={color}
                filter="url(#locationFavorite_svg__a)"
                opacity={0.25}
                rx={12}
                ry={1.5}
            />
            <path
                fill={color}
                d="M17.527 41.504C27.176 30.104 32 21.603 32 16c0-8.837-7.163-16-16-16S0 7.163 0 16c0 5.603 4.824 14.104 14.473 25.504a2.001 2.001 0 003.054 0z"
            />
            <path
                d="M22.397 14.675a.39.39 0 00-.328-.261l-4.02-.459-1.689-3.565c-.065-.135-.205-.222-.359-.222s-.294.087-.358.222l-1.693 3.565-4.02.459a.392.392 0 00-.328.261.376.376 0 00.107.4l2.974 2.662-.792 3.849c-.03.146.03.296.154.384.125.088.29.097.425.024l3.53-1.92 3.53 1.92a.399.399 0 00.424-.024.376.376 0 00.154-.384l-.792-3.85 2.974-2.662a.374.374 0 00.107-.399"
                fill={secondColor}
            />
        </g>
    </svg>
)

export default SvgLocationFavorite