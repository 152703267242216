import React, { useContext } from 'react'
import YFManagerPopIn from './components/YFManagerPopIn'
import YFMobilePopIn from './components/YFMobilePopIn'
import { ResponsiveContext } from '../../utils/context/responsiveContext'

const YFPopIn = () => {
	const { isTabletteScreen } = useContext(ResponsiveContext)

	return (
		<>
			{
				isTabletteScreen ?
					<YFManagerPopIn />
					:
					<YFMobilePopIn />
			}
		</>

	)
}

export default YFPopIn
