export const loadDates = () => {
	const dates: Date[] = []

	for (let i = 1; i <= 8; i++) {
		const firstDate = new Date(new Date().getFullYear(), 0, 1);

		firstDate.setMonth(firstDate.getMonth() + (i*6))

		if (firstDate >= new Date()) {
			dates.push(new Date(firstDate.getFullYear(), firstDate.getMonth(), 0))
		}
	}

	return dates
}
