enum languages {
	fr = 'fr',
	en = 'en'
}

export enum localeEnum {
	fr = 'fr-FR',
	en = 'en-GB'
}

export default languages
