import React, { useContext, useState } from 'react'
import { MakeRequestContext, request } from './utils/MakeRequestContext'
import Header from './component/Header'
import Stepper from './component/stepper/Stepper'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ResponsiveContext } from '../../utils/context/responsiveContext'

const useStyles = makeStyles(
	(theme) => (
		{
			makeRequestContainer: {
				padding: 40,

				'& form > .row > .col-xs-12, & form > .row > .col-xs-4': {
					padding: 0
				}
			}
		}
	)
)

interface AcceptingProps {
	onFormFinished: () => void
}

type MakeRequestProps = AcceptingProps

const MakeRequest: React.FC<MakeRequestProps> = (
	{
		onFormFinished
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)
	const [step, setStep] = useState<number>(1)

	const finishForm = () => {
		onFormFinished()
	}

	const nextStep = () => {
		setStep(step + 1)
	}

	const resetStep = () => {
		request.firstName = ''
		request.lastName = ''
		request.position = ''
		request.mail = ''

		setStep(1)
	}

	return (
		<div
			className={
				classNames(
					{
						[classes.makeRequestContainer]: isLargeScreen || (!isLargeScreen && step < 3)
					}
				)
			}
		>
			{
				step < 3 && (
					<Header />
				)
			}

			<MakeRequestContext.Provider
				value={{
					request,
					step,
					finishForm,
					nextStep,
					resetStep
				}}
			>
				<Stepper step={step} />
			</MakeRequestContext.Provider>
		</div>
	)
}

export default MakeRequest

