import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import { Backdrop, ButtonBase, Fade, Modal, Paper, Theme } from '@material-ui/core'
import classNames from 'classnames'
import Icon from '../../../components/icon/Icon'
import iconsEnum from '../../../enums/iconsEnum'
import { FormattedMessage, IntlShape, useIntl } from 'react-intl'
import IconNumberComponent from './IconNumberComponent'
import { useDispatch, useSelector } from 'react-redux'
import YFDisplay from './YFDisplay'
import { typeYfDisplay } from '../enum/yfManagerEnums'
import { RootState } from '../../../index'
import { isModalVisible } from '../../../components/dialog/reducer/selector'
import { modalTypes } from '../../../components/dialog/enum/modalTypes'
import { displayModal, hideModal } from '../../../components/dialog/reducer/actions'
import MakeRequest from '../../contactYF/MakeRequestModal'
import { UserModel } from '../../profile/types/userModel'
import SvgMobile from '../../../icons/Mobile'
import { useTheme } from '@material-ui/core/styles'

const useStyle = makeStyles<Theme, { user: UserModel }>((theme) => ({
        ...styles(theme),
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%'
        },
        popInContainer: {
            height: '100%'
        },
        yfTitleDiv: {
            textAlign: 'left',
            height: '6%',
            width: '100%',
            backgroundColor: theme.palette.blue['10'],
            display: 'flex',
            alignItems: 'center'
        },
        spanDiv: {
            verticalAlign: 'middle',
            width: '100%',
            marginLeft: '6%',
            marginTop: 20
        },
        buttonIcon: {
            position: 'fixed',
            zIndex: 1121,
            padding: 8,
            backgroundColor: theme.palette.blue.main,
            bottom: 25,
            right: 15,
            borderRadius: 40,
            fill: 'white'
        },
        managerImg: {
            height: 48,
            width: 48
        },
        paper: {
            width: '100%',
            height: '100%'
        },
        buttonPopIn: {
            width: 48,
            height: 48,
            borderRadius: 32,
            backgroundColor: theme.palette.accessibility.main,
            bottom: '5vw',
            right: '5vw',
            position: 'fixed',
            '&:hover': {
                backgroundColor: theme.palette.blue['50'],
                '& svg': {
                    fill: theme.palette.blue.main
                }
            }
        },
        iconPopIn: {
            fill: 'white'
        },
        titleDiv: {
            width: '50%',
            marginTop: '10%',
            marginLeft: '6%',
            textAlign: 'left'
        },
        subTitleDiv: {
            width: '46%'
        },
        availabilityDiv: {
            marginTop: '10%'
        },
        yfManagerButtons: {
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            minHeight: 40,
            height: '10%',
            '& button': {
                width: '100%',
                height: '100%'
            }
        },
        blueButton: {
            backgroundColor: theme.palette.blue.main
        },
        greenButton: {
            backgroundColor: theme.palette.accessibility.main
        },
        numberDivPopIn: {
            margin: '3% 5%'
        },
        listNumberPopIn: {
            marginTop: '5%'
        },
        coverDiv: {
            backgroundColor: theme.palette.blue['10'],
            height: props => props.user.picture ? '35%' : '25%',
            display: 'flex',
            boxSizing: 'border-box',
            backgroundImage: props => `url('${props.user.picture ? props.user.picture : 'img/yfm_img/defaultYFM.png'}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 190,
            backgroundPosition: 'right bottom'
        },
        handleBreakLine: {
            whiteSpace: 'pre-line'
        }
    }
))

const YFMobilePopIn = () => {
    const user = useSelector((state: RootState) => state.user)
    const classes = useStyle({ user })
    const intl: IntlShape = useIntl()
    const theme = useTheme()
    const isOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.youFirstManager))
    const yfManager = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()

    const [isDisplayForm, setIsDisplayForm] = useState<boolean>(false)

    const titlePopIn = classNames(
        classes.text7,
        classes.colorBlue
    )
    const subTitlePopIn = classNames(
        classes.text2,
        classes.colorBlue
    )
    const titleCoverPopIn = classNames(
        classes.colorBlue,
        classes.title6
    )
    const yfManagerButtons = classNames(
        classes.yfManagerButtons
    )
    const yfTextButton = classNames(
        classes.colorWhite,
        classes.text4,
        classes.handleBreakLine
    )

    const handleOnClickPopIn = () => {
        if (isOpen) {
            dispatch(
                hideModal(modalTypes.youFirstManager)
            )
        } else {
            dispatch(
                displayModal(modalTypes.youFirstManager)
            )
            setIsDisplayForm(false)
        }
    }

    const handleClosePopIn = () => {
        dispatch(
            hideModal(modalTypes.youFirstManager)
        )
    }

    return (
        <>
            <ButtonBase onClick={handleOnClickPopIn} className={classes.buttonIcon}>
                <YFDisplay type={typeYfDisplay.POPIN}/>
            </ButtonBase>

            <Modal
                disableEnforceFocus
                className={classes.modal}
                open={isOpen}
                onClose={handleClosePopIn}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={isOpen}>
                    <Paper className={classes.paper}>
                        <div className={classes.popInContainer}>
                            {
                                isDisplayForm ? (
                                    <>
                                        <MakeRequest onFormFinished={() => setIsDisplayForm(false)}/>

                                        <ButtonBase className={classes.buttonPopIn} onClick={handleClosePopIn}>
                                            <Icon icon={iconsEnum.Cross} size={20} className={classes.iconPopIn}/>
                                        </ButtonBase>
                                    </>
                                ) : (
                                    <>
                                        <div className={classes.yfTitleDiv}>
                                            <div className={classes.spanDiv}>
												<span className={titlePopIn}>
													{yfManager.firstname} {yfManager.lastname}
												</span>

                                                <br/>

                                                <span className={subTitlePopIn}>
													<FormattedMessage
                                                        id="popinYf.subtitle"
                                                        defaultMessage="Votre contact YouFirst"
                                                        description="Popin subtitle"
                                                    />
												</span>
                                            </div>
                                        </div>

                                        <div className={classes.coverDiv}>
                                            <div className={classes.titleDiv}>
                                                <span className={titleCoverPopIn}>
													<FormattedMessage
                                                        id="popinYf.listening"
                                                        defaultMessage="Je suis à votre écoute"
                                                        description="Popin title"
                                                    />
												</span>
                                            </div>
                                        </div>

                                        <div className={yfManagerButtons}>
                                            <ButtonBase
                                                className={classes.greenButton}
                                                onClick={() => setIsDisplayForm(true)}
                                            >
												<span
                                                    className={yfTextButton}
                                                >
												   <FormattedMessage
                                                       id="popinYf.sendMessage"
                                                       defaultMessage="Envoyer un message"
                                                       description="Send message button"
                                                   />
												</span>
                                            </ButtonBase>
                                        </div>

                                        <div className={classes.numberDivPopIn}>
                                            <div className={classes.listNumberPopIn}>
                                                <IconNumberComponent
                                                    iconName={<SvgMobile color={theme.palette.blue.main}/>}
                                                    title={
                                                        intl.formatMessage({
                                                            id: 'popinYf.phone',
                                                            defaultMessage: 'Téléphone',
                                                            description: 'Phone label'
                                                        })
                                                    }
                                                    number={yfManager.phone}
                                                />
                                            </div>

                                            <ButtonBase className={classes.buttonPopIn} onClick={handleClosePopIn}>
                                                <Icon icon={iconsEnum.Cross} size={20} className={classes.iconPopIn}/>
                                            </ButtonBase>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}

export default YFMobilePopIn