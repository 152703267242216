import React, { useContext, useState } from 'react'
import styles from '../../styles'
import { makeStyles } from '@material-ui/core/styles'
import MediaQuery from 'react-responsive'
import EnhancedMenuLargeScreen from './MenuLargeScreen'
import './styles.css'
import { useHistory } from 'react-router-dom'
import EnhancedMenuBurger from './MenuBurger'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { FormattedMessage } from 'react-intl'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import links from '../../enums/linksEnum'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'
import queryString from 'query-string'

const useStyles = makeStyles(theme => ({
	...styles(theme),
	menuStyle: {
		width: '100%',
		display: 'flex',
		zIndex: 1101,
		position: 'fixed',

		'& #logo': {
			cursor: 'pointer'
		}
	},
	menuLargeStyle: {
		height: 100,

		'& #logo': {
			margin: '30px 30px 0',
			width: 150
		}
	},
	menuSmallStyle: {
		height: 65,
		borderBottom: `1px solid ${theme.palette.blue['10']}`,
		justifyContent: 'space-between',

		'& #logo': {
			margin: '15px 15px 0',
			width: 120
		}
	},
	menuSmallScreenStyle: {
		display: 'flex',
		alignItems: 'center',
		paddingRight: 0,

		'& div': {
			width: 150,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
			paddingLeft: 30,
			borderLeft: `1px solid ${theme.palette.blue['10']}`,
			cursor: 'pointer'
		},
		'& svg': {
			fontSize: 30,
			marginLeft: 12,
			marginRight: 24
		}
	},
	selected: {
		color: 'white',

		'&::after': {
			bottom: '0',
			width: '100%',
			height: 3,
			content: '\' \'',
			display: 'inline-block',
			background: theme.palette.green.main,
			position: 'absolute',
			right: 0
		}
	},
	opacityItem: {
		opacity: 0.6
	}
}))

const Menu = () => {
	const classes = useStyles()
	const history = useHistory()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const [open, setOpen] = useState<boolean>(false)

	const menuClass: ClassValue = classNames(
		{
			[classes.menuLargeStyle]: isLargeScreen,
			[classes.menuSmallStyle]: !isLargeScreen
		},
		classes.backgroundBlue,
		classes.colorWhite,
		classes.menuStyle
	)

	const menuSmallScreenClass: ClassValue = classNames(
		'col-xs-6 end-xs',
		classes.menuSmallScreenStyle,
		classes.text3
	)

	const redirectTo = (link: string) => {
		setOpen(false)
		redirectToWithQuery(history, link, `?token=${queryString.parse(history.location.search).token}`)
	}

	const linkIsSelected = (linksArray: string[], link: string) => {
		return linksArray.includes(link) ?
			classNames(
				{
					[classes.selected]: isLargeScreen,
					[classes.colorWhite]: !isLargeScreen
				}
			) : classes.opacityItem
	}

	return (
		<>
			<div
				className={menuClass}
			>
				<div className="col-xs-5 col-md-3 col-lg-2">
					<img
						src="/img/youFirstGecina.png"
						alt="Logo Youfirst"
						id="logo"
						onClick={() => redirectTo(links.homepage)}
					/>
				</div>

				<MediaQuery
					maxWidth={1024}
				>
					{
						(match) => {
							return match ? (
								<>
									<div className={menuSmallScreenClass}>
										<div onClick={() => setOpen(!open)}>
											{
												open &&
												<>
													<span>
														<FormattedMessage
															id="menu.close"
															defaultMessage="Fermer"
															description="Close menu"
														/>
													</span>
													<CloseIcon />
												</>
											}
											{
												!open &&
												<>
													<span>Menu</span>
													<MenuIcon />
												</>
											}
										</div>
									</div>

								</>
							) : (
								<>
									<EnhancedMenuLargeScreen
										onClick={redirectTo}
										linkIsSelected={linkIsSelected}
									/>
								</>
							)
						}
					}
				</MediaQuery>
			</div>

			{
				!isLargeScreen &&
				<EnhancedMenuBurger
					onClick={redirectTo}
					open={open}
					linkIsSelected={linkIsSelected}
				/>
			}
		</>
	)
}

export default Menu
