import fetchFactory from '../../../utils/fetchFactory'
import { PostMakeRequestModel } from '../types/makeRequestModel'
import { configEnum } from '../../../enums/fetchFactoryEnum'

export const sendMessage = (yfManager: string, data: PostMakeRequestModel) => {
	return fetchFactory('/cms/api/mail',
		{
			method: configEnum.post,
			body: JSON.stringify(data)
		}
	)
}
