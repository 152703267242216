import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useStyles } from './style'
import { useHistory, useRouteMatch } from 'react-router'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import errorCatcher from '../../utils/errorCatcher'
import Loader from '../../components/loader/Loader'
import Link from '../../components/link/Link'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import parse from 'html-react-parser'
import { PageModel } from './types/footerModel'
import { RootState } from '../../index'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { displaySnackbar } from '../../components/snackBar/reducer/actions'
import { saveFooter } from './reducer/actions'
import { getFooter } from './api/getFooter'

const COMPONENT_NAME = 'details_footer'
const INITIAL_DETAIL: PageModel = {
	id: '',
	content: '',
	title: ''
}

interface MatchParamsModel {
	id: string
}

interface AcceptingProps {
	content?: PageModel
}

type DetailFooterPageProps = AcceptingProps

const DetailFooterPage: React.FC<DetailFooterPageProps> = (
	{
		content
	}
) => {
	const classes = useStyles()
	const history = useHistory()
	const match = useRouteMatch<MatchParamsModel>()
	const intl = useIntl()
	const footer = useSelector((state: RootState) => state.footer)
	const dispatch = useDispatch()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const [selectedFooter, setSelectedFooter] = useState<PageModel>(content || INITIAL_DETAIL)
	const oldLanguage = useRef(intl.locale)
	const [loading, setLoading] = useState(false)
	const errorMessage = intl.formatMessage(
		{
			id: 'footer.details.error',
			defaultMessage: 'La page demandée n\'a pas été trouvée',
			description: 'Error message'
		}
	)

	const detailContainerClass: ClassValue = classNames(
		classes.detailsContainerStyle,
		isLargeScreen ? classes.detailsContainerLargeScreenStyle : classes.detailsContainerSmallScreenStyle
	)

	const textContainerClass: ClassValue = classNames(
		classes.textContainerStyle,
		{
			[classes.textContainerLargeScreenStyle]: isLargeScreen,
			[classes.textContainerSmallScreenStyle]: !isLargeScreen
		}
	)

	const titleClass: ClassValue = classNames(
		classes.title1,
		classes.colorBlue,
		classes.titleStyle
	)

	const checkId = useCallback(
		(footerParam: PageModel[], idInUrl: string) => {
			const footer = footerParam.find(
				(footer) => footer.id === idInUrl
			)

			if (!!footer) {
				setSelectedFooter(footer)
			} else {
				dispatch(
					displaySnackbar(
						{
							id: COMPONENT_NAME,
							message: errorMessage,
							open: true,
							hideIcon: true
						}
					)
				)

				history.goBack()
			}
		}, [dispatch, errorMessage, history]
	)

	useEffect(
		() => {
			setLoading(true)
			const idInUrl = match.params.id
			window.scrollTo(0, 0)

			if ((footer.length === 0 || oldLanguage.current !== intl.locale) && !content) {
				getFooter(intl.locale)
					.then(
						(footerResponse: any) => {
							dispatch(
								saveFooter(footerResponse)
							)

							oldLanguage.current = intl.locale
							checkId(footerResponse, idInUrl)
						}
					)
					.catch(
						(err) => {
							dispatch(
								errorCatcher(err, COMPONENT_NAME)
							)
						}
					)
					.finally(
						() => {
							setLoading(false)
						}
					)
			} else {
				checkId(footer, idInUrl)
				setLoading(false)
			}
		}, [footer, checkId, dispatch, intl.locale, match.params.id, content]
	)

	const back = () => {
		history.goBack()
	}

	if (loading) {
		return (
			<Loader />
		)
	}

	return (
		<div className={detailContainerClass}>
			<div className={textContainerClass}>
				<div className="link">
					{
						!content && (
							<Link
								color="colorAccessibility"
								textStyle="text4"
								hasChevron={false}
								onClick={back}
							>
								<KeyboardArrowLeftIcon />

								<FormattedMessage
									id="app.back"
									defaultMessage="Retour"
									description="Back button"
								/>
							</Link>
						)
					}

					<h1 className={titleClass}>{selectedFooter.title}</h1>

					<span>
						{parse(selectedFooter.content || '')}
					</span>
				</div>
			</div>
		</div>
	)
}

export default DetailFooterPage
