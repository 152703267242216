import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgLocation = (
    {
        color,
        height,
        width,
        secondColor = '#122941',
        className,
        borderColor
    }: IconsModel
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox="-2 0 27 27"
    >
        <defs>
            <filter
                id="location_svg__a"
                width="200%"
                height="894.1%"
                x="-50%"
                y="-397.1%"
                filterUnits="objectBoundingBox"
            >
                <feGaussianBlur in="SourceGraphic" stdDeviation="3 0"/>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                fill={color}
                stroke={borderColor} strokeWidth="2"
                d="M13.537 30.89C20.512 22.512 24 16.245 24 12.089 24 5.412 18.627 0 12 0S0 5.412 0 12.089c0 4.156 3.488 10.423 10.463 18.8a2 2 0 003.074.001z"
            />
            <ellipse cx={12} cy={11.711} fill={secondColor} rx={3.75} ry={3.778}/>
        </g>
    </svg>
)

export default SvgLocation