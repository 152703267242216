import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'
import Loader from '../../components/loader/Loader'
import OffersDisplay from '../../components/offersDisplay/OffersDisplay'
import OffersComponent from './components/OffersComponent'
import OffersProvider from './utils/context/offersContext'
import { selectionStatusEnum } from '../selection/enum/selectionEnum'
import { natureIdEnum } from '../../enums/natureEnum'

const Offers = () => {
    const offers = useSelector((state: RootState) => state.offers)
    const offersFiltered = useMemo(
        () => offers.offers.filter((offer) => offer.status === selectionStatusEnum.available && offer.offer_nature !== natureIdEnum.retail), [offers]
    )

    if (!offers.loaded) {
        return (
            <Loader/>
        )
    }

    return (
        <>
            <OffersProvider>
                <OffersDisplay
                    selections={offersFiltered}
                    offers={<OffersComponent />}
                />
            </OffersProvider>
        </>
    )
}

export default Offers