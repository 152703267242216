import React from 'react'
import MuiButton from '@material-ui/core/Button'
import styles from '../../styles'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'

const useStyles = makeStyles((theme) => ({
	...styles(theme),
	buttonStyle: {
		borderRadius: 0,
		textTransform: 'inherit'
	},
	bigButton: {
		width: '100%',
		minHeight: 64,
		padding: '23px 40px'
	},
	mediumButton: {
		width: '100%',
		minHeight: 48,
		padding: '15px 24px'
	},
	smallButton: {
		width: '100%',
		minHeight: 32,
		padding: 10
	}
}))

interface ModelButton {
	background: string,
	color: string,
	textStyle: string,
	children: React.ReactNode,
	onClick?: () => void,
	disabled?: boolean,
	size?: 'smallButton' | 'mediumButton' | 'bigButton',
	type?: 'submit' | 'reset' | 'button',
	border?: string | null,
	ariaLabel?: string
}

type ButtonProps = ModelButton

const Button: React.FC<ButtonProps> = (
	{
		background,
		color,
		textStyle,
		children,
		onClick,
		disabled = false,
		size = 'mediumButton',
		type = 'submit',
		border,
		ariaLabel
	}
) => {
	const classes = useStyles()

	const btnClass: ClassValue = classNames(
		classes[background as keyof typeof styles],
		classes[color as keyof typeof styles],
		classes[textStyle as keyof typeof styles],
		{
			[classes.bigButton]: size === 'bigButton',
			[classes.mediumButton]: size === 'mediumButton',
			[classes[border as keyof typeof styles]]: border
		},
		classes.buttonStyle
	)

	return (
		<MuiButton
			onClick={onClick}
			className={btnClass}
			disabled={disabled}
			type={type}
			aria-label={ariaLabel}
		>
			{children}
		</MuiButton>
	)
}

export default Button
