import React, { useContext, useRef } from 'react'
import { SelectionModel } from '../../../modules/selection/types/selectionModel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import StarSelection from '../../../modules/selection/components/offers/StarSelection'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import OfferDetails from '../../offerDetails/OfferDetails'
import { OfferDetailsModel } from '../../offerDetails/types/offerDetailsModel'
import { fromComponentEnum } from '../../offerDetails/enum/fromComponentEnum'
import localStorageEnum from '../../../enums/localStorageEnum'
import { FormattedMessage } from 'react-intl'
import { selectionStatusEnum } from '../../../modules/selection/enum/selectionEnum'

const useStyles = makeStyles(
    (theme) => (
        {
            cardContainerStyle: {
                position: 'relative',
                marginBottom: 32
            },
            imgContainerStyle: {
                position: 'relative',
                backgroundSize: 'cover',
                cursor: 'pointer'
            },
            imgContainerLargeScreenStyle: {
                height: '14.3vw'
            },
            imgContainerTabletteScreenStyle: {
                height: '30.3vw'
            },
            imgContainerSmallScreenStyle: {
                height: '59.5vw'
            },
            detailsStyle: {
                '& h1': {
                    color: theme.palette.blue.main,
                    ...styles(theme).title3,
                    margin: 0,
                    cursor: 'pointer'
                },
                '& p': {
                    color: theme.palette.blue.main,
                    ...styles(theme).text3,
                    margin: 0
                }
            },
            detailsLargeScreenStyle: {
                marginTop: 15,
                marginBottom: 24
            },
            detailsSmallScreenStyle: {
                marginTop: 15,
                marginBottom: 15
            },
            disabled: {
                width: '100%',
                position: 'absolute',

                '& h2': {
                    ...styles(theme).title11,
                    color: 'white',
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    margin: 0,
                    width: '100%',
                    textAlign: 'center',
                    background: theme.palette.blue.main,
                    paddingTop: 10,
                    paddingBottom: 10
                }
            },
            disabledLargeScreenStyle: {
                top: '7.15vw'
            },
            disabledTabletteScreenStyle: {
                top: '15.15vw'
            },
            disabledSmallScreenStyle: {
                top: '29.75vw'
            },
            detailsDisabled: {
                '& h1, & p, & span': {
                    opacity: 0.5,
                    cursor: 'inherit'
                }
            },
            disabledImage: {
                filter: 'grayscale(100)',
                cursor: 'default',

                '& .disabledFilter': {
                    width: '100%',
                    height: '100%',
                    background: theme.palette.blue.main,
                    opacity: 0.5
                }
            }
        }
    )
)

interface AcceptingProps {
    item: SelectionModel & OfferDetailsModel,
    selectItem: () => void,
    seeDetails: (id: string) => void
}

type SelectionCardProps = AcceptingProps

const SelectionCard: React.FC<SelectionCardProps> = (
    {
        item,
        selectItem,
        seeDetails
    }
) => {
    const classes = useStyles()
    const cardRef = useRef<HTMLDivElement>(document.createElement('div'))
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)
    const url_token = localStorage.getItem(localStorageEnum.url_token)

    const imgContainerClass: ClassValue = classNames(
        classes.imgContainerStyle,
        {
            [classes.imgContainerLargeScreenStyle]: isLargeScreen,
            [classes.imgContainerTabletteScreenStyle]: isTabletteScreen && !isLargeScreen,
            [classes.imgContainerSmallScreenStyle]: !isTabletteScreen && !isLargeScreen,
            [classes.disabledImage]: (item.status !== selectionStatusEnum.available || item.availableArea === '0')
        }
    )

    const detailsClass: ClassValue = classNames(
        classes.detailsStyle,
        isLargeScreen ? classes.detailsLargeScreenStyle : classes.detailsSmallScreenStyle
    )

    return (
        <div ref={cardRef} className={classes.cardContainerStyle}>
            <div
                className={imgContainerClass}
                onClick={() => item.status === selectionStatusEnum.available && item.availableArea > '0' ? seeDetails(item.id) : undefined}
                style={
                    {
                        backgroundImage: `url("${item.picture}")`
                    }
                }
            >
                {
                    url_token !== process.env.REACT_APP_GENERIC_URL && (
                        <StarSelection
                            item={item}
                            selectItem={selectItem}
                        />
                    )
                }
                {
                    (item.status !== selectionStatusEnum.available || item.availableArea === '0') && (
                        <div className="disabledFilter"></div>
                    )
                }
            </div>

            {
                (item.status !== selectionStatusEnum.available || item.availableArea === '0') && (
                    <div
                        className={
                            classNames(
                                classes.disabled,
                                {
                                    [classes.disabledLargeScreenStyle]: isLargeScreen,
                                    [classes.disabledTabletteScreenStyle]: isTabletteScreen && !isLargeScreen,
                                    [classes.disabledSmallScreenStyle]: !isTabletteScreen && !isLargeScreen,
                                }
                            )
                        }
                    >
                        <h2>
                            <FormattedMessage
                                id="selection.rent"
                                defaultMessage="Déjà loué"
                                description="Already rent text"
                            />
                        </h2>
                    </div>
                )
            }

            <div
                className={
                    classNames(
                        {
                            [classes.detailsDisabled]: (item.status !== selectionStatusEnum.available || item.availableArea === '0')
                        }
                    )
                }
            >
                <div className={detailsClass}>
                    <h1 onClick={() => item.status === selectionStatusEnum.available && item.availableArea > '0' ? seeDetails(item.id) : undefined}>{item.name}</h1>
                    <p>{item.address}, </p>
                    <p>{item.postalCode} {item.city}</p>
                </div>

                <OfferDetails
                    item={
                        {
                            availableArea: item.availableArea,
                            divisibleFrom: item.divisibleFrom,
                            indicativeAvailability: item.indicativeAvailability
                        }
                    }
                    from={fromComponentEnum.selection}
                />
            </div>
        </div>
    )
}

export default SelectionCard