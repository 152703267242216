import { UserModel, UserResponseModel } from '../types/userModel'

export const convertUser = (user: UserResponseModel): UserModel => (
	{
		id: user.content.id,
		id_contact_yf: user.content.id_contact_yf,
		firstname: user.content.prenom_contact_yf,
		lastname: user.content.nom_contact_yf,
		email: user.content.email_contact_yf,
		phone: user.content.telephone_contact_yf,
		homepage_message_fr: user.content.message_accueil,
		homepage_message_en: user.content.message_accueil_en,
		picture: user.contact_commercial.photo ? user.contact_commercial.photo : null,
		thumbnail: user.contact_commercial.vignette ? user.contact_commercial.vignette : null,
		captcha: user.contact_commercial.captcha,
		loaded: true
	}
)