import React, { useContext, useEffect, useState } from 'react'
import { DeckPresentation, MetaModel } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import SvgLocation from '../../../icons/Location'
import { fromComponentEnum } from '../../../components/offerDetails/enum/fromComponentEnum'
import OfferDetails from '../../../components/offerDetails/OfferDetails'
import { OfferDetailsModel } from '../../../components/offerDetails/types/offerDetailsModel'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import styles from '../../../styles'
import { FormattedMessage, useIntl } from 'react-intl'
import parse from 'html-react-parser'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { getCustomDoc } from '../api/getCustomDoc'
import { useRouteMatch } from 'react-router'
import { CustomDocDownloadModel, CustomDocModel } from '../types/customDocModel'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import SvgAttachment from '../../../icons/Attachment'
import { downloadCustomDoc } from '../api/downloadCustomDoc'
import errorCatcher from '../../../utils/errorCatcher'
import Loader from '../../../components/loader/Loader'
import { useMediaQuery } from 'react-responsive'
import { PrintDeckModel } from '../types/printDeckModel'
import { fetchPrintDeck } from '../api/fetchPrintDeck'
import { formatFilename } from '../../../utils/formatter/formatFilename'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			presentationContainer: {
				margin: 0,
				textAlign: 'center',

				'& div': {
					position: 'relative',
					margin: 'auto'
				},
				'& .presentation_title': {
					'& h1': {
						margin: 0,
						marginBottom: 13,
						color: 'white'
					},
					'& h2': {
						margin: 0,
						marginBottom: 33,
						color: theme.palette.green.main
					},
					'& p': {
						margin: 0
					},
					'& p:last-child': {
						marginTop: 24,
						color: 'white',
						...styles(theme).text20
					}
				},
				'& .presentation_customMessage': {
					textAlign: 'left',

					'& > div': {
						backgroundColor: theme.palette.blue['5'],
						color: theme.palette.blue.main,
						...styles(theme).text20
					}
				},
				'& .presentation_pictures': {
					position: 'relative',
					textAlign: 'left',

					'& .single': {
						margin: '0 auto',

						'& img': {
							maxWidth: '100%'
						}
					},
					'& .multiple': {
						'& .imgContainer:first-child': {
							maxWidth: '45%',
							margin: 'initial',
							verticalAlign: 'bottom',

							'& img': {
								maxWidth: '100%'
							}
						},
						'& .imgContainer:last-child': {
							position: 'absolute',
							maxWidth: '60%',
							left: '40%',
							bottom: -15,

							'& img': {
								maxWidth: '100%'
							}
						}
					}
				},
				'& .presentation_text': {
					textAlign: 'left',

					'& h1, & p': {
						margin: 0,
						color: theme.palette.blue.main,
						lineHeight: 1.5
					}
				}
			},
			presentationContainerLargeScreenStyle: {
				'& div': {
					maxWidth: 920
				},
				'& .presentation_title': {
					margin: '104px auto 84px',

					'& h1': {
						...styles(theme).title0
					},
					'& h2': {
						...styles(theme).title9
					}
				},
				'& .presentation_customMessage': {
					marginTop: 57,

					'& > div': {
						padding: '55px 95px'
					}
				},
				'& .presentation_pictures': {
					marginTop: 60
				},
				'& .presentation_text': {
					maxWidth: 1100,
					marginTop: 75,
					marginBottom: 50,

					'& h1': {
						marginBottom: 50,
						...styles(theme).title1
					}
				}
			},
			presentationContainerSmallScreenStyle: {
				'& .presentation_title': {
					margin: '15px auto 50px',

					'& h1': {
						...styles(theme).title2
					},
					'& h2': {
						...styles(theme).text12
					}
				},
				'& .presentation_customMessage': {
					marginTop: 25,

					'& > div': {
						padding: '25px 50px'
					}
				},
				'& .presentation_pictures': {
					marginTop: 35
				},
				'& .presentation_text': {
					width: '90%',
					margin: '55px auto 35px',

					'& h1': {
						marginBottom: 25,
						...styles(theme).title2
					}
				}
			},
			fileListStyle: {
				padding: '12px 12px 12px 16px',
				background: 'white',

				'& svg': {
					marginLeft: 'auto'
				}
			},
			fileListLargeScreenStyle: {
				marginTop: '25px !important'
			},
			fileListSmallScreenStyle: {
				marginTop: '15px !important'
			},
			alignItems: {
				verticalAlign: 'middle',

				'& svg': {
					verticalAlign: 'middle'
				}
			},
			alignItemsLargeScreen: {
				'&:first-child': {
					marginRight: 20,
					marginLeft: 0
				}
			},
			alignItemsSmallScreen: {
				'&:first-child': {
					marginRight: 10,
					marginLeft: 0
				}
			},
			printStyle: {
				position: 'absolute !important' as 'absolute',
				background: theme.palette.accessibility.main,
				cursor: 'pointer'
			},
			printLargeScreenStyle: {
				padding: '20px 40px',
				minHeight: 44,
				right: -107,
				top: 0
			},
			printTabletteScreenStyle: {
				padding: '20px 40px',
				minHeight: 44,
				right: 8,
				top: -84
			},
			printSmallScreenStyle: {
				padding: '10px 15px',
				right: 8,
				top: -39
			}
		}
	)
)

interface MatchParamsModel {
	id: string
}

interface CustomFileModel {
	fileName: string,
	url: string
}

interface AcceptingProps {
	presentation: DeckPresentation & OfferDetailsModel,
	meta: MetaModel
}

const GET_DOC_ERROR = 'get_doc_error'
const DOWNLOAD_ERROR = 'download_error'
const PRINT_ERROR = 'print_error'

type DeckPresentationProps = AcceptingProps

const DeckPresentationPage: React.FC<DeckPresentationProps> = (
	{
		presentation,
		meta
	}
) => {
	const printIconLargeScreenBreakpoint = useMediaQuery(
		{
			query: '(min-width: 1170px)'
		}
	)

	const classes = useStyles()
	const intl = useIntl()
	const user = useSelector((state: RootState) => state.user)
	const dispatch = useDispatch()
	const match = useRouteMatch<MatchParamsModel>()
	const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

	const [files, setFiles] = useState<CustomFileModel[]>([])
	const [downloadingFiles, setDownloadingFiles] = useState<string[]>([])
	const [isPrintingDeck, setIsPrintingDeck] = useState<boolean>(false)

	const presentationClass: ClassValue = classNames(
		classes.presentationContainer,
		isLargeScreen ? classes.presentationContainerLargeScreenStyle : classes.presentationContainerSmallScreenStyle
	)

	const fileListClass: ClassValue = classNames(
		classes.fileListStyle,
		classes.flex,
		classes.alignCenter,
		classes.cursorPointer,
		isLargeScreen ? classes.fileListLargeScreenStyle : classes.fileListSmallScreenStyle
	)

	const fileNameClass: ClassValue = classNames(
		classes.colorBlue,
		classes.alignItems,
		{
			[classes.alignItemsLargeScreen]: isLargeScreen,
			[classes.text2]: isLargeScreen,
			[classes.alignItemsSmallScreen]: !isLargeScreen,
			[classes.text3]: !isLargeScreen
		}
	)

	const fileIconClass: ClassValue = classNames(
		classes.colorGreen,
		classes.alignItems,
		classes.cursorPointer,
		isLargeScreen ? classes.alignItemsLargeScreen : classes.alignItemsSmallScreen
	)

	useEffect(
		() => {
			const offerId = match.params.id
			const data: CustomDocModel = {
				id_offre: offerId,
				id_demande: user.id
			}

			getCustomDoc(data)
				.then(
					(response: any) => {
						setFiles(response)
					}
				)
				.catch(
					(error) => dispatch(
						errorCatcher(error, GET_DOC_ERROR)
					)
				)
		}, [dispatch, match.params.id, user.id]
	)

	const downloadFile = (file: CustomFileModel) => {
		const data: CustomDocDownloadModel = {
			fileName: file.fileName,
			fileURL: file.url
		}

		setDownloadingFiles(
			(downloadingFiles) => (
				[
					...downloadingFiles,
					file.fileName
				]
			)
		)

		downloadCustomDoc(data)
			.then(
				(response: any) => {
					const url = URL.createObjectURL(response)
					const a = document.createElement('a')
					a.href = url || ''
					a.target = '_blank'
					a.download = file.fileName
					a.click()
				}
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(error, DOWNLOAD_ERROR)
					)
				}
			)
			.finally(
				() => setDownloadingFiles(
					(downloadingFiles) => {
						const allDownloadingFiles = [...downloadingFiles]

						const index = downloadingFiles.findIndex((downloadingFile) => downloadingFile === file.fileName)
						if (index >= 0) {
							allDownloadingFiles.splice(index, 1)
						}

						return allDownloadingFiles
					}
				)
			)
	}

	const printDeck = () => {
		setIsPrintingDeck(true)

		const data: PrintDeckModel = {
			deck: meta.id
		}

		fetchPrintDeck(data)
			.then(
				(response: any) => {
					const url = URL.createObjectURL(response)
					const a = document.createElement('a')
					a.href = url || ''
					a.target = '_blank'
					a.download = `YouFirst_${formatFilename(presentation.name, '-')}`
					a.click()
				}
			)
			.catch(
				(error) => {
					dispatch(
						errorCatcher(
							intl.formatMessage(
								{
									id: 'deck.presentation.printError',
									defaultMessage: 'Erreur lors de l\'impression du deck',
									description: 'Deck error message'
								}
							), PRINT_ERROR)
					)
				}
			)
			.finally(() => setIsPrintingDeck(false))
	}

	return (
		<div className={presentationClass}>
			<div className="row">
				<div className="presentation_title col-xs-12">
					<h1>{presentation.name}</h1>

					<h2>{presentation.baseline || ' '}</h2>

					<p><SvgLocation color="white" height={34} width={24} /></p>
					<p>{presentation.address}, {presentation.postalCode} {presentation.city}</p>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12">
					<OfferDetails
						item={
							{
								availableArea: presentation.availableArea,
								divisibleFrom: presentation.divisibleFrom,
								indicativeAvailability: presentation.indicativeAvailability,
								nature: presentation.nature
							}
						}
						from={fromComponentEnum.deck}
					/>

					<div
						onClick={printDeck}
						className={
							classNames(
								classes.printStyle,
								{
									[classes.printLargeScreenStyle]: printIconLargeScreenBreakpoint,
									[classes.printTabletteScreenStyle]: isTabletteScreen && !printIconLargeScreenBreakpoint,
									[classes.printSmallScreenStyle]: !isTabletteScreen
								}
							)
						}
					>
						{
							isPrintingDeck ? (
								<Loader
									size={isTabletteScreen ? 35 : 15}
									color="white"
								/>
							) : (
								<img src="/img/deck/print.svg" alt="Print deck" width={isTabletteScreen ? 35 : 15} height={isTabletteScreen ? 40 : 15} />
							)
						}
					</div>
				</div>
			</div>

			{
				presentation.customMessage && (
					<div className="row">
						<div className="presentation_customMessage col-xs-12">
							<div>
								<div>
									{presentation.customMessage}
								</div>

								{
									files.map(
										(file) => (
											<div
												key={file.fileName}
												className={fileListClass}
												onClick={() => downloadFile(file)}
											>
												{
													downloadingFiles.findIndex((downloadingFile) => downloadingFile === file.fileName) >= 0 ? (
														<Loader size={50} />
													) : (
														<>
															<div className={fileIconClass}>
																<SvgAttachment
																	color="#122941"
																	width={isLargeScreen ? 25 : 17}
																	height={isLargeScreen ? 25 : 15}
																/>
															</div>

															<span className={fileNameClass}>
                                                            {file.fileName}
                                                        </span>
														</>
													)
												}
											</div>
										)
									)
								}
							</div>
						</div>
					</div>
				)
			}

			<div className="row">
				<div className="presentation_pictures col-xs-12">
					{
						presentation.pictures.length === 1 ? (
							<div className="single">
								<div className="imgContainer">
									<img src={presentation.pictures[0]} alt="Presentation pictures" />
									{
										presentation.captions[0] && (
											<span>{presentation.captions[0]}</span>
										)
									}
								</div>
							</div>
						) : (
							<div className="multiple">
								{
									presentation.pictures.map(
										(picture, index) => (
											<div
												key={picture}
												className="imgContainer"
											>
												<img
													src={picture}
													alt="Presentation pictures"
												/>

												{
													presentation.captions[index] && (
														<span>{presentation.captions[index]}</span>
													)
												}
											</div>
										)
									)
								}
							</div>
						)
					}
				</div>
			</div>

			<div className="row">
				<div className="presentation_text col-xs-12">
					<h1>
						<FormattedMessage
							id="deck.presentation.title"
							defaultMessage="Présentation"
							description="Presentation title hook"
						/>
					</h1>

					<p>{parse(presentation.text)}</p>
				</div>
			</div>
		</div>
	)
}

export default DeckPresentationPage