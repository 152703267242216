export const isEmailOk = (value: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)

/**
 * Validation du mot de passe:
 * - Au moins 8 caractères
 * - Au moins 1 chiffre
 * - Au moins 1 lettre minuscule
 * - Au moins 1 lettre majuscule
 * @param value
 */
export const isPasswordOk = (value: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value)

/**
 * Validation du numéro de téléphone; TEMPORAIRE, EN ATTENDANT LA REGLE DE GESTION
 *  - Pas plus de 20 caractères
 *
 */
export const isPhoneOk = (value: string) => /^.{0,20}$/.test(value)