import { OffersType } from '../types/types'
import { LOAD_OFFERS, SAVE_OFFERS } from './actionTypes'
import { OffersReducerModel } from '../types/offersModel'

export const saveOffers = (offers: OffersReducerModel): OffersType => (
    {
        type: SAVE_OFFERS,
        payload: offers
    }
)

export const loadOffers = (): OffersType => (
    {
        type: LOAD_OFFERS
    }
)