import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgMobile = (
    {
        color,
        width = 55,
        height = 55
    }: IconsModel
) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 52 52">
        <path
            d="M35.731 1.123a3.854 3.854 0 013.85 3.85V47.15a3.854 3.854 0 01-3.85 3.85h-21.82a3.854 3.854 0 01-3.851-3.85V4.974a3.854 3.854 0 013.85-3.85zm2.385 40.525h-26.59v5.502a2.387 2.387 0 002.384 2.384h21.821a2.388 2.388 0 002.385-2.384v-5.502zM24.82 42.835a2.759 2.759 0 012.756 2.756 2.76 2.76 0 01-2.756 2.756 2.76 2.76 0 01-2.756-2.756 2.759 2.759 0 012.756-2.756zm0 1.466c-.711 0-1.29.579-1.29 1.29 0 .71.579 1.29 1.29 1.29.711 0 1.29-.58 1.29-1.29 0-.711-.579-1.29-1.29-1.29zM35.731 2.59h-21.82a2.388 2.388 0 00-2.385 2.384v35.208h26.59V8.824H13.91V7.358h24.206V4.974a2.387 2.387 0 00-2.385-2.384zm-7.793 1.65v1.467h-6.234V4.241h6.234z"
            fill={color}
            fillRule="evenodd"
        />
    </svg>
)


export default SvgMobile