import React, { useContext } from 'react'
import { DeckCsr } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styles from '../../../styles'
import parse from 'html-react-parser'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyles = makeStyles(
	(theme) => (
		{
			csrContainerStyle: {
				background: theme.palette.blue['5'],

				'& > div': {
					margin: 'auto',
					'& > div:first-child': {
						'& h1': {
							margin: 0,
							color: theme.palette.blue.main
						}
					},
					'& > div:last-child': {
						'& > div:first-child': {
							'& h2, & p': {
								margin: 0,
								color: theme.palette.blue.main
							},
							'& h2': {
								marginBottom: 30,
								...styles(theme).text13
							},
							'& p': {
								...styles(theme).text20
							}
						},
						'& img': {
							width: 150,
							height: 150
						}
					}
				}
			},
			csrContainerLargeScreenStyle: {
				marginTop: 115,
				padding: '115px 0',

				'& > div': {
					maxWidth: 1100,
					'& > div:first-child': {
						marginBottom: 50,

						'& h1': {
							...styles(theme).title1
						}
					},
					'& > div:last-child': {
						'& img': {
							marginBottom: 75,
							marginRight: 15
						}
					}
				}
			},
			csrContainerSmallScreenStyle: {
				marginTop: 100,
				padding: '60px 0',

				'& > div': {
					'& > div:first-child': {
						marginBottom: 30,

						'& h1': {
							...styles(theme).title2
						}
					},
					'& > div:last-child': {
						'& > div:first-child': {
							marginBottom: 20
						},
						'& > div:last-child': {
							'& > div': {
								'& img': {
									marginBottom: 40,
									marginRight: 9
								}
							}
						}
					}
				}
			},
			labelClickable: {
				cursor: 'pointer'
			}
		}
	)
)

interface AcceptingProps {
	csr: DeckCsr
}

type deckCsrProps = AcceptingProps

const DeckCsrPage: React.FC<deckCsrProps> = (
	{
		csr
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const csrContainerClass: ClassValue = classNames(
		classes.csrContainerStyle,
		isLargeScreen ? classes.csrContainerLargeScreenStyle : classes.csrContainerSmallScreenStyle
	)

	const openLabel = (url: string | undefined) => {
		if (url) {
			const a = document.createElement('a')
			a.href = url
			a.target = '_blank'
			a.click()
		}
	}

	return (
		<div className={csrContainerClass}>
			<div className="marginContent">
				<div className="row">
					<div className={`col-xs-12 ${csr.labels && csr.labels.length > 0 ? 'col-md-6' : 'col-md-12'}`}>
						<h1>
							<FormattedMessage
								id="deck.csr.title"
								defaultMessage="Responsabilité, labels et certificats"
								description="CSR title"
							/>
						</h1>
					</div>
				</div>

				<div className="row between-md">
					<div className={`col-xs-12 ${csr.labels && csr.labels.length > 0 ? 'col-md-6' : 'col-md-12'}`}>
						<span>{parse(csr.description)}</span>
					</div>

					{
						csr.labels && csr.labels.length > 0 && (
							<div className="col-xs-12 col-md-5">
								<div className="row">
									{
										csr.labels.map(
											(label) => (
												<img
													key={label.picture}
													src={label.picture}
													alt={label.alternative}
													onClick={() => openLabel(label.link)}
													className={label.link ? classes.labelClickable : ''}
												/>
											)
										)
									}
								</div>
							</div>
						)
					}
				</div>
			</div>
		</div>
	)
}

export default DeckCsrPage
