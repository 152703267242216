import flatten from 'flat'
import { ModelTranslations } from '../components/langContext/languagesModel'

const target = {
    global: {
        unit: '{value} sqm ',
        unitStandalone: ' sqm',
        btn: {
            save: 'Save',
            cancel: 'Cancel',
            accept: 'Accept',
            refuse: 'Decline',
            next: 'Next',
            validate: 'Confirm',
            send: 'Send'
        },
        errors: {
            required: 'Required*',
            formatMail: 'The email format is not correct',
            emptyOffers: 'No offer was found',
            authorizedExt: 'Accepted file formats: .png, .jpg, .jpeg, .gif, .pdf',
            validateCaptcha: 'Please validate the CAPTCHA'
        }
    },
    app: {
        details: 'Learn more',
        back: 'Back'
    },
    errors: {
        default: 'An error has occurred, please try again later',
        unauthorized: 'You are not authorized to access this content'
    },
    menu: {
        close: 'Close',
        homepage: 'Home',
        selection: 'Your selection',
        research: 'Your research',
        offers: 'All our offers',
        documents: 'Your documents',
        profile: 'Your profile',
        logout: 'Sign out'
    },
    homepage: {
        title: 'Welcome',
        lastVisit: 'Return to your previous visit ',
        customSelection: 'Discover your personalized selection ',
        allOffers: 'Check out all our offers '
    },
    selection: {
        visits: 'Your visits',
        visit: 'Your latest visits ',
        selectedOffers: 'Our selected offers for you',
        availableArea: 'Available space ',
        around: '(around) ',
        divisibleFrom: 'Able to be divided from ',
        indicativeAvailability: 'Indicative availability ',
        availability: 'Availability',
        immediate: 'Immediate ',
        nature: {
            label: 'Type of lease',
            commercial: 'Commercial lease',
            derogatory: 'Derogatory lease',
	        prestations_services: 'Services contract'
        },
        elementDisplayed: {
            offers: 'Map',
            map: 'See list'
        },
        rent: 'Already rented'
    },
    deck: {
        menu: {
            presentation: 'Presentation',
            location: 'Location',
            keyPoints: 'Key points',
            spaces: 'Spaces',
            visit: 'Visits',
            technical: 'Technical',
            csr: 'CSR',
            services: 'Services',
            pictures: 'Photos',
            contacts: 'Contacts'
        },
        presentation: {
            title: 'Presentation',
            printError: 'Error while printing deck'
        },
        location: {
            title: 'Location and transport links',
            caption: 'Gecina portfolio',
            stop: 'Access with public transport',
            metro: 'Metro',
            rer: 'RER',
            bus: 'Bus',
            tramway: 'Tram',
            transilien: 'Transilien'
        },
        keyPoints: {
            title: 'Key points'
        },
        spaces: {
            title: 'Spaces'
        },
        visit: {
            title: {
                virtual: 'Virtual visit',
                presentation: '3D presentation'
            },
            fullScreen: 'See in fullscreen',
            perspective: 'Perspectives'
        },
        surfaces: {
            title: 'Summary of surface areas ',
            level: 'Level',
            surface: 'Space (around)',
            garden: 'Terraces / Garden',
            availableSurface: 'Available space',
            estimation: 'Estimated capacity'
        },
        plans: {
            title: 'Plans'
        },
        technical: {
            title: 'General technical features',
            download: 'Download the full technical file',
            filename: 'Technical file'
        },
        csr: {
            title: 'Commitments, labels and certificates'
        },
        services: {
            title: 'Services'
        },
        pictures: {
            title: 'Photos'
        },
        contacts: {
            title: 'Our letting partners '
        },
        credit: {
            credit: 'Credits:',
            nonContractual: 'Non contractual - confidential data'
        }
    },
    footer: {
        details: {
            error: 'The page you requested cannot be found'
        }
    },
    favorites: {
        title: 'Your favorites',
        tooltip: 'You have added an offer to your favorites. All of your favorites can be found here'
    },
    offers: {
        research: {
            title: 'What are your plans?',
            subtitle: 'Discover our living and working spaces located primarily at the heart of urban life',
            selects: {
                cities: 'Indicate the city(s)',
                disponibility: 'Availability',
                disponibilityPlaceholder: 'Availability',
                disponibleNow: 'Immediately available',
                surface: 'Space',
                untilSurface: 'Up to {max}sqm',
                concatSurface: 'From {min}sqm to {max}sqm',
                firstSemester: '1st half {date}',
                secondSemester: '2nd half {date}',
                oneSemester: '1 selected semester',
                semesters: '{semesters} selected semesters'
            },
            errors: {
                minGreaterThanMax: 'The minimum cannot be higher than the maximum '
            },
            submit: 'See our offers',
            seeAllOffers: 'Discover all our offers',
            commercial: {
                title: 'Are you looking for a commercial lease?',
                button: 'Click here '
            }
        },
        list: {
            offer: '{value} offer',
            match: 'Matches your search ',
            matches: 'Matches your search',
            offerInterest: ' and one may be of interest to you',
            offersInterest: ' and {offers} can be of interest to you',
            canInterest: 'You might also be interested in',
            noMatching: 'Unfortunately, we do not have any offers that match your criteria'
        }
    },
    cookie: {
        title: 'Cookie management',
        text: 'This site uses Google Analytics. By accepting, you authorise us to deposit cookies for analysing visitors. {br} For more information, visit our page {page}',
        link: 'Cookie management policy'
    },
    popinYf: {
        subtitle: 'Your YouFirst contact',
        listening: 'I am here to help',
        sendMessage: 'Send a message',
        phone: 'Phone',
        email: 'Email',
        makeRequest: {
            title: 'Your request',
            firstStep: {
                lastName: 'Your surname',
                firstName: 'Your first name',
                position: 'Your position',
                mail: 'Your email address',
                positions: {
                    associate: 'Partner',
                    dirBuyer: 'Purchasing director',
                    dirAdmin: 'Administrative director',
                    dirAdminFin: 'Administration and finance director',
                    dirCom: 'Sales director',
                    dirOpe: 'Operations director',
                    dirHR: 'Human resources director',
                    dirServ: 'Facilities management director',
                    dirSysInfo: 'IT systems director',
                    dirExpl: 'Operational director',
                    dirDev: 'Development director',
                    dirFin: 'Finance director',
                    dirImmo: 'Real estate director',
                    dirJur: 'Legal director',
                    dirTec: 'Technical director',
                    fund: 'Founder',
                    ger: 'Manager',
                    member: 'Executive Committee member',
                    resBuyer: 'Purchasing manager',
                    resImmo: 'Real estate manager',
                    resJur: 'Legal manager',
                    resTec: 'Technical manager',
                    other: 'Other'
                }
            },
            secondStep: {
                title: 'The purpose of your request',
                message: 'Enter more details',
                attachment: 'Add an attachment'
            },
            thirdStep: {
                title: 'Your message has been sent.',
                message: 'Your YouFirst team will reply to you as quickly as possible ',
                finish: 'End',
                newMessage: 'Send a new message'
            }
        }
    },
    map: {
        transit: 'Transport links'
    }
}

export default flatten<typeof target, ModelTranslations>(target)
