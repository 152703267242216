import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import GoogleMapReact from 'google-map-react'
import { API_KEY } from '../../../components/offersDisplay/components/map/utils/globalConst'
import TextField from '@material-ui/core/TextField'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import styles from '../../../styles'
import { CitiesModel } from '../types/searchFormModel'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { ThemeContext } from '../../../utils/context/themeContext'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import { InputAdornment } from '@material-ui/core'

const useStyles = makeStyles<Theme, { isVisualHandicap: boolean }>(
	(theme) => (
		{
			searchBoxContainerStyle: {
				marginBottom: 15,

				'& > div:first-child': {
					'& .MuiTextField-root': {
						color: theme.palette.blue.main,
						backgroundColor: theme.palette.blue['5'],
						borderRadius: 40,
						minWidth: 120,
						width: '100%',
						padding: '10px 20px',
						boxSizing: 'border-box',
						...styles(theme).text4
					},
					'& .MuiInput-underline:before, & .MuiInput-underline:after': {
						display: 'none'
					}
				}
			},
			chipStyle: {
				...styles(theme).text4,
				color: 'white',
				backgroundColor: `${theme.palette.blue.main} !important`,
				paddingRight: 5,
				marginRight: 5,
				marginBottom: 5,
				marginTop: 10,

				'& svg': {
					width: 12,
					height: 12,
					color: props => props.isVisualHandicap ? theme.palette.blue.main : 'white !important',
					backgroundColor: props => props.isVisualHandicap ? 'white' : theme.palette.green.main,
					padding: 4,
					borderRadius: 20,
					margin: 0,
					marginLeft: 5
				}
			}
		}
	)
)

interface AcceptingProps {
	id: string
}

const POSTAL_CODE = 'postal_code'
const CITY = 'locality'
const COLLOQUIAL = 'colloquial_area'

type SearchboxFieldProps =
	FieldArrayRenderProps<CitiesModel, HTMLInputElement>
	& AcceptingProps

const SearchboxField: React.FC<SearchboxFieldProps> = (
	{
		fields,
		id
	}
) => {
	const { isVisualHandicap } = useContext(ThemeContext)
	const classes = useStyles({ isVisualHandicap })
	const intl = useIntl()
	const location = useLocation()

	const [value, setValue] = useState<string>('')
	const inputRef = React.useRef<HTMLInputElement>(null)
	const searchBox = React.useRef<google.maps.places.Autocomplete | null>(null)

	const searchBoxContainerClass: ClassValue = classNames(
		'row middle-xs',
		classes.searchBoxContainerStyle
	)

	const handleAddChip = useCallback(
		(value: CitiesModel) => {
			const { push } = fields

			push(value)
			setValue('')
		}, [fields]
	)

	const handleDeleteChip = (index: number) => {
		fields.remove(index)
	}

	const handleOnPlacesChanges = React.useCallback(() => {
		if (searchBox.current != null) {
			const place = searchBox.current.getPlace()

			const indexPostalCode = place.address_components?.findIndex(
				(address) => address.types.includes(POSTAL_CODE)
			)

			const indexCity = place.address_components?.findIndex(
				(address) => address.types.includes(CITY) || address.types.includes(COLLOQUIAL)
			)

			if (indexPostalCode !== undefined && indexPostalCode >= 0 && place.address_components) {
				handleAddChip(
					{
						name: place.address_components[indexPostalCode].long_name,
						lat: place.geometry?.location.lat() || 0,
						lng: place.geometry?.location.lng() || 0
					}
				)
			} else if (indexCity !== undefined && indexCity >= 0 && place.address_components) {
				handleAddChip({
						name: place.address_components[indexCity].long_name,
						lat: place.geometry?.location.lat() || 0,
						lng: place.geometry?.location.lng() || 0
					}
				)
			}
		}
	}, [handleAddChip])

	const initSearchbox = () => {
		if (inputRef.current !== null && google.maps.places) {
			searchBox.current = new google.maps.places.Autocomplete(inputRef.current, {
				types: ['geocode'],
				componentRestrictions: { country: 'fr' }
			})
			searchBox.current.addListener('place_changed', handleOnPlacesChanges)
		}
	}

	useEffect(() => {
		return () => {
			if (searchBox.current) {
				searchBox.current = null
			}
		}
	}, [])

	return (
		<div className={searchBoxContainerClass}>
			<div className={`col-xs-12 ${location.search.length > 0 && 'col-md-5'}`}>
				<TextField
					inputRef={inputRef}
					id={id}
					placeholder={
						intl.formatMessage(
							{
								id: 'offers.research.selects.cities'
							}
						)
					}
					value={value}
					onChange={(e) => setValue(e.target.value)}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<img src="img/offers/location.png" alt="Location icon" width={25}/>
							</InputAdornment>
						),
					}}
				/>
			</div>

			<div className={`col-xs-12 ${location.search.length > 0 && 'col-md-7'}`}>
				{
					fields.map(
						(field, index) => (
							<Chip
								className={classes.chipStyle}
								key={fields.value[index].name}
								deleteIcon={<CloseIcon />}
								onDelete={() => handleDeleteChip(index)}
								label={
									isNaN(Number(fields.value[index].name)) ? fields.value[index].name : thousandsSeparator(Number(fields.value[index].name), true)
								}
							/>
						)
					)
				}
			</div>

			<div style={{ display: 'none' }}>
				<GoogleMapReact
					bootstrapURLKeys={
						{
							key: API_KEY,
							libraries: ['places', 'geometry']
						}
					}
					defaultCenter={{
						lat: 0,
						lng: 0
					}}
					defaultZoom={10}
					yesIWantToUseGoogleMapApiInternals
					onGoogleApiLoaded={() => initSearchbox()}
				/>
			</div>
		</div>
	)
}

export default SearchboxField