import React, { createContext, ReactChild, useState } from 'react'

export const OffersContext = createContext(
    {
        isApiLoaded: false,
        setIsApiLoaded: (isApiLoaded: boolean) => {
        }
    }
)

export default (props: { children: ReactChild }) => {
    const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false)

    return (
        <OffersContext.Provider
            value={{
                isApiLoaded,
                setIsApiLoaded
            }}
        >
            {props.children}
        </OffersContext.Provider>
    )
}