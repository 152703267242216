import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatDate } from '../../utils/formatter/formatDate'
import moment from 'moment'
import { OfferDetailsModel } from './types/offerDetailsModel'
import { fromComponentEnum } from './enum/fromComponentEnum'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import thousandsSeparator from '../../utils/thousandsSeparator'
import { numberTrimester } from '../../modules/research/utils/checkTrimester'

const useStyles = makeStyles(
    (theme) => (
        {
            infoStyle: {
                '& div': {
                    '& span:first-child': {
                        textTransform: 'uppercase',
                        color: theme.palette.blue['70'],
                        ...styles(theme).text14
                    },
                    '& span:last-child': {
                        color: theme.palette.blue.main,
                        ...styles(theme).text8
                    }
                }
            },
            infoLargeScreenStyle: {
                marginTop: 16
            },
            infoSmallScreenStyle: {
                marginTop: 10,

                '& .col-xs-8': {
                    margin: '20px auto 0'
                },
                '& .col-xs-8:last-child': {
                    marginBottom: 15
                }
            },
            infoMapStyle: {
                marginTop: 10,

                '& div': {
                    '& span:first-child': {
                        textTransform: 'uppercase',
                        color: theme.palette.blue['70'],
                        ...styles(theme).text22
                    },
                    '& span:last-child': {
                        color: theme.palette.blue.main,
                        ...styles(theme).text4
                    }
                }
            },
            deckStyle: {
                background: 'white',
                textAlign: 'left'
            },
            deckLargeScreenStyle: {
                height: 121,
                padding: '0 95px'
            },
            deckSmallScreenStyle: {
                padding: '0 5%'
            }
        }
    )
)

interface AcceptingProps {
    item: OfferDetailsModel,
    from: fromComponentEnum
}

type OfferDetailsProps = AcceptingProps

const OfferDetails: React.FC<OfferDetailsProps> = (
    {
        item,
        from
    }
) => {
    const classes = useStyles()
    const intl = useIntl()
    const { isLargeScreen } = useContext(ResponsiveContext)

    const infoClass: ClassValue = classNames(
        'row',
        {
            [classes.infoStyle]: from === fromComponentEnum.deck || from === fromComponentEnum.selection,
            [classes.infoMapStyle]: from === fromComponentEnum.map,
            [classes.infoLargeScreenStyle]: (from === fromComponentEnum.deck || from === fromComponentEnum.selection) && isLargeScreen,
            [classes.infoSmallScreenStyle]: (from === fromComponentEnum.deck || from === fromComponentEnum.selection) && !isLargeScreen,
            [classes.deckStyle]: from === fromComponentEnum.deck,
            [classes.deckLargeScreenStyle]: from === fromComponentEnum.deck && isLargeScreen,
            [classes.deckSmallScreenStyle]: from === fromComponentEnum.deck && !isLargeScreen
        }
    )

    const disponibilityComponent = () => (
        <div
            className={from === fromComponentEnum.selection || from === fromComponentEnum.map ? 'col-xs-12' : 'col-xs-8 col-md-3'}>
            {
                new Date(item.indicativeAvailability) < new Date() && (
                    <>
						<span>
							<FormattedMessage
                                id="selection.availability"
                                defaultMessage="Disponibilité"
                                description="Indicative availability text"
                            />
						</span>

                        <br/>

                        <span>
							<FormattedMessage
                                id="selection.immediate"
                                defaultMessage="Immédiate"
                                description="Immediate text"
                            />
						</span>
                    </>
                )
            }
            {
                new Date(item.indicativeAvailability) > new Date() && Number(item.availableArea) < 1000 && (
                    <>
						<span>
							<FormattedMessage
                                id="selection.indicativeAvailability"
                                defaultMessage="Disponibilité indicative"
                                description="Indicative availability text"
                            />
						</span>

                        <br/>

                        <span>{formatDate(moment(item.indicativeAvailability, 'YYYY-MM-DD').toDate(), intl.locale)}</span>
                    </>
                )
            }
            {
                new Date(item.indicativeAvailability) > new Date() && Number(item.availableArea) >= 1000 && (
                    <>
						<span>
							<FormattedMessage
                                id="selection.indicativeAvailability"
                                defaultMessage="Disponibilité indicative"
                                description="Indicative availability text"
                            />
						</span>

                        <br/>

                        <span>{`${numberTrimester(new Date(item.indicativeAvailability), intl).charAt(0)}T${new Date(item.indicativeAvailability).getFullYear()}`}</span>
                    </>
                )
            }
        </div>
    )

    return (
        <>
            <div className={infoClass}>
                <div
                    className={from === fromComponentEnum.selection || from === fromComponentEnum.map ? 'col-xs-6' : 'col-xs-8 col-md-3'}>
					<span>
						<FormattedMessage
                            id="selection.availableArea"
                            defaultMessage="Surface disponible"
                            description="Area text"
                        />
					</span>

                    <br/>

                    <span>
						<FormattedMessage
                            id="global.unit"
                            defaultMessage="m²"
                            description="Unit"
                            values={
                                {
                                    value: thousandsSeparator(Number(item.availableArea), true)
                                }
                            }
                        />

                        {
                            from === fromComponentEnum.deck && (
                                <small>
                                    <FormattedMessage
                                        id="selection.around"
                                        defaultMessage="Environ"
                                        description="Around text"
                                    />
                                </small>
                            )
                        }
					</span>
                </div>

                {
                    Number(item.divisibleFrom) !== Number(item.availableArea) && (
                        <div
                            className={from === fromComponentEnum.selection || from === fromComponentEnum.map ? 'col-xs-6' : 'col-xs-8 col-md-3'}>
							<span>
								<FormattedMessage
                                    id="selection.divisibleFrom"
                                    defaultMessage="Divisible dès"
                                    description="Divisible from text"
                                />
							</span>

                            <br/>

                            <span>
								<FormattedMessage
                                    id="global.unit"
                                    defaultMessage="m²"
                                    description="Unit"
                                    values={
                                        {
                                            value: thousandsSeparator(Number(item.divisibleFrom), true)
                                        }
                                    }
                                />
							</span>
                        </div>
                    )
                }

                {
                    from === fromComponentEnum.deck && (
                        <>
                            {disponibilityComponent()}

                            <div className="col-xs-8 col-md-3">
								<span>
									<FormattedMessage
                                        id="selection.nature.label"
                                        defaultMessage="Nature du bail"
                                        description="Nature label"
                                    />
								</span>

                                <br/>

                                <span>
                                    <FormattedMessage
                                        id={`selection.nature.${item.nature?.includes('dérogatoire') 
	                                        ? 'derogatory' 
	                                        : item.nature?.includes('prestations de services') 
		                                        ? 'prestations_services'
		                                        : 'commercial'
										}`}
                                        defaultMessage="Bail commercial"
                                        description="Nature text"
                                    />
                                </span>
                            </div>
                        </>
                    )
                }
            </div>

            {
                (from === fromComponentEnum.selection || from === fromComponentEnum.map) && (
                    <div className={infoClass}>
                        {disponibilityComponent()}
                    </div>
                )
            }
        </>
    )
}

export default OfferDetails
