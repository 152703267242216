import React from 'react'
import Dialog from '../../../../components/dialog/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../index'
import { getModalContent, isModalVisible } from '../../../../components/dialog/reducer/selector'
import { modalTypes } from '../../../../components/dialog/enum/modalTypes'
import { hideModal } from '../../../../components/dialog/reducer/actions'
import DeckSlider from '../DeckSlider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
    (theme) => (
        {
            modalContainer: {
                height: '100vh',
                background: theme.palette.blue.main,

                '& > .row': {
                    height: '100%',

                    '& > .col-xs-12': {
                        maxHeight: '100vh',

                        '& .swiper-container': {
                            height: '90%',

                            '& .swiper-slide': {
                                visibility: 'hidden'
                            },
                            '& .swiper-slide-active': {
                                width: '100% !important',
                                visibility: 'visible'
                            },
                            '& .sliderImage': {
                                background: theme.palette.blue.main,
                                height: '99%',
                                display: 'flex',

                                '& img': {
                                    height: 'auto',
                                    width: 'auto',
                                    maxWidth: '100%',
                                    margin: 'auto'
                                }
                            }
                        }
                    }
                },
                '& .sliderDetails': {
                    height: '10%'
                }
            }
        }
    )
)

const PictureModal = () => {
    const classes = useStyles()
    const isOpen = useSelector((state: RootState) => isModalVisible(state, modalTypes.deck))
    const modalContent = useSelector((state: RootState) => getModalContent(state, modalTypes.deck))
    const dispatch = useDispatch()

    const onCloseDialog = () => {
        dispatch(
            hideModal(modalTypes.deck)
        )
    }

    if (!modalContent.content) {
        return (
            <></>
        )
    }

    return (
        <Dialog
            onCloseDialog={onCloseDialog}
            open={isOpen}
        >
            <div className={classes.modalContainer}>
                <DeckSlider content={modalContent.content}/>
            </div>
        </Dialog>
    )
}

export default PictureModal