import React, { useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { FieldArrayRenderProps } from 'react-final-form-arrays'
import CloseIcon from '@material-ui/icons/Close'
import SvgAttachment from '../../icons/Attachment'
import { FileModel } from '../../types/fields/fieldsModel'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { ThemeContext } from '../../utils/context/themeContext'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { displaySnackbar } from '../snackBar/reducer/actions'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			errorStyle: {
				color: '#B92D00',
				fontSize: 10
			},
			textSize: {
				fontSize: 14
			},
			alignItems: {
				verticalAlign: 'middle'
			},
			alignItemsLargeScreen: {
				'&:first-child': {
					marginRight: 20
				}
			},
			alignItemsSmallScreen: {
				'&:first-child': {
					marginRight: 10
				}
			},
			customFontWeight: {
				fontWeight: 600
			},
			labelLargeScreenStyle: {
				color: theme.palette.blue['30']
			},
			fileListStyle: {
				padding: '12px 12px 12px 16px',
				background: theme.palette.blue['5'],
				marginBottom: 20,

				'& svg': {
					marginLeft: 'auto'
				}
			}
		}
	)
)

interface AcceptingProps {
	label: React.ReactChild,
	id: string,
}

const AcceptedExtensions: string[] = ['png', 'jpg', 'jpeg', 'gif', 'pdf']

type FormFileProps =
	FieldArrayRenderProps<FileModel, HTMLElement>
	& AcceptingProps

const FormFile: React.FC<FormFileProps> = (
	{
		fields,
		label,
		id,
		meta: {
			error,
			submitFailed
		}
	}
) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const intl = useIntl()
	const { isVisualHandicap } = useContext(ThemeContext)
	const { isLargeScreen } = useContext(ResponsiveContext)
	const attachmentImg = isLargeScreen ? <SvgAttachment color="#122941" /> : <SvgAttachment color={isVisualHandicap ? '#122941' : '#00ACA9'} />
	const inputRef = useRef<HTMLInputElement>(document.createElement('input'))

	const fileNameClass: ClassValue = classNames(
		classes.colorBlue,
		classes.alignItems,
		{
			[classes.alignItemsLargeScreen]: isLargeScreen,
			[classes.text2]: isLargeScreen,
			[classes.alignItemsSmallScreen]: !isLargeScreen,
			[classes.text3_opacity]: !isLargeScreen
		}
	)

	const fileIconClass: ClassValue = classNames(
		classes.colorGreen,
		classes.alignItems,
		classes.cursorPointer,
		isLargeScreen ? classes.alignItemsLargeScreen : classes.alignItemsSmallScreen
	)

	const addFileContainerClass: ClassValue = classNames(
		'col-xs-12',
		classes.cursorPointer,
		classes.flex,
		classes.alignCenter
	)

	const addFileIconClass: ClassValue = classNames(
		classes.colorGreen,
		classes.alignItems,
		isLargeScreen ? classes.alignItemsLargeScreen : classes.alignItemsSmallScreen
	)

	const labelClass: ClassValue = classNames(
		classes.alignItems,
		{
			[classes.alignItemsLargeScreen]: isLargeScreen,
			[classes.labelLargeScreenStyle]: isLargeScreen,
			[classes.text12]: isLargeScreen,
			[classes.alignItemsSmallScreen]: !isLargeScreen,
			[classes.colorAccessibility]: !isLargeScreen,
			[classes.text4]: !isLargeScreen
		}
	)

	const fileListClass: ClassValue = classNames(
		'col-xs-12',
		classes.fileListStyle,
		classes.flex,
		classes.alignCenter
	)

	const openFileSelectionner = () => {
		const input = inputRef.current
		input.click()
	}

	const onFileSelected = (event: any) => {
		const { push } = fields
		for (let i = 0; i < event.target.files.length; i++) {
			if (isCorrectExtension(event.target.files[i].name)) {
				toBase64(event.target.files[i])
					.then(
						(response: any) => {
							push(
								{
									file: response.file,
									encodedFile: response.encodedFile
								}
							)
						}
					)
			} else {
				dispatch(
					displaySnackbar(
						{
							id: 'inputFile',
							message: intl.formatMessage({
								id: 'global.errors.authorizedExt',
								defaultMessage: 'Fichiers autorisés : .png, .jpg, .jpeg, .gif, .pdf',
								description: 'error extension file'
							}),
							open: true,
							hideIcon: true
						}
					)
				)
			}
		}
	}

	const isCorrectExtension = (name: string) => {
		const lastDot = name.lastIndexOf('.')
		const ext = name.substring(lastDot + 1)
		return AcceptedExtensions.includes(ext.toLowerCase())
	}

	const toBase64 = (file: File) => new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(
			{
				file: file.name,
				encodedFile: reader.result
			}
		)
		reader.onerror = error => reject(error)
	})

	return (
		<div>
			{
				fields.map((field: any, index: number) => {
						return (
							<div
								className="row"
								key={fields.value[index].file}
							>
								<div
									key={index}
									className={fileListClass}
								>
									<div className={fileIconClass}>
										<SvgAttachment color="#122941" />
									</div>

									<span className={fileNameClass}>
										{fields.value[index].file}
									</span>

									<CloseIcon
										onClick={() => fields.remove(index)}
										className={classes.cursorPointer}
									/>
								</div>
							</div>
						)
					}
				)
			}

			<div
				className={addFileContainerClass}
				onClick={openFileSelectionner}
			>
				<div className={addFileIconClass}>
					{attachmentImg}
				</div>

				<span className={labelClass}>
					{label}
				</span>

				<input
					id={id}
					type="file"
					hidden
					multiple
					onChange={onFileSelected}
					ref={inputRef}
				/>

				<br />

				{
					(submitFailed && error &&
						<span className={classes.errorStyle}>{error}</span>)
				}
			</div>
		</div>
	)
}

export default FormFile
