import React, { useContext, useState } from 'react'
import { DeckModel } from '../types/deckModel'
import DeckHeader from './DeckHeader'
import DeckPresentation from './DeckPresentation'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Element } from 'react-scroll/modules'
import { deckEnum } from '../enum/deckEnum'
import DeckVideo from './DeckVideo'
import DeckLocation from './DeckLocation'
import DeckKeyPoints from './DeckKeyPoints'
import DeckSpaces from './DeckSpaces'
import DeckSurfaces from './DeckSurfaces'
import DeckPlans from './DeckPlans'
import DeckTechnical from './DeckTechnical'
import DeckCsr from './DeckCsr'
import DeckServices from './DeckServices'
import DeckPictures from './DeckPictures'
import DeckContacts from './DeckContacts'
import { Sticky, StickyContainer } from 'react-sticky'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import PictureModal from './modal/PictureModal'
import styles from '../../../styles'
import DeckCredit from './DeckCredit'
import DeckVisit from './DeckVisit'

const useStyles = makeStyles(
    (theme) => (
        {
            deckModalContainerStyle: {
                position: 'relative',

                '& ul, & ol': {
                    color: theme.palette.blue.main,

                    '& li': {
                        lineHeight: 1.5
                    }
                },
                '& > div:first-child': {
                    '& > div': {
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%'
                    },
                    '& > .img': {
                        backgroundImage: (props: DeckModel) => `url("${props.presentation.backgroundPicture}")`,
                        backgroundPosition: 'center',
                        backgroundColor: theme.palette.blue['80'],
                        backgroundRepeat: 'no-repeat'
                    },
                    '& > .filter': {
                        backgroundColor: theme.palette.blue.main,
                        opacity: '0.85'
                    },
                    '& > .bg-blue': {
                        backgroundColor: theme.palette.blue.main
                    }
                },
                '& > div:nth-child(2)': {
                    position: 'relative'
                }
            },
            deckModalContainerLargeScreenStyle: {
                '& > div:first-child': {
                    '& > div': {
                        height: 608
                    }
                }
            },
            deckModalContainerSmallScreenStyle: {
                '& > div:first-child': {
                    '& > div': {
                        height: 460
                    }
                },
                '& .marginContent': {
                    marginLeft: 20,
                    marginRight: 20
                }
            },
            deckModalContainerTabletteScreenStyle: {
                '& > div:first-child': {
                    '& > div': {
                        height: '45vh'
                    }
                },
                '& .marginContent': {
                    marginLeft: 20,
                    marginRight: 20
                }
            },
            deckStyle: {
                width: '100%',

                '& .imgContainer': {
                    position: 'relative',

                    '& span': {
                        position: 'absolute',
                        bottom: -15,
                        left: 0,
                        background: theme.palette.blue.main,
                        color: 'white',
                        padding: 10,
                        maxWidth: '85%',
                        boxSizing: 'border-box',
                        wordBreak: 'break-all'
                    }
                }
            },
            deckLargeScreenStyle: {
                '& .imgContainer': {
                    '& span': {
                        ...styles(theme).text4
                    }
                }
            },
            deckSmallScreenStyle: {
                '& .imgContainer': {
                    '& span': {
                        ...styles(theme).text22
                    }
                }
            },
            deckMenuStyle: {
                perspective: 'inherit !important',
                perspectiveOrigin: 'inherit !important',
                overflow: 'hidden !important'
            }
        }
    )
)

interface AcceptingProps {
    content: DeckModel
}

type DeckModalProps = AcceptingProps

const DeckModal: React.FC<DeckModalProps> = (
    {
        content
    }
) => {
    const classes = useStyles(content)
    const theme = useTheme()
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)
    const [open, setOpen] = useState<boolean>(false)

    const deckMenuClass: ClassValue = classNames(
        classes.deckStyle,
        isLargeScreen ? classes.deckLargeScreenStyle : classes.deckSmallScreenStyle,
	    classes.deckMenuStyle
    )

    const deckModalContainerClass: ClassValue = classNames(
        classes.deckModalContainerStyle,
        {
            [classes.deckModalContainerLargeScreenStyle]: isLargeScreen,
            [classes.deckModalContainerTabletteScreenStyle]: isTabletteScreen && !isLargeScreen,
            [classes.deckModalContainerSmallScreenStyle]: !isTabletteScreen
        }
    )

    return (
        <div
            id="deck-menu"
            className={deckMenuClass}
        >
            <StickyContainer className={deckModalContainerClass}>
                <div>
                    {
                        content.presentation.backgroundPicture ? (
                            <>
                                <div className="img" />
                                <div className="filter" />
                            </>
                        ) : (
                            <div className="bg-blue"/>
                        )
                    }
                </div>

                <Sticky>
                    {
                        (
                            {
                                style,
                                distanceFromTop
                            }
                        ) => (
                            <div
                                style={{
                                    ...style,
                                    zIndex: 2,
                                    backgroundColor: theme.palette.blue.main
                                }}
                            >
                                <DeckHeader
                                    content={content}
                                    fromTop={distanceFromTop * -1}
                                    isOpen={open}
                                    setOpen={setOpen}
                                />
                            </div>
                        )
                    }
                </Sticky>

                <div>
                    <Element
                        className="marginContent"
                        name={deckEnum.presentation}
                    >
                        <DeckPresentation
                            presentation={content.presentation}
                            meta={content.metas}
                        />

                        {
                            content.video && (
                                <DeckVideo video={content.video}/>
                            )
                        }
                    </Element>

                    <Element
                        className="marginContent"
                        name={deckEnum.location}
                    >
                        <DeckLocation location={content.location}/>
                    </Element>

                    <Element name={deckEnum.keyPoints}>
                        <DeckKeyPoints keyPoints={content.keyPoints}/>
                    </Element>

                    {
                        content.spaces && (
                            <Element
                                className="marginContent"
                                name={deckEnum.spaces}
                            >
                                <DeckSpaces spaces={content.spaces}/>
                            </Element>
                        )
                    }

                    {
                        content.surfaces && content.surfaces.length > 0 && (
                            <div>
                                <DeckSurfaces surfaces={content.surfaces}/>
                            </div>
                        )
                    }

                    <div>
                        <DeckPlans pictures={content.plans}/>
                    </div>

                    {
                        content.visit && (
                            <Element name={deckEnum.visit}>
                                <DeckVisit visit={content.visit}/>
                            </Element>
                        )
                    }

                    <Element
                        className="marginContent"
                        name={deckEnum.technical}
                    >
                        <DeckTechnical technical={content.technical}/>
                    </Element>

                    {
                        content.csr && (
                            <Element name={deckEnum.csr}>
                                <DeckCsr csr={content.csr}/>
                            </Element>
                        )
                    }

                    {
                        content.services && (
                            <Element
                                className="marginContent"
                                name={deckEnum.services}
                            >
                                <DeckServices services={content.services}/>
                            </Element>
                        )
                    }

                    {
                        content.pictures && (
                            <Element
                                name={deckEnum.pictures}
                                className="marginContent"
                            >
                                <DeckPictures pictures={content.pictures}/>
                            </Element>
                        )
                    }

                    {
                        content.contacts && content.contacts.length > 0 && (
                            <Element name={deckEnum.contacts}>
                                <DeckContacts contacts={content.contacts}/>
                            </Element>
                        )
                    }

                    <DeckCredit credit={content.credit} hasContact={content.contacts && content.contacts.length > 0}/>
                </div>
            </StickyContainer>

            <PictureModal/>
        </div>
    )
}

export default DeckModal
