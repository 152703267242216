import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../index'
import Loader from '../../components/loader/Loader'
import SelectionsComponent from './components/offers/SelectionsComponent'
import OffersDisplay from '../../components/offersDisplay/OffersDisplay'

const Selection = () => {
	const selections = useSelector((state: RootState) => state.selection)

	if (!selections.loaded) {
		return (
			<Loader />
		)
	}

	return (
		<OffersDisplay
			selections={selections}
			offers={<SelectionsComponent />}
		/>
	)
}

export default Selection
