import { DeckModel } from '../types/deckModel'
import { natureEnum } from '../../../enums/natureEnum'
import { stopsEnum } from '../enum/stopsEnum'

export const DECK_MOCK: DeckModel = {
	metas: {
		id: '',
		id_crm: '',
		selected: false,
	},
	presentation: {
		backgroundPicture: '<img src="/img/homepage/homepage_bg1.jpg" alt="" />',
		name: 'Be Issy',
		baseline: 'Be Issy, au cœur du Grand Paris',
		address: '16 boulevard Garibaldi',
		postalCode: '92130',
		city: 'Issy-les-Moulineaux',
		availableArea: '5100',
		divisibleFrom: '1000',
		indicativeAvailability: '21/12/2021',
		nature: natureEnum.commercial,
		customMessage: 'Bonjour M. XXX, suite à notre échange veuillez trouver ci-dessous des documents complémentaires pour guider votre recherche. Vous trouverez également sur cette page toutes les informations sur notre immeuble Be Issy.',
		pictures: ['<img src="/img/deck/buildings.png" alt="" />', '<img src="/img/homepage/homepage_bg1.jpg" alt="" />'],
		captions: [null, null],
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut non aliquam urna. Suspendisse commodo non odio vel euismod. Vivamus eget massa vitae ipsum elementum iaculis in sed elit. Cras eu aliquam ex, a vestibulum nisl. Nunc pharetra tincidunt tincidunt. Suspendisse potenti. Phasellus vehicula nisl at turpis malesuada, ut dictum lorem fringilla. Duis facilisis ex vel rhoncus congue. Donec neque nibh, semper at facilisis non, feugiat vitae velit.<br />' +
			'<br />' +
			'Integer semper bibendum faucibus. Pellentesque posuere lorem quis sem dignissim malesuada. Maecenas varius, lacus id blandit vestibulum, urna tortor rutrum augue, eget pellentesque est mauris ac ex. Aliquam at dictum metus. In scelerisque tristique purus, sed aliquet ex pulvinar porttitor. Donec lobortis, odio eget pellentesque semper, odio diam accumsan tellus, at dictum sem risus vitae nunc. Nam vitae accumsan massa. Aliquam porttitor metus non quam volutpat maximus. Duis interdum purus metus, id pretium elit iaculis quis. Curabitur eleifend hendrerit massa, sed commodo velit dignissim quis. Sed vel elit in risus pellentesque volutpat sit amet at purus. Suspendisse sem magna, lobortis ac velit non, condimentum eleifend erat. Sed faucibus hendrerit quam, vel tincidunt dui. Nam lectus tellus, eleifend et ultricies at, euismod ut orci. In id volutpat lorem. In ac tellus ut arcu pulvinar accumsan quis ut erat.'
	},
	video: {
		video: 'https://www.youtube.com/watch?v=T9ZCORwVNr8'
	},
	location: {
		hookLocation: 'L’immeuble Be Issy implanté au cœur d’Issy-les Moulineaux signe l’ambition durable de Gecina en matière d’immobilier.',
		map: {
			picture:  '/img/deck/map.png',
			caption: 'Img de la map'
		},
		stops: [
			{
				type: stopsEnum.metro,
				icon: '/img/deck/bus6.jpg',
				name: 'Bus 6',
				line: '6',
				duration: 2
			},
			{
				type: stopsEnum.rer,
				icon: '/img/deck/bus6.jpg',
				name: 'Bus 2',
				line: '2',
				duration: 10
			},
			{
				type: stopsEnum.metro,
				icon: '/img/deck/bus6.jpg',
				name: 'Métro 2',
				line: '2',
				duration: 1
			}
		],
		title: 'Be Business',
		hookNeighborhood: 'Le quartier d’affaires d’Issy Les Moulineaux lorem ipsumeget eleifend ipsum. Vivamus sit amet tincidunt turpis, a dignissim 530m2 . Nullam eu magna convallis, finibus sapien a, malesuada nunc. Proin tincidunt ante vel sem tempor, in dignissim ipsum tempor. ',
		picture: '/img/homepage/homepage_bg1.jpg'
	},
	keyPoints: {
		text: '<ul><li>Un immeuble pas comme les autres</li><li>6 162m², du bon matos</li></ul>',
		picture: '/img/deck/keyPoints.png'
	},
	spaces: {
		text: 'L’immeuble a été conçu pour pouvoir accueillir une grande entreprise, en lui offrant la faculté de pouvoir faire fonctionner différents départements en totale autonomie. <br />' +
			'Sa capacité d’accueil s’élève à 1 877 personnes, soit un ratio de 1/12 m² de bureaux.',
		list: [
			{
				title: 'Terasse',
				hook: 'Cras eget eleifend ipsum. Vivamus sit amet tincidunt turpis, a dignissim 530m2 . Nullam eu magna convallis, finibus sapien a, malesuada nunc. Proin tincidunt ante vel sem tempor, in dignissim ipsum tempor.',
				picture: '/img/deck/terrasse.png',
				alternative: '',
				caption: null
			},
			{
				title: 'Terasse 2',
				hook: 'Cras eget eleifend ipsum. Vivamus sit amet tincidunt turpis, a dignissim 530m2 . Nullam eu magna convallis, finibus sapien a, malesuada nunc. Proin tincidunt ante vel sem tempor, in dignissim ipsum tempor.',
				picture: '/img/deck/terrasse.png',
				alternative: '',
				caption: 'Terrasse stylé !'
			}
		]
	},
	surfaces: [
		{
			level: 'R+4',
			surface: 675,
			garden: 201,
			estimation: 20
		},
		{
			level: 'R+4',
			surface: 454,
			garden: 485,
			estimation: 321
		}
	],
	plans: [
		{
			title: 'RDC',
			picture: '/img/deck/plan.png'
		},
		{
			title: 'RDC 2',
			picture: '/img/deck/plan.png'
		}
	],
	technical: {
		description: '<h2>COuezhfeizh</h2><p>OKokcoekok</p>',
		download: 'https://google.com'
	},
	csr: {
		description: '<h2>Un immeuble respectueux de l\'environnement</h2><p>L’approvisionnement en énergies se fait par les réseaux urbains, peu émetteurs de gaz à effet de serre <br />' +
			'La production d’eau chaude sanitaire des restaurants est réalisée par le réseau de chaleur, complétée par une installation solaire thermique <br />' +
			'Afin de permettre une gestion éco-responsable des consommations d’eaux et d’énergies, un système de comptage relié à la GTB de l’immeuble permettra de mesurer les consommations par usage et par lot commercial</p>',
		labels: [
			{
				picture: '/img/deck/breeam.png',
				alternative: 'Img Breeam',
				link: 'https://google.com'
			},
			{
				picture: '/img/deck/breeam.png',
				alternative: 'Img Breeam'
			},
			{
				picture: '/img/deck/breeam.png',
				alternative: 'Img Breeam'
			}
		]
	},
	services: {
		text: 'Entouré d’espaces verts, le rez-de-chaussée de l’immauble est totalement dédié aux services. <br />' +
			'Accessibles depuis le hall double hauteur et baignés de lulmière naturelle, ces differents lieux sont tous ouverts sur les jardins de l’immeuble',
		list: [
			{
				title: 'Restauration',
				hook: 'Cras eget eleifend ipsum. Vivamus sit amet tincidunt turpis, a dignissim tortor. Nullam eu magna convallis, finibus sapien a, malesuada nunc. Proin tincidunt ante vel sem tempor, in dignissim ipsum tempor. ',
				picture: '/img/deck/terrasse.png',
				alternative: 'Img terrasse',
				caption: 'Terrasse trop soin'
			},
			{
				title: 'Restauration 2',
				hook: 'Cras eget eleifend ipsum. Vivamus sit amet tincidunt turpis, a dignissim tortor. Nullam eu magna convallis, finibus sapien a, malesuada nunc. Proin tincidunt ante vel sem tempor, in dignissim ipsum tempor. ',
				picture: '/img/deck/terrasse.png',
				alternative: 'Img terrasse',
				caption: 'Terrasse trop CARRE'
			}
		]
	},
	pictures: [
		{
			title: 'RDC',
			picture: '/img/youFirstGecina.png'
		},
		{
			title: 'RDC 2',
			picture: '/img/deck/plan.png'
		}
	],
	contacts: [
		{
			firstName: 'Julie',
			lastName: 'Forgeot',
			company: 'JLL',
			email: 'julie.forgeot@youfirst.co',
			phone: '0102030405'
		},
		{
			firstName: 'Julie',
			lastName: 'Forgeot',
			company: 'JLL',
			email: 'julie.forgeo2t@youfirst.co',
			phone: '0102030405'
		}
	]
}

export const INITIAL_STATE: DeckModel = {
	metas: {
		id: '', id_crm: '', selected: false
	},
	presentation: {
		backgroundPicture: '<img src="" alt="" />',
		name: '',
		baseline: '',
		address: '',
		postalCode: '',
		city: '',
		availableArea: '',
		divisibleFrom: '',
		indicativeAvailability: '',
		nature: natureEnum.commercial,
		customMessage: '',
		pictures: [],
		captions: [],
		text: ''
	},
	video: {
		video: ''
	},
	location: {
		hookLocation: '',
		map: {
			picture: '',
			caption: null
		},
		stops: [],
		title: '',
		hookNeighborhood: '',
		picture: ''
	},
	keyPoints: {
		text: '',
		picture: ''
	},
	spaces: {
		text: '',
		list: []
	},
	surfaces: [],
	plans: [],
	technical: {
		description: '',
		download: ''
	},
	csr: {
		description: '',
		labels: []
	},
	services: {
		text: '',
		list: []
	},
	pictures: [],
	contacts: []
}
