import React, { useContext, useEffect, useRef } from 'react'
import styles from '../../styles'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { IntlShape, useIntl } from 'react-intl'
import classNames from 'classnames'
import Link from '../../components/link/Link'
import { PageModel } from './types/footerModel'
import { useHistory } from 'react-router'
import links from '../../enums/linksEnum'
import { useDispatch, useSelector } from 'react-redux'
import errorCatcher from '../../utils/errorCatcher'
import { ClassValue } from 'classnames/types'
import { RootState } from '../../index'
import { saveFooter } from './reducer/actions'
import { getFooter } from './api/getFooter'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	footerContainer: {
		width: '100%',
		display: 'flex',
		backgroundColor: 'white',
		zIndex: 1,
		borderTop: `2px solid ${theme.palette.blue['30']}`
	},
	footerContainerLargeScreenStyle: {
		height: 100,
		paddingBottom: 70
	},
	footerContainerSmallScreenStyle: {
		paddingBottom: 100
	},
	logoContainerLargeScreen: {
		'& img': {
			width: 150,
			margin: '0 30px'
		}
	},
	logoContainerSmallScreen: {
		marginBottom: 20,
		paddingLeft: '8%',

		'& img': {
			marginTop: 15,
			width: 150
		}
	},
	optionContainer: {
		display: 'flex'
	},
	optionDivLargeScreen: {
		marginRight: 40
	},
	optionContainerSmallScreen: {
		justifyContent: 'space-around',
		paddingLeft: '8%',
		columnGap: '8%',
		columnCount: 2
	},
	optionContainerMobileScreen: {
		paddingLeft: '8%'
	},
	optionDivSmallScreen: {
		marginBottom: '2%',
		display: 'inline-block',
		width: '-webkit-fill-available'
	}

}))

const ID_FOOTER = 'get_footer'

const AppFooter: React.FC = () => {
	const classes = useStyle()
	const intl: IntlShape = useIntl()
	const oldLanguage = useRef(intl.locale)
	const history = useHistory()
	const footer: PageModel[] = useSelector((state: RootState) => state.footer)
	const dispatch = useDispatch()
	const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

	const footerContainerClass: ClassValue = classNames(
		classes.footerContainer,
		isTabletteScreen ? classes.alignCenter : classes.flexColumn,
		isLargeScreen ? classes.footerContainerLargeScreenStyle : classes.footerContainerSmallScreenStyle
	)

	const logoContainerClass: ClassValue = classNames(
		'col-xs-2 col-md-3 col-lg-2',
		isTabletteScreen ? classes.logoContainerLargeScreen : classes.logoContainerSmallScreen
	)

	const optionContainer = classNames(
		'col-xs-10 col-md-9 col-lg-10',
		{
			[classes.optionContainer]: isLargeScreen,
			[classes.optionContainerSmallScreen]: !isLargeScreen && isTabletteScreen,
			[classes.optionContainerMobileScreen]: !isTabletteScreen
		}
	)

	const optionDiv = classNames(
		isLargeScreen ? classes.optionDivLargeScreen : classes.optionDivSmallScreen
	)

	useEffect(() => {
		if (footer.length === 0 || oldLanguage.current !== intl.locale) {
			getFooter(intl.locale)
				.then(
					(data: any) => {
						dispatch(
							saveFooter(data)
						)
					}
				)
				.catch(
					(error: any) => dispatch(
						errorCatcher(error, ID_FOOTER)
					)
				)
				.finally(
					() => oldLanguage.current = intl.locale
				)
		}
	}, [dispatch, footer.length, intl.locale])

	const seeDetailsFooterPage = (id: string) => {
		redirectToWithQuery(history, `${links.footer}/${id}`)
	}

	return (
		<div className={footerContainerClass}>
			<div className={logoContainerClass}>
				<img
					src="/img/youFirstGecinaFooter.png"
					alt="Logo Youfirst Gecina Footer"
					id="logo YF Gecina"
				/>
			</div>

			<div className={optionContainer}>
				{
					footer.map(
						(pageFooter) => (
							<div
								className={optionDiv}
								key={pageFooter.id}
							>
								<Link
									color="colorBlue"
									textStyle="text4"
									hasChevron={false}
									onClick={() => seeDetailsFooterPage(pageFooter.id)}
								>
									{pageFooter.title}
								</Link>
							</div>
						)
					)
				}
			</div>
		</div>
	)
}

export default AppFooter
