import fetchFactory from '../../../utils/fetchFactory'
import { configEnum, responseTypes } from '../../../enums/fetchFactoryEnum'
import { PrintDeckModel } from '../types/printDeckModel'

export const fetchPrintDeck = (data: PrintDeckModel) => fetchFactory('/cms/fr/api/export/pdf',
	{
		method: configEnum.post,
		body: JSON.stringify(data)
	},
	responseTypes.blob
)