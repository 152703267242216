import React, { useContext, useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { SearchFormModel } from '../types/searchFormModel'
import { loadDates } from '../utils/loadDates'
import { FormattedMessage, useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import SelectSurfaceForm from '../../../components/fields/SelectSurfaceForm'
import Button from '../../../components/button/Button'
import classNames from 'classnames'
import SearchBoxCity from './SearchboxCity'
import { useHistory } from 'react-router'
import links from '../../../enums/linksEnum'
import { DISPONIBILITY_NAME, MAX, MIN, SURFACE_NAME } from '../../offers/utils/commonConst'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { formatDate } from '../../../utils/formatter/formatDate'
import { checkSemester } from '../utils/checkSemester'
import { MenuItem } from '@material-ui/core'
import styles from '../../../styles'
import FormSelectField, { RenderValue } from '../../../components/fields/FormSelectField'
import { animateScroll as scroll } from 'react-scroll'
import { localeEnum } from '../../../components/langContext/enum/languagesEnum'
import { redirectToWithQuery } from '../../../utils/formatter/formatRouter'

const useStyles = makeStyles(
	(theme) => (
		{
			formStyle: {
				marginBottom: 20
			},
			surfaceFormContainer: {
				padding: '20px 40px 30px 25px'
			},
			hideElement: {
				display: 'none'
			},
			submitStyle: {
				marginTop: 30
			},
			marginSelectSmallScreenStyle: {
				marginBottom: 15
			},
			searchBoxStyle: {
				padding: 0
			},
			menuItem: {
				...styles(theme).text4,
				color: theme.palette.blue.main
			}
		}
	)
)

const SearchForm = () => {
	const classes = useStyles()
	const intl = useIntl()
	const history = useHistory()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const disponibilities: Date[] = useMemo(
		() => (
			loadDates()
		), []
	)

	const initialValues: SearchFormModel = useMemo(
		() => (
			{
				cities: [],
				disponibility: [],
				surface: {
					min: MIN,
					max: MIN
				}
			}
		), []
	)

	const onSubmit = (values: SearchFormModel) => {
		scroll.scrollToTop()
		redirectToWithQuery(history, links.research, `${history.location.search}&cities=${JSON.stringify(values.cities)}&disponibility=${values.disponibility}&surface=${values.surface.min},${values.surface.max}`)
	}

	const validate = (values: SearchFormModel) => {
		const errors: any = {}

		if (!values.disponibility || (values.disponibility && values.disponibility.length === 0)) {
			errors[DISPONIBILITY_NAME] = intl.formatMessage(
				{
					id: 'global.errors.required',
					defaultMessage: 'Requis*',
					description: 'Required field'
				}
			)
		}
		if (values.surface.max === 0) {
			errors.surface = intl.formatMessage(
				{
					id: 'global.errors.required',
					defaultMessage: 'Requis*',
					description: 'Required field'
				}
			)
		}
		if (values.surface.min > values.surface.max) {
			errors.surface = {
				min: intl.formatMessage(
					{
						id: 'offers.research.errors.minGreaterThanMax',
						defaultMessage: 'Le minimum ne peut pas être supérieur au maximum',
						description: 'Min / Max error'
					}
				)
			}
		}

		return errors
	}

	return (
		<Form
			mutators={{
				...arrayMutators
			}}
			initialValues={initialValues}
			validate={validate}
			onSubmit={onSubmit}
			render={
				(
					{
						handleSubmit
					}
				) => (
					<form
						onSubmit={handleSubmit}
						className={classes.formStyle}
					>
						<div className="row">
							<div
								className={
									classNames(
										'col-xs-12',
										classes.searchBoxStyle
									)
								}
							>
								<SearchBoxCity />
							</div>
						</div>

						<div className="row">
							<div
								className={
									classNames(
										'col-xs-12 col-md-6',
										classes.marginSelectSmallScreenStyle
									)
								}
							>
								<Field
									name={DISPONIBILITY_NAME}
									component={FormSelectField}
									id="disponibility"
									renderValue={() => RenderValue(DISPONIBILITY_NAME)}
									multiple
								>
									<MenuItem value="">
                                        <span className={classes.menuItem}>
                                            <FormattedMessage
	                                            id="offers.research.selects.disponibilityPlaceholder"
	                                            defaultMessage="Date de disponibilité"
	                                            description="Placeholder item"
                                            />
                                        </span>
									</MenuItem>

									{
										disponibilities.map(
											(dateDisponibility) => (
												<MenuItem
													key={dateDisponibility.toLocaleString()}
													value={formatDate(dateDisponibility, localeEnum.fr)}
												>
                                                    <span className={classes.menuItem}>
                                                        {
	                                                        checkSemester(new Date(dateDisponibility), intl)
                                                        }
                                                    </span>
												</MenuItem>
											)
										)
									}
								</Field>
							</div>

							<div className="col-xs-12 col-md-6">
								<Field
									name={SURFACE_NAME}
									component={SelectSurfaceForm}
									id="surface"
									min={MIN}
									max={MAX}
								/>
							</div>
						</div>

						<div className="row center-xs">
							<div
								className={
									classNames(
										'col-xs-12',
										classes.submitStyle
									)
								}
							>
								<Button
									background="backgroundAccessibility"
									color="colorWhite"
									textStyle={isLargeScreen ? 'text7' : 'text8'}
									type="submit"
									size="mediumButton"
								>
									<FormattedMessage
										id="offers.research.submit"
										defaultMessage="Voir les offres"
										description="Submit txt"
									/>
								</Button>
							</div>
						</div>
					</form>
				)
			}
		/>
	)
}

export default SearchForm
