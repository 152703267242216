import { httpStatus } from './httpResponseEnum'

const errorsTypeEnum = {
	unauthorized: {
		status: httpStatus.unauthorized,
		text: 'errors.unauthorized'
	},
	internal: {
		status: httpStatus.internal,
		text: 'errors.internal'
	},
	notFound: {
		status: httpStatus.notFound,
		text: 'errors.unauthorized'
	}
}

export default errorsTypeEnum

export interface ErrorsTypeModel {
	status: number,
	text: string
}