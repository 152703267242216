import snackBarReducer from '../components/snackBar/reducer/reducers'
import userReducer from '../modules/profile/reducer/reducers'
import selectionReducer, { isCardHover } from '../modules/selection/reducer/reducers'
import modalReducer from '../components/dialog/reducer/reducers'
import footerReducer from '../modules/footer/reducer/reducers'
import promotedReducer from '../modules/homepage/reducer/reducers'
import offersReducer from '../modules/offers/reducer/reducers'
import favoritesReducer from '../modules/favorites/reducer/reducers'
import tokenReducer from '../modules/checkToken/reducer/reducers'

export default {
	snackBar: snackBarReducer,
	user: userReducer,
	selection: selectionReducer,
	modal: modalReducer,
	isCardHover: isCardHover,
	footer: footerReducer,
	promoted: promotedReducer,
	offers: offersReducer,
	favorites: favoritesReducer,
	token: tokenReducer
}
