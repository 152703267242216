import React, { useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../styles'
import { FieldRenderProps } from 'react-final-form'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { FormattedMessageModel } from '../../types/fields/fieldsModel'
import { useIntl } from 'react-intl'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import InputAdornment from '@material-ui/core/InputAdornment'

const useStyles = makeStyles(
	(theme) => (
		{
			...styles(theme),
			textFieldStyle: {
				width: '100%',
				padding: '8px 8px 8px 0px',
				boxSizing: 'border-box'
			},
			textFieldLargeScreenStyle: {
				'& .MuiInput-formControl': {
					'& .MuiInput-input': {
						fontSize: 20,
						fontWeight: 500,
						color: theme.palette.blue.main
					}
				}
			},
			textFieldSmallScreenStyle: {
				'& .MuiInput-formControl': {
					'& .MuiInput-input': {
						fontSize: 14,
						fontWeight: 500,
						color: theme.palette.blue.main
					}
				}
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: '10px'
			},
			errorColor: {
				color: '#B92D00 !important'
			},
			errorBorder: {
				border: '1px solid #B92D00'
			}
		}
	)
)

interface AcceptingProps {
	id: string,
	label?: string,
	placeholder?: FormattedMessageModel,
	multiline?: boolean
	maxLength?: number,
	type?: string,
	disablePaste?: boolean,
	suffix?: string
}

type FormTextFieldProps =
	FieldRenderProps<string>
	& AcceptingProps

const FormTextField: React.FC<FormTextFieldProps> = (
	{
		input,
		meta: {
			touched,
			error
		},
		id,
		label,
		placeholder,
		multiline,
		maxLength,
		type = 'text',
		disablePaste = false,
		suffix
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const labelClass: ClassValue = classNames(
		classes.text5,
		classes.colorBlue,
		{
			[classes.errorColor]: touched && error
		}
	)

	const textFieldClass: ClassValue = classNames(
		classes.textFieldStyle,
		{
			[classes.textFieldLargeScreenStyle]: isLargeScreen,
			[classes.textFieldSmallScreenStyle]: !isLargeScreen,
			[classes.errorBorder]: touched && error
		}
	)

	return (
		<>
			<span
				className={labelClass}
			>
				{label}
			</span>

			<TextField
				id={id}
				color="primary"
				className={textFieldClass}
				multiline={multiline}
				inputProps={{ maxLength: maxLength, min: 0 }}
				placeholder={placeholder ?
					intl.formatMessage(
						{
							id: placeholder.id,
							defaultMessage: placeholder.defaultMessage,
							description: placeholder.description
						}
					) : ''
				}
				onPaste={(e) => disablePaste && e.preventDefault()}
				InputProps={
					suffix ? {
						endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>
					} : undefined
				}
				type={type}
				{...input}
			/>

			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default FormTextField
