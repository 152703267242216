import React, { useContext, useState } from 'react'
import { DeckLocation, StopsModel } from '../types/deckModel'
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import styles from '../../../styles'
import { objectGetKeys } from '../../../utils/ieCompatibility'
import { stopsEnum } from '../enum/stopsEnum'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import SvgWalker from '../../../icons/Walker'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import parse from 'html-react-parser'
import GoogleMapReact from 'google-map-react'
import SvgLocation from '../../../icons/Location'
import { API_KEY } from '../../../components/offersDisplay/components/map/utils/globalConst'
import { useSelector } from 'react-redux'
import { RootState } from '../../../index'
import TransitComponent from '../../../components/offersDisplay/components/map/component/TransitComponent'
import { POIs } from '../../../components/offersDisplay/components/map/enum/listPOI'
import { POIModel } from '../../../components/offersDisplay/components/map/types/POIModel'

const useStyles = makeStyles(
    (theme) => (
        {
            locationContainerStyle: {
                '& .location_header': {
                    margin: 'auto',

                    '& h1, & p': {
                        margin: 0,
                        color: theme.palette.blue.main
                    },
                    '& p': {
                        ...styles(theme).text20
                    }
                },
                '& .location_map': {
                    margin: 'auto',
                    position: 'relative',

                    '& .map': {
                        height: '60vh'
                    }
                },
                '& .location_stops': {
                    margin: 'auto',

                    '& .row': {
                        '& h2': {
                            margin: 0,
                            marginBottom: 30,
                            color: theme.palette.accessibility.main
                        },
                        '& h3': {
                            margin: 0,
                            marginBottom: 10,
                            color: theme.palette.blue.main,
                            ...styles(theme).title4
                        },
                        '& .stopDetails': {
                            '& .stopName': {
                                marginBottom: 5,

                                '& p': {
                                    margin: 0,
                                    color: theme.palette.blue.main,
                                    ...styles(theme).text20
                                }
                            },
                            '& .stopDuration': {
                                '& p': {
                                    margin: 0,
                                    color: theme.palette.blue['70'],
                                    ...styles(theme).text15
                                }
                            }
                        }
                    },
                    '& .busList': {
                        marginTop: 45,

                        '& .col-xs-10': {
                            '& > div': {
                                display: 'flex',
                                flexWrap: 'wrap',

                                '& img': {
                                    height: 25,
                                    marginRight: 10,
                                    marginBottom: 10
                                }
                            }
                        }
                    },
                    '& .velibList': {
                        marginTop: 20,

                        '& img': {
                            width: '100%',
                            maxWidth: 50
                        },
                        '& .col-xs-10': {
                            '& p': {
                                margin: 0,
                                ...styles(theme).text2,
                                color: theme.palette.blue.main
                            }
                        }
                    }
                },
                '& .location_business': {
                    margin: 'auto',

                    '& .row': {
                        '& h1': {
                            margin: 0,
                            color: theme.palette.blue.main
                        },
                        '& p': {
                            margin: 0,
                            color: theme.palette.blue.main,
                            ...styles(theme).text20
                        },
                        '& img': {
                            width: '100%'
                        }
                    }
                }
            },
            locationContainerLargeScreenStyle: {
                marginTop: 220,

                '& .location_header': {
                    marginBottom: 50,
                    maxWidth: 1100,

                    '& h1': {
                        ...styles(theme).title1
                    }
                },
                '& .location_map': {
                    maxWidth: 1240,
                    marginBottom: 50
                },
                '& .location_stops': {
                    maxWidth: 1100,

                    '& .row': {
                        '& h2': {
                            ...styles(theme).title3
                        },
                        '& .stopDetails:not(:last-child)': {
                            marginBottom: 24
                        }
                    }
                },
                '& .location_business': {
                    maxWidth: 1100,
                    marginTop: 50,

                    '& .row': {
                        '& h1': {
                            ...styles(theme).text21
                        }
                    },
                    '& .row:first-child': {
                        marginBottom: 41
                    }
                }
            },
            locationContainerSmallScreenStyle: {
                marginTop: 100,

                '& .location_header': {
                    marginBottom: 30,

                    '& h1': {
                        ...styles(theme).title2
                    },
                    '& .col-xs-12:first-child': {
                        marginBottom: 10
                    }
                },
                '& .location_map': {
                    marginBottom: 25
                },
                '& .location_stops': {
                    '& .row': {
                        '& h2': {
                            ...styles(theme).title7
                        },
                        '& .stopDetails:not(:last-child)': {
                            marginBottom: 15
                        }
                    }
                },
                '& .location_business': {
                    marginTop: 60,

                    '& .row': {
                        '& h1': {
                            ...styles(theme).text0
                        }
                    },
                    '& .row:first-child': {
                        marginBottom: 20
                    }
                }
            },
            stopsStyle: {
                display: 'flex',
                flexWrap: 'wrap',

                '& div[class^="col-xs"]': {
                    padding: 0
                },
                '& .col-xs-3': {
                    paddingRight: '0.5rem !important'
                },
                '& .iconContainer': {
                    width: 30,
                    paddingRight: '0.5rem !important'
                }
            },
            stopsLargeScreenStyle: {
                '& .stopName': {
                    '& img': {
                        height: 30,
                        maxWidth: '100%',
                        verticalAlign: 'bottom'
                    }
                }
            },
            stopsSmallScreenStyle: {
                '&:not(.col-xs-6)': {
                    marginTop: 15
                },

                '& .stopName': {
                    '& img': {
                        width: '100%',
                        maxWidth: 30,
                        verticalAlign: 'top'
                    }
                }
            },
            svgStyle: {
                transform: 'translate(-50%, -50%)'
            },
            pointsComponent: {
                transform: 'translate(-50%, -50%)',
                width: 10,
                height: 10,
                borderRadius: '100%',
                background: theme.palette.blue.main
            },
            poiStyle: {
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                zIndex: -1
            },
            mapCaption: {
                display: 'flex',
                alignItems: 'flex-end',
                marginTop: 15,
                marginLeft: 5,

                '& > div': {
                    marginRight: 10
                }
            },
            fullWidth: {
                width: '100%'
            }
        }
    )
)

interface AcceptingProps {
    location: DeckLocation
}

interface PinModel {
    lat: number,
    lng: number
}

type DeckLocationProps = AcceptingProps

const DeckLocationPage: React.FC<DeckLocationProps> = (
    {
        location
    }
) => {
    const classes = useStyles()
    const intl = useIntl()
    const theme = useTheme()
    const offers = useSelector((state: RootState) => state.offers)
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)
    const [zoomLvl, setZoomLvl] = useState<number>(10)
    const [hasTransit, setHasTransit] = useState<boolean>(false)


    const groupedStops = location.stops.reduce(
        (acc: any[], obj) => {
            const key = obj['type']

            if (!acc[key as any]) {
                acc[key as any] = []
            }
            acc[key as any].push(obj)
            return acc
        }, []
    )

    const locationContainerClass: ClassValue = classNames(
        classes.locationContainerStyle,
        isLargeScreen ? classes.locationContainerLargeScreenStyle : classes.locationContainerSmallScreenStyle
    )

    const getStopName = (stop: string) => {
        return intl.formatMessage(
            {
                id: `deck.location.${stop}`,
                defaultMessage: 'Métros',
                description: 'Transport name'
            }
        )
    }

    const PinComponent = (pin: PinModel) => (
        <SvgLocation
            color={theme.palette.green.main}
            secondColor="#FFFFFF"
            height={40}
            width={30}
            className={classes.svgStyle}
        />
    )

    const PointsComponent = (point: PinModel) => (
        <div className={classes.pointsComponent}></div>
    )

    const POIComponent = (poi: POIModel) => (
        <div className={classes.poiStyle}>
            {poi.icon}
        </div>
    )

    return (
        <div className={locationContainerClass}>
            <div className="location_header row bottom-xs">
                <div className="col-xs-12 col-md-6">
                    <h1>
                        <FormattedMessage
                            id="deck.location.title"
                            defaultMessage="Localisation et transports"
                            description="Location title"
                        />
                    </h1>
                </div>

                <div className="col-xs-12 col-md-6">
                    <p>{parse(location.hookLocation)}</p>
                </div>
            </div>

            <div className="location_map row">
                {
                    'longitude' in location.map ? (
                        <>
                            <div className="col-xs-12 map">
                                <TransitComponent
                                    hasTransit={hasTransit}
                                    setHasTransit={setHasTransit}
                                />

                                <GoogleMapReact
                                    bootstrapURLKeys={
                                        {
                                            key: API_KEY
                                        }
                                    }
                                    defaultCenter={
                                        {
                                            lat: Number(location.map.latitude),
                                            lng: Number(location.map.longitude)
                                        }
                                    }
                                    layerTypes={hasTransit ? ['TransitLayer'] : []}
                                    defaultZoom={13}
                                    onZoomAnimationEnd={(zoomLvl: number) => setZoomLvl(zoomLvl)}
                                    yesIWantToUseGoogleMapApiInternals
                                    options={
                                        {
                                            styles: [
                                                {
                                                    featureType: 'poi',
                                                    elementType: 'labels',
                                                    stylers: [{ 'visibility': 'off' }]
                                                }
                                            ]
                                        }
                                    }
                                >
                                    <PinComponent
                                        lat={Number(location.map.latitude)}
                                        lng={Number(location.map.longitude)}
                                    />

                                    {
                                        offers.offers
                                            .filter((offer) => 'latitude' in location.map && location.map.latitude !== offer.latitude && location.map.longitude !== offer.longitude)
                                            .map(
                                                (item) => (
                                                    <PointsComponent
                                                        key={item.id}
                                                        lat={Number(item.latitude)}
                                                        lng={Number(item.longitude)}
                                                    />
                                                )
                                            )
                                    }

                                    {
                                        zoomLvl >= 14 && POIs.map(
                                            (poi) => (
                                                <POIComponent
                                                    key={poi.name}
                                                    {...poi}
                                                />
                                            )
                                        )
                                    }
                                </GoogleMapReact>
                            </div>

                            <div
                                className={
                                    classNames(
                                        'col-xs-12',
                                        classes.mapCaption
                                    )
                                }
                            >
                                <div className={classes.pointsComponent}></div>

                                <FormattedMessage
                                    id="deck.location.caption"
                                    defaultMessage="Immeubles du patrimoine Gecina"
                                    description="Map caption"
                                />
                            </div>
                        </>
                    ) : (
                        <div className="col-xs-12">
                            <div className="imgContainer">
                                <img src={location.map.picture} alt="Location map"/>
                                {
                                    location.map.caption && (
                                        <span>{location.map.caption}</span>
                                    )
                                }
                            </div>
                        </div>
                    )
                }
            </div>

            {
                location.stops.length > 0 && (
                    <div className="location_stops">
                        <div className="row">
                            <div className="col-xs-12">
                                <h2>
                                    <FormattedMessage
                                        id="deck.location.stop"
                                        defaultMessage="Accès en transports en commun"
                                        description="Stops message"
                                    />
                                </h2>
                            </div>
                        </div>

                        <div className="row">
                            {
                                objectGetKeys(stopsEnum).map(
                                    (stopEnum: string) => (
                                        stopEnum in groupedStops && (
                                            <div
                                                className={classNames(
                                                    classes.stopsStyle,
                                                    isTabletteScreen ? classes.stopsLargeScreenStyle : classes.stopsSmallScreenStyle,
                                                    groupedStops[stopEnum as keyof typeof groupedStops].length > 5 ? 'col-xs-12' : 'col-xs-12 col-sm-6 col-md-3'
                                                )}
                                                style={{
                                                 flexDirection: groupedStops[stopEnum as keyof typeof groupedStops].length > 5 ? 'row' : 'column'
                                                }}
                                                key={stopEnum}
                                            >
                                                <div className="row">
                                                    <div className="col-xs-12">
                                                        <h3
                                                            style={{
                                                            marginRight: groupedStops[stopEnum as keyof typeof groupedStops].length > 5 ? 10 : 0
                                                        }}
                                                        >
                                                            {
                                                                stopEnum === stopsEnum.metro || stopEnum === stopsEnum.tramway ? (
                                                                    <FormattedPlural
                                                                        value={groupedStops[stopEnum as any].length}
                                                                        one={getStopName(stopEnum)}
                                                                        other={`${getStopName(stopEnum)}s`}
                                                                    />
                                                                ) : (
                                                                    <FormattedMessage
                                                                        id={`deck.location.${stopEnum}`}
                                                                        defaultMessage="Métro"
                                                                        description="Transport name"
                                                                    />
                                                                )
                                                            }
                                                        </h3>
                                                    </div>
                                                </div>

                                                {
                                                    groupedStops[stopEnum as keyof typeof groupedStops].length > 5 && (
                                                        <div className={classes.fullWidth}/>
                                                    )
                                                }

                                                {
                                                    groupedStops[stopEnum as any]
                                                        .sort((a: StopsModel, b: StopsModel) => {
                                                                if (a.duration) {
                                                                    return Number(a.duration) - Number(b.duration)
                                                                } else {
                                                                    if (isNaN(Number(a.line)) && isNaN(Number(b.line))) {
                                                                        if (a.line < b.line) {
                                                                            return -1
                                                                        }
                                                                        if (a.line > b.line) {
                                                                            return 1
                                                                        }
                                                                        return 0
                                                                    } else {
                                                                        return Number(a.line) - Number(b.line)
                                                                    }
                                                                }
                                                            }
                                                        )
                                                        .map(
                                                            (stop: StopsModel) => (
                                                                <div
                                                                    key={stop.name ? stop.name : stop.line}
                                                                    className="stopDetails"
                                                                >
                                                                    <div className="row middle-xs">
                                                                        <div className="col-xs-12">
                                                                            <div className="stopName row middle-xs">
                                                                                <div className="center-xs iconContainer">
                                                                                    <img src={stop.icon}
                                                                                         alt="Stop icon"/>
                                                                                </div>

                                                                                {
                                                                                    stop.name && (
                                                                                        <div>
                                                                                            <p>{stop.name}</p>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        </div>


                                                                        {
                                                                            stop.duration && (
                                                                                <div className="col-xs-12">
                                                                                    <div
                                                                                        className="stopDuration row middle-xs"
                                                                                    >
                                                                                        <div
                                                                                            className="center-xs iconContainer">
                                                                                            <SvgWalker
                                                                                                color={theme.palette.blue.main}
                                                                                                height={24}
                                                                                                width={24}
                                                                                            />
                                                                                        </div>

                                                                                        <div>
                                                                                            <p>
                                                                                                {stop.duration}

                                                                                                <FormattedPlural
                                                                                                    value={stop.duration}
                                                                                                    zero=" minute"
                                                                                                    one=" minute"
                                                                                                    other=" minutes"
                                                                                                />
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        )
                                                }
                                            </div>
                                        )
                                    )
                                )
                            }
                        </div>

                        {
                            groupedStops['bus' as unknown as number] && groupedStops['bus' as unknown as number].length > 0 && (
                                <div className="row busList middle-xs">
                                    <div className="col-xs-2 col-sm-1">
                                        <h3>
                                            <FormattedMessage
                                                id="deck.location.bus"
                                                defaultMessage="Bus"
                                                description="Bus title"
                                            />
                                        </h3>
                                    </div>

                                    <div className="col-xs-10">
                                        <div>
                                            {
                                                groupedStops['bus' as unknown as number]
                                                    .sort(
                                                        (a: StopsModel, b: StopsModel) => {
                                                            if (isNaN(Number(a.line)) && isNaN(Number(b.line))) {
                                                                if (a.line < b.line) {
                                                                    return -1
                                                                }
                                                                if (a.line > b.line) {
                                                                    return 1
                                                                }
                                                                return 0
                                                            } else {
                                                                return Number(a.line) - Number(b.line)
                                                            }
                                                        }
                                                    )
                                                    .map(
                                                        (stop: StopsModel) => (
                                                            <img
                                                                key={stop.line}
                                                                src={stop.icon}
                                                                alt="Stop icon"
                                                            />
                                                        )
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }

                        {
                            location.velib && location.velib.length > 0 && (
                                <div className="row velibList middle-xs">
                                    <div className="col-xs-2 col-sm-1">
                                        <img src="/img/deck/velib.png" alt="Velib icon"/>
                                    </div>

                                    <div className="col-xs-10">
                                        <p>{location.velib}</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

            {
                location.title && location.hookNeighborhood && location.picture && (
                    <div className="location_business">
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <h1>{location.title}</h1>
                            </div>

                            <div className="col-xs-12 col-md-6">
                                <p>{parse(location.hookNeighborhood)}</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12">
                                <div className="imgContainer">
                                    <img src={location.picture} alt="Neighborhood"/>
                                    {
                                        location.caption && (
                                            <span>{location.caption}</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default DeckLocationPage
