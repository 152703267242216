export const polygons = [
    [
        {
            lng: 2.3257616254697,
            lat: 48.869546133476
        },
        {
            lng: 2.3278733621861,
            lat: 48.869861088958
        },
        {
            lng: 2.3508413205563,
            lat: 48.863344411573
        },
        {
            lng: 2.3500912644194,
            lat: 48.861951749823
        },
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        },
        {
            lng: 2.3328469000671,
            lat: 48.859300907461
        },
        {
            lng: 2.3207773515807,
            lat: 48.863075035941
        },
        {
            lng: 2.3257616254697,
            lat: 48.869546133476
        }
    ],
    [
        {
            lng: 2.3508413205563,
            lat: 48.863344411573
        },
        {
            lng: 2.3278733621861,
            lat: 48.869861088958
        },
        {
            lng: 2.3399298105677,
            lat: 48.871937310519
        },
        {
            lng: 2.3478317710849,
            lat: 48.870624420335
        },
        {
            lng: 2.3541114926597,
            lat: 48.869275285895
        },
        {
            lng: 2.3508413205563,
            lat: 48.863344411573
        }
    ],
    [
        {
            lng: 2.3500912644194,
            lat: 48.861951749823
        },
        {
            lng: 2.3508413205563,
            lat: 48.863344411573
        },
        {
            lng: 2.3541114926597,
            lat: 48.869275285895
        },
        {
            lng: 2.3638513390126,
            lat: 48.867431638281
        },
        {
            lng: 2.3684085721728,
            lat: 48.855737300049
        },
        {
            lng: 2.3500912644194,
            lat: 48.861951749823
        }
    ],
    [
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        },
        {
            lng: 2.3500912644194,
            lat: 48.861951749823
        },
        {
            lng: 2.3684085721728,
            lat: 48.855737300049
        },
        {
            lng: 2.3690196371168,
            lat: 48.853221440745
        },
        {
            lng: 2.3643180356191,
            lat: 48.846167191329
        },
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        }
    ],
    [
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        },
        {
            lng: 2.3643180356191,
            lat: 48.846167191329
        },
        {
            lng: 2.365950820881,
            lat: 48.844908659278
        },
        {
            lng: 2.3613870338288,
            lat: 48.839822776185
        },
        {
            lng: 2.3517180689518,
            lat: 48.836813651152
        },
        {
            lng: 2.3420384635948,
            lat: 48.838296917933
        },
        {
            lng: 2.3365927071777,
            lat: 48.839690537143
        },
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        }
    ],
    [
        {
            lng: 2.3166281602877,
            lat: 48.846752387477
        },
        {
            lng: 2.3282850183008,
            lat: 48.851787363918
        },
        {
            lng: 2.3328469000671,
            lat: 48.859300907461
        },
        {
            lng: 2.3445592260856,
            lat: 48.85398930556
        },
        {
            lng: 2.3365927071777,
            lat: 48.839690537143
        },
        {
            lng: 2.3246635446251,
            lat: 48.843518038723
        },
        {
            lng: 2.3166281602877,
            lat: 48.846752387477
        }
    ],
    [
        {
            lng: 2.3207773515807,
            lat: 48.863075035941
        },
        {
            lng: 2.3328469000671,
            lat: 48.859300907461
        },
        {
            lng: 2.3282850183008,
            lat: 48.851787363918
        },
        {
            lng: 2.3166281602877,
            lat: 48.846752387477
        },
        {
            lng: 2.3021915203793,
            lat: 48.850344748998
        },
        {
            lng: 2.2897782765961,
            lat: 48.858123113769
        },
        {
            lng: 2.3015562721716,
            lat: 48.863475234966
        },
        {
            lng: 2.3207773515807,
            lat: 48.863075035941
        }
    ],
    [
        {
            lng: 2.3271206970663,
            lat: 48.883485416617
        },
        {
            lng: 2.3257616254697,
            lat: 48.869546133476
        },
        {
            lng: 2.3207773515807,
            lat: 48.863075035941
        },
        {
            lng: 2.3015562721716,
            lat: 48.863475234966
        },
        {
            lng: 2.2951453922338,
            lat: 48.873861979677
        },
        {
            lng: 2.2981403262665,
            lat: 48.87804262556
        },
        {
            lng: 2.3271206970663,
            lat: 48.883485416617
        }
    ],
    [
        {
            lng: 2.3257616254697,
            lat: 48.869546133476
        },
        {
            lng: 2.3271206970663,
            lat: 48.883485416617
        },
        {
            lng: 2.3379555061118,
            lat: 48.882249883375
        },
        {
            lng: 2.3495508401232,
            lat: 48.883620167912
        },
        {
            lng: 2.3478317710849,
            lat: 48.870624420335
        },
        {
            lng: 2.3399298105677,
            lat: 48.871937310519
        },
        {
            lng: 2.3278733621861,
            lat: 48.869861088958
        },
        {
            lng: 2.3257616254697,
            lat: 48.869546133476
        }
    ],
    [
        {
            lng: 2.3646776664012,
            lat: 48.884291351874
        },
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        },
        {
            lng: 2.3638513390126,
            lat: 48.867431638281
        },
        {
            lng: 2.3541114926597,
            lat: 48.869275285895
        },
        {
            lng: 2.3478317710849,
            lat: 48.870624420335
        },
        {
            lng: 2.3495508401232,
            lat: 48.883620167912
        },
        {
            lng: 2.3646776664012,
            lat: 48.884291351874
        }
    ],
    [
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        },
        {
            lng: 2.3943004420294,
            lat: 48.856491711482
        },
        {
            lng: 2.3991184448726,
            lat: 48.848094484736
        },
        {
            lng: 2.3690196371168,
            lat: 48.853221440745
        },
        {
            lng: 2.3684085721728,
            lat: 48.855737300049
        },
        {
            lng: 2.3638513390126,
            lat: 48.867431638281
        },
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        }
    ],
    [
        {
            lng: 2.365950820881,
            lat: 48.844908659278
        },
        {
            lng: 2.3643180356191,
            lat: 48.846167191329
        },
        {
            lng: 2.3690196371168,
            lat: 48.853221440745
        },
        {
            lng: 2.3991184448726,
            lat: 48.848094484736
        },
        {
            lng: 2.4159711107696,
            lat: 48.846622956277
        },
        {
            lng: 2.4122456125626,
            lat: 48.834538914673
        },
        {
            lng: 2.4221386362435,
            lat: 48.835797660955
        },
        {
            lng: 2.4275751567613,
            lat: 48.841570705794
        },
        {
            lng: 2.4478479965512,
            lat: 48.844804739969
        },
        {
            lng: 2.4634383121686,
            lat: 48.842089485269
        },
        {
            lng: 2.4672474618601,
            lat: 48.839072987582
        },
        {
            lng: 2.465730572119,
            lat: 48.826226047985
        },
        {
            lng: 2.4612052142523,
            lat: 48.818355149154
        },
        {
            lng: 2.4384475102742,
            lat: 48.818232447877
        },
        {
            lng: 2.4199625195411,
            lat: 48.824080072246
        },
        {
            lng: 2.406031823401,
            lat: 48.827615470779
        },
        {
            lng: 2.3900720145113,
            lat: 48.82569232682
        },
        {
            lng: 2.365950820881,
            lat: 48.844908659278
        }
    ],
    [
        {
            lng: 2.365950820881,
            lat: 48.844908659278
        },
        {
            lng: 2.3900720145113,
            lat: 48.82569232682
        },
        {
            lng: 2.3642042811729,
            lat: 48.816398323996
        },
        {
            lng: 2.3564647458137,
            lat: 48.815954893718
        },
        {
            lng: 2.3439025047441,
            lat: 48.815767001297
        },
        {
            lng: 2.3414937722336,
            lat: 48.828568124075
        },
        {
            lng: 2.3420384635948,
            lat: 48.838296917933
        },
        {
            lng: 2.3517180689518,
            lat: 48.836813651152
        },
        {
            lng: 2.3613870338288,
            lat: 48.839822776185
        },
        {
            lng: 2.365950820881,
            lat: 48.844908659278
        }
    ],
    [
        {
            lng: 2.314145699673,
            lat: 48.822290535442
        },
        {
            lng: 2.3013154184885,
            lat: 48.825125760842
        },
        {
            lng: 2.3246635446251,
            lat: 48.843518038723
        },
        {
            lng: 2.3365927071777,
            lat: 48.839690537143
        },
        {
            lng: 2.3420384635948,
            lat: 48.838296917933
        },
        {
            lng: 2.3414937722336,
            lat: 48.828568124075
        },
        {
            lng: 2.3439025047441,
            lat: 48.815767001297
        },
        {
            lng: 2.3318980606376,
            lat: 48.817010929642
        },
        {
            lng: 2.314145699673,
            lat: 48.822290535442
        }
    ],
    [
        {
            lng: 2.2897782765961,
            lat: 48.858123113769
        },
        {
            lng: 2.3021915203793,
            lat: 48.850344748998
        },
        {
            lng: 2.3166281602877,
            lat: 48.846752387477
        },
        {
            lng: 2.3246635446251,
            lat: 48.843518038723
        },
        {
            lng: 2.3013154184885,
            lat: 48.825125760842
        },
        {
            lng: 2.2893937776751,
            lat: 48.828352646104
        },
        {
            lng: 2.2790519306533,
            lat: 48.832489952145
        },
        {
            lng: 2.2727931901868,
            lat: 48.827920084226
        },
        {
            lng: 2.2627915245265,
            lat: 48.833923373524
        },
        {
            lng: 2.2724846953815,
            lat: 48.843681568601
        },
        {
            lng: 2.2897782765961,
            lat: 48.858123113769
        }
    ],
    [
        {
            lng: 2.225670292257,
            lat: 48.859404562396
        },
        {
            lng: 2.2317363597469,
            lat: 48.86906858161
        },
        {
            lng: 2.2457037884056,
            lat: 48.876459232476
        },
        {
            lng: 2.2548171406686,
            lat: 48.874080881369
        },
        {
            lng: 2.2584671711142,
            lat: 48.880387263086
        },
        {
            lng: 2.2799493325675,
            lat: 48.878573123982
        },
        {
            lng: 2.2951453922338,
            lat: 48.873861979677
        },
        {
            lng: 2.3015562721716,
            lat: 48.863475234966
        },
        {
            lng: 2.2897782765961,
            lat: 48.858123113769
        },
        {
            lng: 2.2724846953815,
            lat: 48.843681568601
        },
        {
            lng: 2.2627915245265,
            lat: 48.833923373524
        },
        {
            lng: 2.2551442384175,
            lat: 48.834809549369
        },
        {
            lng: 2.2506124417162,
            lat: 48.845554851211
        },
        {
            lng: 2.2242191058804,
            lat: 48.853516917557
        },
        {
            lng: 2.225670292257,
            lat: 48.859404562396
        }
    ],
    [
        {
            lng: 2.2844595717547,
            lat: 48.885639278202
        },
        {
            lng: 2.3037743623516,
            lat: 48.894153778949
        },
        {
            lng: 2.3198871747441,
            lat: 48.90045978209
        },
        {
            lng: 2.3299805220467,
            lat: 48.901163282127
        },
        {
            lng: 2.3260974369951,
            lat: 48.889111262565
        },
        {
            lng: 2.3271206970663,
            lat: 48.883485416617
        },
        {
            lng: 2.2981403262665,
            lat: 48.87804262556
        },
        {
            lng: 2.2951453922338,
            lat: 48.873861979677
        },
        {
            lng: 2.2799493325675,
            lat: 48.878573123982
        },
        {
            lng: 2.2844595717547,
            lat: 48.885639278202
        }
    ],
    [
        {
            lng: 2.3646776664012,
            lat: 48.884291351874
        },
        {
            lng: 2.3495508401232,
            lat: 48.883620167912
        },
        {
            lng: 2.3379555061118,
            lat: 48.882249883375
        },
        {
            lng: 2.3271206970663,
            lat: 48.883485416617
        },
        {
            lng: 2.3260974369951,
            lat: 48.889111262565
        },
        {
            lng: 2.3299805220467,
            lat: 48.901163282127
        },
        {
            lng: 2.3518739447639,
            lat: 48.901522076927
        },
        {
            lng: 2.365846786,
            lat: 48.901609465126
        },
        {
            lng: 2.3702905140491,
            lat: 48.901653595285
        },
        {
            lng: 2.3698082422377,
            lat: 48.893376450644
        },
        {
            lng: 2.3646776664012,
            lat: 48.884291351874
        }
    ],
    [
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        },
        {
            lng: 2.3646776664012,
            lat: 48.884291351874
        },
        {
            lng: 2.3698082422377,
            lat: 48.893376450644
        },
        {
            lng: 2.3702905140491,
            lat: 48.901653595285
        },
        {
            lng: 2.3894416378177,
            lat: 48.901152013806
        },
        {
            lng: 2.394906293421,
            lat: 48.898444039523
        },
        {
            lng: 2.3986443942733,
            lat: 48.889410347347
        },
        {
            lng: 2.4106903466383,
            lat: 48.878475106516
        },
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        }
    ],
    [
        {
            lng: 2.4132704497803,
            lat: 48.873116980044
        },
        {
            lng: 2.4153151254188,
            lat: 48.855173471685
        },
        {
            lng: 2.4163411302989,
            lat: 48.849233783552
        },
        {
            lng: 2.4159711107696,
            lat: 48.846622956277
        },
        {
            lng: 2.3991184448726,
            lat: 48.848094484736
        },
        {
            lng: 2.3943004420294,
            lat: 48.856491711482
        },
        {
            lng: 2.3770153520998,
            lat: 48.87192112928
        },
        {
            lng: 2.4106903466383,
            lat: 48.878475106516
        },
        {
            lng: 2.4132704497803,
            lat: 48.873116980044
        }
    ]
]

export const parisPolygon = [
    {
        lng: 2.3198871747441,
        lat: 48.90045978209
    },
    {
        lng: 2.3851496429397,
        lat: 48.902007785215
    },
    {
        lng: 2.3894416378177,
        lat: 48.901152013806
    },
    {
        lng: 2.3976272336691,
        lat: 48.894578113161
    },
    {
        lng: 2.3988455271816,
        lat: 48.887109095072
    },
    {
        lng: 2.4132702557262,
        lat: 48.872892145992
    },
    {
        lng: 2.4163411302989,
        lat: 48.849233783552
    },
    {
        lng: 2.4122456125626,
        lat: 48.834538914673
    },
    {
        lng: 2.4281301699852,
        lat: 48.841528392473
    },
    {
        lng: 2.447699326814,
        lat: 48.844818443355
    },
    {
        lng: 2.4634383121686,
        lat: 48.842089485269
    },
    {
        lng: 2.4675819883673,
        lat: 48.833133318793
    },
    {
        lng: 2.4612052142523,
        lat: 48.818355149154
    },
    {
        lng: 2.4384475102742,
        lat: 48.818232447877
    },
    {
        lng: 2.406031823401,
        lat: 48.827615470779
    },
    {
        lng: 2.3909392530738,
        lat: 48.826078980076
    },
    {
        lng: 2.3642042811729,
        lat: 48.816398323996
    },
    {
        lng: 2.3318980606376,
        lat: 48.817010929642
    },
    {
        lng: 2.2893937776751,
        lat: 48.828352646104
    },
    {
        lng: 2.2790519306533,
        lat: 48.832489952145
    },
    {
        lng: 2.2727931901868,
        lat: 48.827920084226
    },
    {
        lng: 2.2551442384175,
        lat: 48.834809549369
    },
    {
        lng: 2.2506124417162,
        lat: 48.845554851211
    },
    {
        lng: 2.2242191058804,
        lat: 48.853516917557
    },
    {
        lng: 2.225670292257,
        lat: 48.859404562396
    },
    {
        lng: 2.2317363597469,
        lat: 48.86906858161
    },
    {
        lng: 2.2584671711142,
        lat: 48.880387263086
    },
    {
        lng: 2.2774870298138,
        lat: 48.877968320853
    },
    {
        lng: 2.2915068524977,
        lat: 48.8894718708
    },
    {
        lng: 2.3198871747441,
        lat: 48.90045978209
    }
]