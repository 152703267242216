import React, { useContext } from 'react'
import { objectGetKeys } from '../../../../utils/ieCompatibility'
import { selectionEnum } from '../../enum/selectionEnum'
import { FormattedMessage, FormattedPlural } from 'react-intl'
import SelectionCard from '../../../../components/offersDisplay/components/SelectionCard'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../index'
import links from '../../../../enums/linksEnum'
import { useHistory } from 'react-router'
import { manageFavorites } from '../../api/manageFavorites'
import { isCardHovered } from '../../reducer/actions'
import { manageFavorites as manageFavoritesAction } from '../../../favorites/reducer/actions'
import errorCatcher from '../../../../utils/errorCatcher'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../../../utils/context/responsiveContext'
import makeStyles from '@material-ui/core/styles/makeStyles'
import styles from '../../../../styles'
import { useTheme } from '@material-ui/core'
import Element from 'react-scroll/modules/components/Element'
import { isCardHoveredInitialState } from '../../reducer/reducers'
import SvgLocation from '../../../../icons/Location'
import { redirectToWithQuery } from '../../../../utils/formatter/formatRouter'

const useStyles = makeStyles(
    (theme) => (
        {
            selectionTitles: {
                '& > div:first-child h1': {
                    color: theme.palette.blue.main,
                    marginTop: 0,

                    '& svg': {
                        marginRight: 10
                    }
                },

                '& .title-0': {
                    marginTop: 30
                },
                '& > div:first-child h1:nth-child(2)': {
                    marginTop: 22
                },
                '& div div': {
                    padding: 0
                },
                '& .shortDiv-0': {
                    paddingRight: 10
                },
                '& .shortDiv-1': {
                    paddingLeft: 10
                }
            },
            selectionTitlesLargeScreenStyle: {
                '& > div:first-child h1': {
                    marginBottom: 20,
                    ...styles(theme).title1
                }
            },
            selectionTitlesSmallScreenStyles: {
                '& > div:first-child h1': {
                    marginBottom: 15,
                    ...styles(theme).title2
                }
            }
        }
    )
)

const MANAGE_FAVORITE = 'manage_favorite'

const SelectionsComponent = () => {
    const classes = useStyles()
    const theme = useTheme()
    const history = useHistory()
    const user = useSelector((state: RootState) => state.user)
    const selections = useSelector((state: RootState) => state.selection)
    const favorites = useSelector((state: RootState) => state.favorites)
    const dispatch = useDispatch()
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

    const selectionTitlesClass: ClassValue = classNames(
        classes.selectionTitles,
        isLargeScreen ? classes.selectionTitlesLargeScreenStyle : classes.selectionTitlesSmallScreenStyles
    )

    const onItemSelected = (id: string, type: selectionEnum) => {
        const selectedCategory = selections.selections[type]
        const index = selectedCategory.findIndex((selection) => selection.id === id)

        if (index > -1) {
            const favoriteIndex = favorites.favorites.findIndex((favorite) => favorite.id === selectedCategory[index].id)

            if (favoriteIndex >= 0) {
                const favoriteItem = favorites.favorites[favoriteIndex]

                manageFavorites(user.id, favoriteItem.id, !favoriteItem.selected)
                    .then(
                        () => {
                            dispatch(
                                manageFavoritesAction(
                                    {
                                        ...favoriteItem,
                                        selected: !favoriteItem.selected
                                    }
                                )
                            )
                        }
                    )
                    .catch(
                        (error: any) => dispatch(
                            errorCatcher(error, MANAGE_FAVORITE)
                        )
                    )
            } else {
                manageFavorites(user.id, selectedCategory[index].id, true)
                    .then(
                        () => {
                            dispatch(
                                manageFavoritesAction(
                                    {
                                        ...selectedCategory[index],
                                        selected: true
                                    }
                                )
                            )
                        }
                    )
                    .catch(
                        (error: any) => dispatch(
                            errorCatcher(error, MANAGE_FAVORITE)
                        )
                    )
            }
        }
    }

    const seeDetails = (id: string) => {
        redirectToWithQuery(history, `${links.deskDetails}/${id}`)
    }

    return (
        <>
            {
                objectGetKeys(selectionEnum).map(
                    (selectionType: string, index: number) => {
                        if (selections.selections[selectionType as keyof typeof selections.selections].length === 0) {
                            return (
                                <React.Fragment key={selectionType}></React.Fragment>
                            )
                        }

                        return (
                            <div
                                className={selectionTitlesClass}
                                key={selectionType}
                                style={
                                    selections.selections.visits.length > 0 && selections.selections.selectedOffers.length > 0 && index === 1 ? {
                                        borderTop: `2px solid ${theme.palette.green.main}`,
                                        paddingTop: 32
                                    } : {}
                                }
                            >
                                <div className="row">
                                    <div className={`col-xs-2 col-md-1 title-${index}`}>
                                        {
                                            selectionType === selectionEnum.visits ? (
                                                <SvgLocation
                                                    color={theme.palette.green.main}
                                                    secondColor="#FFFFFF"
                                                    height={42}
                                                    width={30}
                                                    borderColor={theme.palette.green.main}
                                                />
                                            ) : (
                                                <SvgLocation
                                                    color="#FFFFFF"
                                                    secondColor={theme.palette.blue.main}
                                                    height={42}
                                                    width={30}
                                                    borderColor={theme.palette.blue.main}
                                                />
                                            )
                                        }
                                    </div>

                                    <div className={`col-xs-10 col-md-11 title-${index}`}>
                                        <h1>
                                            {
                                                selectionType === selectionEnum.visits ? (
                                                    <FormattedPlural
                                                        value={selections.selections.visits.length}
                                                        one={
                                                            <FormattedMessage
                                                                id="selection.visit"
                                                                defaultMessage="Votre dernière visite"
                                                                description="One visit title"
                                                            />
                                                        }
                                                        other={
                                                            <FormattedMessage
                                                                id="selection.visits"
                                                                defaultMessage="Vos visites"
                                                                description="Multiple visits title"
                                                            />
                                                        }
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        id={`selection.${selectionType}`}
                                                        defaultMessage="Notre sélection personnalisée"
                                                        description="Item title"
                                                    />
                                                )

                                            }
                                        </h1>
                                    </div>
                                </div>

                                <div className="row selectionCardContainer">
                                    {
                                        selections.selections[selectionType as keyof typeof selections.selections].map(
                                            (item, index) => (
                                                <Element
                                                    name={item.id}
                                                    className={`col-xs-12 col-sm-6 ${isTabletteScreen ? `shortDiv-${index % 2}` : ''}`}
                                                    key={item.id}
                                                    onMouseEnter={
                                                        () => dispatch(
                                                            isCardHovered(
                                                                {
                                                                    isHovered: true,
                                                                    offerHovered: item.id
                                                                }
                                                            )
                                                        )
                                                    }
                                                    onMouseLeave={
                                                        () => dispatch(
                                                            isCardHovered(isCardHoveredInitialState)
                                                        )
                                                    }
                                                >
                                                    <SelectionCard
                                                        item={item}
                                                        selectItem={() => onItemSelected(item.id, selectionEnum[selectionType as keyof typeof selectionEnum])}
                                                        seeDetails={seeDetails}
                                                    />
                                                </Element>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                )
            }
        </>
    )
}

export default SelectionsComponent
