import React from 'react'
import selectColorEnum from '../../enums/selectEnums/SelectColorEnum'
import { Field, FieldRenderProps } from 'react-final-form'
import SelectField from './SelectField'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl'

const useStyles = makeStyles(
	(theme) => (
		{
			disponibilityFormContainer: {
				padding: '20px 25px 30px'
			},
			errorStyle: {
				color: '#B92D00',
				fontSize: 10,
				paddingLeft: 10
			},
			renderedValue: {
				display: 'flex',
				alignItems: 'flex-end',

				'& img': {
					width: 30,
					marginRight: 20
				},
				'& span': {
					marginTop: 10
				}
			}
		}
	)
)

interface AcceptingProps {
	renderValue: () => JSX.Element,
	multiple?: boolean
}

type SelectDisponibilityFormProps =
	AcceptingProps
	& FieldRenderProps<string>

const FormSelectField: React.FC<SelectDisponibilityFormProps> = (
	{
		children,
		renderValue,
		multiple = false,
		input,
		meta: {
			touched,
			error
		}
	}
) => {
	const classes = useStyles()

	return (
		<>
			<SelectField
				backgroundColor={selectColorEnum.grey}
				renderValue={renderValue}
				multiple={multiple}
				{...input}
			>
				{children}
			</SelectField>

			{
				touched &&
				((error && <span className={classes.errorStyle}>{error}</span>))
			}
		</>
	)
}

export default FormSelectField

export const RenderValue = (name: string) => {
	const classes = useStyles()
	const intl = useIntl()

	const combineIconValue = (value: JSX.Element) => (
		<div className={classes.renderedValue}>
			<img src="/img/offers/calendar.png" alt="Disponibility icon" />

			<span>{value}</span>
		</div>
	)

	return (
		<Field
			name={name}
			render={
				({ input: { value } }: FieldRenderProps<string>) => {
					if (value.length === 0) {
						return combineIconValue(
							<FormattedMessage
								id="offers.research.selects.disponibilityPlaceholder"
								defaultMessage="Date de disponibilité"
								description="Placeholder item"
							/>
						)
					}

					return combineIconValue(
						<FormattedPlural
							value={value.length}
							one={
								intl.formatMessage(
									{
										id: 'offers.research.selects.oneSemester',
										defaultMessage: '1 semestre sélectionné',
										description: '1 semester selected'
									}
								)
							}
							other={
								intl.formatMessage(
									{
										id: 'offers.research.selects.semesters',
										defaultMessage: '{semesters} semestres sélectionnés',
										description: 'N semesters selected'
									},
									{
										semesters: value.length
									}
								)
							}
						/>
					)
				}
			}
		/>
	)
}