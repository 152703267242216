import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import styles from '../../../styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { hasContact: boolean | undefined }>(
    (theme) => (
        {
            creditContainerStyle: {
                background: theme.palette.blue.main,

                '& > div': {
                    margin: 'auto',

                    '& > div:first-child': {
                        opacity: 0.9,
                        padding: '30px 0',
                        borderTop: '1px solid white'
                    },
                    '& > div:last-child': {
                        opacity: 0.9,
                        padding: '30px 0 100px',
                        borderTop: '1px solid white'
                    },
                    '& p': {
                        margin: 0,
                        color: 'white',
                        ...styles(theme).text8
                    }
                }
            },
            creditContainerLargeScreenStyle: {
                marginTop: props => props.hasContact ? 0 : 50,
                paddingTop: props => props.hasContact ? 0 : 25,

                '& > div': {
                    maxWidth: 1100
                }
            },
            creditContainerSmallScreenStyle: {
                marginTop: props => props.hasContact ? 0 : 30,
                paddingTop: props => props.hasContact ? 0 : 5,
            }
        }
    )
)

interface AcceptingProps {
    credit: string | undefined,
    hasContact: boolean | undefined
}

type deckCreditProps = AcceptingProps

const DeckCreditPage: React.FC<deckCreditProps> = (
    {
        credit,
        hasContact
    }
) => {
    const classes = useStyles({ hasContact })
    const { isLargeScreen } = useContext(ResponsiveContext)

    const creditContainerClass: ClassValue = classNames(
        classes.creditContainerStyle,
        isLargeScreen ? classes.creditContainerLargeScreenStyle : classes.creditContainerSmallScreenStyle
    )

    return (
        <div className={creditContainerClass}>
            <div className="marginContent">
                {
                    credit && (
                        <div className="row">
                            <div className="col-xs-12">
                                <p>
                                    <strong>
                                        <FormattedMessage
                                            id="deck.credit.credit"
                                            defaultMessage="Crédits photos"
                                            description="Credit text"
                                        />
                                    </strong> {credit}
                                </p>
                            </div>
                        </div>
                    )
                }

                <div className="row">
                    <div className="col-xs-12">
                        <p>
                            <FormattedMessage
                                id="deck.credit.nonContractual"
                                defaultMessage="Non contractuel - données confidentielles"
                                description="Non contractual text"
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeckCreditPage