import React, { useContext, useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { useTheme } from '@material-ui/core'
import styles from '../../styles'
import MapComponent from './components/map/MapComponent'
import { SelectionModel, SelectionReducerModel } from '../../modules/selection/types/selectionModel'
import { FormattedMessage } from 'react-intl'
import SvgLocation from '../../icons/Location'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import StickyEl from 'react-sticky-el'
import { elementDisplayedEnum } from './enum/elementDisplayedEnum'
import { OfferDetailsModel } from '../offerDetails/types/offerDetailsModel'
import { useLocation } from 'react-router'
import links from '../../enums/linksEnum'
import TransitComponent from './components/map/component/TransitComponent'

const useStyles = makeStyles(
    (theme) => (
        {
            selectionWithMarginStyle: {
                marginLeft: 20,
                marginRight: 20
            },
            selectionWithoutMarginStyle: {
                '& > div': {
                    padding: 0
                }
            },
            mapContainerStyle: {
                position: 'relative',
                height: '100%',
                padding: 0,

                '& > div:first-child': {
                    height: '100%'
                }
            },
            mapContainerSmallScreenStyle: {
                minHeight: '75vh'
            },
            switchButtonStyle: {
                position: 'fixed',
                bottom: 22.5,
                left: '50%',
                transform: 'translate(-50%, 0px)',
                zIndex: 2,
                padding: 20,
                backgroundColor: theme.palette.accessibility.main,
                color: 'white',
                width: 'fit-content',
                margin: 'auto',
                borderRadius: 35,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',

                '& span': {
                    ...styles(theme).text8
                },
                '& svg': {
                    marginLeft: 25
                }
            },
            switchMapStyle: {
                position: 'absolute',
                bottom: 30,
                left: '50%',
                transform: 'translate(-50%, 0)'
            },
            mapStyle: {
                height: '88vh'
            },
            mapFixed: {
                paddingTop: 100,

                '& > div:first-child': {
                    top: 110
                }
            },
            fullHeight: {
                height: '100%'
            },
            listOffers: {
                paddingRight: 40
            }
        }
    )
)

interface AcceptingProps {
    selections: SelectionReducerModel | (SelectionModel & OfferDetailsModel)[],
    offers: React.ReactChild,
    whichElementDisplayed?: (elementDisplayed: elementDisplayedEnum) => void
}

const OFFSET = 100

type offersDisplayProps = AcceptingProps

const OffersDisplay: React.FC<offersDisplayProps> = (
    {
        selections,
        offers,
        whichElementDisplayed
    }
) => {
    const classes = useStyles()
    const theme = useTheme()
    const location = useLocation()
    const { isLargeScreen } = useContext(ResponsiveContext)

    const [elementDisplayed, setElementDisplayed] = useState<elementDisplayedEnum>(elementDisplayedEnum.offers)
    const [isFixed, setIsFixed] = useState<boolean>(!isLargeScreen)
    const [hasScrolled, setHasScrolled] = useState<boolean>(false)
    const [hasTransit, setHasTransit] = useState<boolean>(false)
    const selectionContainerClass: ClassValue = classNames(
        'row fullWidth',
        {
            [classes.selectionWithMarginStyle]: !isLargeScreen && elementDisplayed === elementDisplayedEnum.offers,
            [classes.selectionWithoutMarginStyle]: !isLargeScreen && elementDisplayed === elementDisplayedEnum.map,
            [classes.fullHeight]: !isLargeScreen && location.pathname.includes(links.offers) && elementDisplayed === elementDisplayedEnum.offers
        }
    )

    const mapContainerClass: ClassValue = classNames(
        'col-xs-12 col-md-6',
        classes.mapContainerStyle
    )

    useEffect(
        () => {
            window.addEventListener('scroll', (event) => {
                if (!hasScrolled && window.pageYOffset > 20) {
                    setHasScrolled(true)
                }
            })
        }, [hasScrolled]
    )

    return (
        <div className={selectionContainerClass}>
            {
                isLargeScreen ? (
                    <>
                        <div
                            className={
                                classNames(
                                    'col-md-6',
                                    {
                                        [classes.listOffers]: isLargeScreen
                                    }
                                )
                            }
                        >
                            {offers}
                        </div>

                        <StickyEl
                            topOffset={-OFFSET}
                            mode="top"
                            onFixedToggle={(isFixed) => setIsFixed(isFixed)}
                            className={mapContainerClass}
                        >
                            <div
                                className={
                                    classNames(
                                        classes.mapStyle,
                                        {
                                            [classes.mapFixed]: isFixed
                                        }
                                    )
                                }
                            >
                                <TransitComponent
                                    hasTransit={hasTransit}
                                    setHasTransit={setHasTransit}
                                />

                                <MapComponent
                                    selections={selections}
                                    hasTransit={hasTransit}
                                />
                            </div>
                        </StickyEl>
                    </>
                ) : (
                    <div className="col-xs-12">
                        {
                            elementDisplayed === elementDisplayedEnum.offers ? (
                                <>
                                    {offers}

                                    <div
                                        className={classes.switchButtonStyle}
                                        onClick={
                                            () => {
                                                setElementDisplayed(elementDisplayedEnum.map)
                                                whichElementDisplayed && whichElementDisplayed(elementDisplayedEnum.map)
                                            }
                                        }
                                    >
											<span>
												<FormattedMessage
                                                    id="selection.elementDisplayed.offers"
                                                    defaultMessage="Carte"
                                                    description="Switch button text"
                                                />
											</span>

                                        <SvgLocation
                                            width={16}
                                            height={20}
                                            color="#FFFFFF"
                                            secondColor={theme.palette.accessibility.main}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div
                                    className={
                                        classNames(
                                            classes.mapContainerStyle,
                                            {
                                                [classes.mapContainerSmallScreenStyle]: !isLargeScreen
                                            }
                                        )
                                    }
                                >
                                    <MapComponent
                                        selections={selections}
                                        hasTransit={hasTransit}
                                    />

                                    <TransitComponent
                                        hasTransit={hasTransit}
                                        setHasTransit={setHasTransit}
                                    />

                                    <div
                                        className={
                                            classNames(
                                                classes.switchMapStyle,
                                                classes.switchButtonStyle
                                            )
                                        }
                                        onClick={
                                            () => {
                                                setElementDisplayed(elementDisplayedEnum.offers)
                                                whichElementDisplayed && whichElementDisplayed(elementDisplayedEnum.offers)
                                            }
                                        }
                                    >
										<span>
											<FormattedMessage
                                                id="selection.elementDisplayed.map"
                                                defaultMessage="Voir la liste"
                                                description="Switch button text"
                                            />
										</span>

                                        <ExpandMoreIcon/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default OffersDisplay