import React, { useContext, useState } from 'react'
import { objectGetKeys } from '../../../utils/ieCompatibility'
import { deckEnum } from '../enum/deckEnum'
import Link from 'react-scroll/modules/components/Link'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import styles from '../../../styles'
import { FormattedMessage } from 'react-intl'
import SvgFavorite from '../../../icons/Favorite'
import CloseIcon from '@material-ui/icons/Close'
import { DeckModel } from '../types/deckModel'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { fallDown as MenuBurgerComponent } from 'react-burger-menu'
import { useHistory } from 'react-router'
import SvgStarSelection from '../../../icons/StarSelection'
import { manageFavorites } from '../../selection/api/manageFavorites'
import errorCatcher from '../../../utils/errorCatcher'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../index'
import MenuIcon from '@material-ui/icons/Menu'
import localStorageEnum from '../../../enums/localStorageEnum'

const useStyles = makeStyles(
	(theme) => (
		{
			menuStyle: {
				alignItems: 'center',
				borderBottom: `1px solid ${theme.palette.blue['60']}`,
				margin: 0,

				'& .bm-menu-wrap': {
					top: 0,
					overflow: 'hidden'
				}
			},
			menuLargeScreenStyle: {
				paddingLeft: 32,
				height: 87
			},
			menuSmallScreenStyle: {
				paddingLeft: 10,
				height: 65,

				'& .textMenu': {
					...styles(theme).text3,

					'& span': {
						marginRight: 10
					}
				}
			},
			menuItemStyle: {
				height: '100%',
				display: 'flex',

				'& > a': {
					display: 'flex',
					alignItems: 'center',
					position: 'relative'
				}
			},
			linkStyle: {
				color: 'white',
				marginRight: '3%',
				cursor: 'pointer',
				...styles(theme).text8,
				fontSize: "12px",
			},
			linkSmallScreenStyle: {
				display: 'flex',
				flexDirection: 'column'
			},
			linkUnselected: {
				opacity: 0.6
			},
			linkSelected: {
				'&::after': {
					bottom: -1,
					width: '100%',
					height: 1,
					content: '\' \'',
					display: 'inline-block',
					background: theme.palette.green.main,
					position: 'absolute',
					right: 0
				}
			},
			iconContainerStyle: {
				height: '100%',

				'& > div': {
					height: '100%',

					'& div': {
						cursor: 'pointer',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: 'white'
					},

					'& div:not(:last-child)': {
						borderRight: '1px solid rgba(255,255,255,0.1)'
					},
					'& div:first-child': {
						borderLeft: '1px solid rgba(255,255,255,0.1)'
					},
					'& div:last-child svg': {
						color: 'white',
						width: 30,
						height: 30
					}
				}
			}
		}
	)
)

interface AcceptingProps {
	content: DeckModel,
	fromTop: number,
	isOpen: boolean,
	setOpen: (open: boolean) => void
}

const MANAGE_FAVORITE = 'manage_favorite'

type ServiceHeaderProps = AcceptingProps

const DeckHeader: React.FC<ServiceHeaderProps> = (
	{
		content,
		fromTop = 0,
		isOpen,
		setOpen
	}
) => {
	const classes = useStyles(fromTop)
	const theme = useTheme()
	const history = useHistory()
	const user = useSelector((state: RootState) => state.user)
	const dispatch = useDispatch()
	const url_token = localStorage.getItem(localStorageEnum.url_token)

	const [isFavorite, setIsFavorite] = useState<boolean>(content.metas.selected)
	const [selected, setSelected] = useState<deckEnum>(deckEnum.presentation)
	const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

	const isLargeSize = () => {
		const menuSize = objectGetKeys(deckEnum).filter((menu) => content[menu as keyof typeof content])

		if ((isLargeScreen && menuSize.length < 6) || (isTabletteScreen && !isLargeScreen)) {
			return true
		} else if (isLargeScreen && menuSize.length > 6) {
			return false
		}
	}

	const menuClass: ClassValue = classNames(
		'row',
		classes.menuStyle,
		isLargeScreen ? classes.menuLargeScreenStyle : classes.menuSmallScreenStyle
	)
	const menuItemClass: ClassValue = classNames(
		isLargeSize() ? `col-xs-${url_token !== process.env.REACT_APP_GENERIC_URL ? '7' : '8'}` : `col-xs-${url_token !== process.env.REACT_APP_GENERIC_URL ? '8' : '9'}`,
		classes.menuItemStyle
	)
	const linkClass: ClassValue = classNames(
		classes.linkStyle,
		{
			[classes.linkSmallScreenStyle]: !isLargeScreen
		}
	)
	const iconContainerClass: ClassValue = classNames(
		`col-xs-8 col-md-${url_token !== process.env.REACT_APP_GENERIC_URL ? '2' : '1'}`,
		classes.iconContainerStyle
	)

	const changeSelectedElement = (elementSelected: deckEnum) => {
		setSelected(elementSelected)
	}

	const items = () => (
		<>
			{
				objectGetKeys(deckEnum).map(
					(deckHeader: string) => (
						content[deckHeader as keyof typeof content] && (
							<Link
								key={deckHeader}
								className={
									classNames(
										linkClass,
										deckHeader === selected ? classes.linkSelected : classes.linkUnselected
									)
								}
								spy
								to={deckHeader}
								smooth
								onSetActive={changeSelectedElement}
								offset={isLargeScreen ? -87 : -65}
								onClick={() => setOpen(false)}
							>
								<span>
									<FormattedMessage
										id={`deck.menu.${deckHeader}`}
										defaultMessage="Présentation"
										description="Deck menu text"
									/>
								</span>
							</Link>
						)
					)
				)
			}
		</>
	)

	const onManageFavorites = () => {
		manageFavorites(user.id, content.metas.id_crm, !isFavorite)
			.then(
				() => {
					setIsFavorite(!isFavorite)
				}
			)
			.catch(
				(error: any) => dispatch(
					errorCatcher(error, MANAGE_FAVORITE)
				)
			)
	}

	const closeDialog = () => {
		history.goBack()
	}

	return (
		<div className={menuClass}>
			<div className={`col-xs-4 ${isLargeSize() ? 'col-md-3' : 'col-md-2'}`}>
				<img
					src="/img/youFirstGecina.png"
					alt="Gecina's logo"
					width={isLargeSize() ? 150 : 110}
				/>
			</div>

			{
				isLargeScreen ? (
					<div className={menuItemClass}>
						{items()}
					</div>
				) : (
					<MenuBurgerComponent
						outerContainerId="deck-menu"
						right
						width="100%"
						customBurgerIcon={false}
						customCrossIcon={false}
						isOpen={isOpen}
						noOverlay
						styles={
							isOpen ? {
								bmMenuWrap: {
									top: '65px'
								}
							} : {
								bmMenuWrap: {
									top: '0px'
								}
							}
						}
					>
						{items()}
					</MenuBurgerComponent>
				)
			}

			<div className={iconContainerClass}>
				<div className="row">
					{
						!isLargeScreen && (
							<div
								className={`col-xs-${url_token !== process.env.REACT_APP_GENERIC_URL ? '6' : '9'} textMenu`}
								onClick={() => setOpen(!isOpen)}
							>
								{
									isOpen ? (
										<>
											<span>
												<FormattedMessage
													id="menu.close"
													defaultMessage="Fermer"
													description="Close menu"
												/>
											</span>

											<CloseIcon />
										</>
									) : (
										<>
											<span>Menu</span>

											<MenuIcon />
										</>
									)
								}
							</div>
						)
					}

					{
						url_token !== process.env.REACT_APP_GENERIC_URL && (
							<div
								className="col-xs-3 col-md-6"
								onClick={onManageFavorites}
							>
								{
									isFavorite ? (
										<SvgStarSelection
											color={'#FFFFFF'}
											width={24}
											height={24}
										/>
									) : (
										<SvgFavorite
											color={theme.palette.blue['30']}
											width={24}
											height={24}
										/>
									)
								}

							</div>
						)
					}

					<div
						className={`col-xs-3 col-md-${url_token !== process.env.REACT_APP_GENERIC_URL ? '6' : '12'}`}
						onClick={closeDialog}
					>
						<CloseIcon />
					</div>
				</div>
			</div>
		</div>
	)
}

export default DeckHeader