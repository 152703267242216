import { FooterType } from '../types/types'
import { SAVE_FOOTER } from './actionTypes'
import { PageModel } from '../types/footerModel'

const initialState: PageModel[] = []

const footer = (
	state: PageModel[] = initialState,
	action: FooterType
): PageModel[] => {
	switch (action.type) {
		case SAVE_FOOTER: {
			return 'payload' in action ? action.payload : initialState
		}
		default:
			return state
	}
}

export default footer
