import React, { useContext } from 'react'
import { DeckPlans } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../styles'
import DeckSlider from './DeckSlider'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(
    (theme) => (
        {
            plansContainerStyle: {
                background: theme.palette.blue.main,

                '& > div': {
                    margin: 'auto',

                    '& h1': {
                        margin: 0,
                        color: 'white',
                        ...styles(theme).text21
                    }
                }
            },
            plansContainerLargeScreenStyle: {
                marginTop: 100,

                '& > div': {
                    maxWidth: 1100,

                    '& h1': {
                        marginTop: 115
                    }
                }
            },
            plansContainerSmallScreenStyle: {
                marginTop: 50,

                '& > div': {
                    '& h1': {
                        marginTop: 30
                    }
                }
            },
            bluePath: {
                '& .sliderFullScreen': {
                    '& path': {
                        stroke: theme.palette.blue.main
                    }
                }
            }
        }
    )
)

interface AcceptingProps {
    pictures: DeckPlans[]
}

type deckPlansProps = AcceptingProps

const DeckPlansPage: React.FC<deckPlansProps> = (
    {
        pictures
    }
) => {
    const classes = useStyles()
    const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

    const plansContainerClass: ClassValue = classNames(
        classes.plansContainerStyle,
        isLargeScreen ? classes.plansContainerLargeScreenStyle : classes.plansContainerSmallScreenStyle,
        {
            [classes.bluePath]: !isTabletteScreen
        }
    )

    return (
        <div className={plansContainerClass}>
            <div className="marginContent">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>
                            <FormattedMessage
                                id="deck.plans.title"
                                defaultMessage="Plans"
                                description="Plans title"
                            />
                        </h1>
                    </div>
                </div>

                <DeckSlider content={pictures}/>
            </div>
        </div>
    )
}

export default DeckPlansPage