import React from 'react'
import { fallDown as MenuBurgerComponent } from 'react-burger-menu'
import classNames from 'classnames'
import { ClassValue } from 'classnames/types'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import links from '../../enums/linksEnum'
import { objectGetValues } from '../../utils/ieCompatibility'
import menuEnum, { groupedLinksEnum } from './enum/menuEnum'
import MenuItem from './MenuItem'
import styles from '../../styles'
import LanguageSelector from '../langContext/LanguageSelector'
import localStorageEnum from '../../enums/localStorageEnum'

const useStyle = makeStyles(
	(theme) => styles(theme)
)

interface AcceptingProps {
	onClick: (lien: string) => void,
	open: boolean,
	linkIsSelected: (linksArray: string[], link: string) => string
}

type MenuBurgerProps = AcceptingProps

const MenuBurger: React.FC<MenuBurgerProps> = (
	{
		onClick,
		open,
		linkIsSelected
	}
) => {
	const classes = useStyle()
	const location = useLocation()

	const link = location.pathname
	const url_token = localStorage.getItem(localStorageEnum.url_token)

	const linkClass: ClassValue = classNames(
		'menu-item',
		classes.title5,
		classes.cursorPointer
	)

	return (
		<>
			<MenuBurgerComponent
				outerContainerId="outer-container"
				right
				width="100%"
				customBurgerIcon={false}
				customCrossIcon={false}
				isOpen={open}
				noOverlay
			>
				{
					objectGetValues(menuEnum).map(
						(menu: string) => {
							const groupedLinks = groupedLinksEnum[menu as keyof typeof groupedLinksEnum]

							if (groupedLinks.includes(links.selection) && url_token === process.env.REACT_APP_GENERIC_URL) {
								return <React.Fragment key={menu}></React.Fragment>
							}

							return (
								<span
									id={menu}
									className={linkClass}
									onClick={() => onClick(links[menu as keyof typeof links])}
									key={menu}
								>
								<ChevronRightIcon />

								<span
									className={linkIsSelected(groupedLinksEnum[menu as keyof typeof groupedLinksEnum], link)}
								>
									<MenuItem menu={menu} />
								</span>
							</span>
							)
						}
					)
				}

				<LanguageSelector />
			</MenuBurgerComponent>
		</>
	)
}

export default MenuBurger
