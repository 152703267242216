import React, { useContext } from 'react'
import { DeckSurfaces } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage, useIntl } from 'react-intl'
import Table from '../../../components/table/Table'
import { ColumnsModel } from '../../../components/table/types/tableModel'
import { objectGetKeys } from '../../../utils/ieCompatibility'
import thousandsSeparator from '../../../utils/thousandsSeparator'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import '../deckStyle.css'

const useStyles = makeStyles(
	(theme) => (
		{
			surfacesContainerStyle: {
				margin: 'auto',

				'& h1': {
					...styles(theme).text21,
					color: theme.palette.blue.main,
					margin: 0
				}
			},
			surfacesContainerLargeScreenStyle: {
				maxWidth: 1100,
				marginTop: 60,

				'& h1': {
					marginBottom: 35
				}
			},
			surfacesContainerTabletteScreenStyle: {
				margin: '10px 20px 0'
			},
			surfacesContainerSmallScreenStyle: {
				marginRight: 0,

				'& h1': {
					marginBottom: 20
				},
				'& .col-xs-12': {
					paddingRight: 0
				}
			},
			totalRow: {
				fontWeight: 600
			},
			iconTouchStyle: {
				transform: 'translateX(0)',
				animation: 'sail 2s  ease-in-out infinite',
				color: theme.palette.blue.main
			},
			arrowStyle: {
				marginBottom: 20,
				display: 'flex',
				alignItems: 'flex-end',
				justifyContent: 'flex-end'
			}
		}
	)
)

interface AcceptingProps {
	surfaces: DeckSurfaces[]
}

const NUMBER_EXCLUDED = ['level', 'estimation']
const TOTAL = 'total'

type deckSurfacesProps = AcceptingProps

const DeckSurfacesPage: React.FC<deckSurfacesProps> = (
	{
		surfaces
	}
) => {
	const classes = useStyles()
	const intl = useIntl()
	const { isLargeScreen, isTabletteScreen } = useContext(ResponsiveContext)

	const surfacesContainerClass: ClassValue = classNames(
		classes.surfacesContainerStyle,
		{
			[classes.surfacesContainerLargeScreenStyle]: isLargeScreen,
			[classes.surfacesContainerTabletteScreenStyle]: isTabletteScreen && !isLargeScreen,
			[classes.surfacesContainerSmallScreenStyle]: !isTabletteScreen
		}
	)

	const arrowClass: ClassValue = classNames(
		'col-xs-3',
		classes.arrowStyle
	)

	const columns: ColumnsModel[] = objectGetKeys(surfaces[0]).map(
		(item) => (
			{
				value: item,
				label: intl.formatMessage(
					{
						id: `deck.surfaces.${item}`,
						defaultMessage: item,
						description: `${item} text`
					}
				),
				type: typeof surfaces[0][item as keyof typeof surfaces[0]],
				width: `${100 / objectGetKeys(surfaces[0]).length}%`,
				render: (row: DeckSurfaces) => {
					const fakeElement = row[item as keyof typeof row]
					const element = typeof fakeElement === 'string' ? fakeElement.replace(/\s+/g, '') : fakeElement

					return (
						<div style={{ width: !isTabletteScreen ? '40vw' : undefined }}>
							{
								isNaN(element as number) ? (
									<>
										{
											row.level.toLowerCase() === TOTAL ? (
												<strong>{fakeElement}</strong>
											) : (
												<>
													{fakeElement}
												</>
											)
										}
									</>
								) : (
									<>
										{
											NUMBER_EXCLUDED.includes(item) ? (
												<>
													{
														row.level.toLowerCase() === TOTAL ? (
															<strong>
																{thousandsSeparator(Number(element), true)}
															</strong>
														) : (
															<>
																{thousandsSeparator(Number(element), true)}
															</>
														)
													}
												</>
											) : (
												<>
													{
														row.level.toLowerCase() === TOTAL ? (
															<strong>
																<FormattedMessage
																	id="global.unit"
																	defaultMessage="m²"
																	description="Unit"
																	values={
																		{
																			value: thousandsSeparator(Number(element), true)

																		}
																	}
																/>
															</strong>
														) : (
															<FormattedMessage
																id="global.unit"
																defaultMessage="m²"
																description="Unit"
																values={
																	{
																		value: thousandsSeparator(Number(element), true)

																	}
																}
															/>
														)
													}
												</>
											)
										}
									</>
								)
							}
						</div>
					)
				}
			}
		)
	)

	return (
		<div className={surfacesContainerClass}>
			<div className="row">
				<div className={isLargeScreen ? 'col-xs-12' : 'col-xs-9'}>
					<h1>
						<FormattedMessage
							id="deck.surfaces.title"
							defaultMessage="Tableaux des surfaces"
							description="Surfaces title"
						/>
					</h1>
				</div>
				{
					!isTabletteScreen && !isLargeScreen &&
					<div className={arrowClass}>
						<ArrowForwardIcon className={classes.iconTouchStyle} />
					</div>
				}
			</div>

			<div className="row">
				<div className="col-xs-12">
					<Table columns={columns} lines={surfaces} />
				</div>
			</div>
		</div>
	)
}

export default DeckSurfacesPage