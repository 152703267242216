import React, {useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import styles from '../../../../../styles'
import {ClassValue} from 'classnames/types'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {ResponsiveContext} from '../../../../../utils/context/responsiveContext'

const useStyle = makeStyles(
    (theme) => (
        {
            ...styles(theme),
            imgStyle: {
                width: '100%'
            },
            containerStyle: {
                '& > div': {
                    textAlign: 'left'
                }
            },
            containerSmallScreenStyle: {
                width: '100%',
                padding: 15,
                margin: 'auto',

                '& div:nth-child(2)': {
                    margin: 'auto',

                    '& img': {
                        width: '100%'
                    }
                }
            },
            buttonsContainerStyle: {
                '& div:first-child': {
                    marginBottom: 15
                }
            }
        }
    )
)

const RequestSummary = () => {
    const classes = useStyle()
    const {isLargeScreen} = useContext(ResponsiveContext)

    const containerClass: ClassValue = classNames(
        'row',
        classes.containerStyle,
        {
            [classes.flexColumnReverse]: !isLargeScreen,
            [classes.containerSmallScreenStyle]: !isLargeScreen
        }
    )

    const titleClass: ClassValue = classNames(
        classes.colorBlue,
        {
            [classes.title2]: isLargeScreen,
            [classes.title6]: !isLargeScreen
        }
    )

    const textClass: ClassValue = classNames(
        classes.colorBlue,
        {
            [classes.text13_opacity]: isLargeScreen,
            [classes.text2_opacity]: !isLargeScreen
        }
    )

    return (
        <>
            <div className="row">
                <div className="col-xs-6">
                    <img
                        src="img/yfManager/sendImg.png"
                        alt="Send icon"
                        className={classes.imgStyle}
                    />
                </div>
            </div>

            <div className={containerClass}>
                <div className="col-xs-12 col-md-8 center-sm">
                    <h1 className={titleClass}>
                        <FormattedMessage
                            id="popinYf.makeRequest.thirdStep.title"
                            defaultMessage="Votre message a bien été envoyé"
                            description="Confirmation title"
                        />
                    </h1>

                    <p className={textClass}>
                        <FormattedMessage
                            id="popinYf.makeRequest.thirdStep.message"
                            defaultMessage="Votre équipe YouFirst vous répondra dès que possible."
                            description="Confirmation message"
                        />
                    </p>
                </div>
            </div>
        </>
    )
}

export default RequestSummary
