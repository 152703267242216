export enum natureEnum {
	commercial = 'commercial',
	derogatory = 'derogatory',
	dwelling = 'dwelling',
	retail = 'retail'
}


export enum natureIdEnum {
	retail = 2
}