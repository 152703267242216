import React, { useContext } from 'react'
import { ListModel } from '../types/deckModel'
import { makeStyles } from '@material-ui/core/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import styles from '../../../styles'
import { ResponsiveContext } from '../../../utils/context/responsiveContext'
import parse from 'html-react-parser'

const useStyles = makeStyles(
	(theme) => (
		{
			listStyle: {
				padding: '0 0.5rem',

				'& > div:first-child': {
					padding: 0
				},
				'& > div:last-child': {
					padding: 0,
					alignSelf: 'center'
				},
				'& > div': {
					'& h2, & p': {
						margin: 0,
						color: theme.palette.blue.main
					},
					'& h2': {
						marginBottom: 20,
						...styles(theme).text21
					},
					'& p': {
						...styles(theme).text20,
						marginBottom: 15
					}
				},
				'& img': {
					width: '100%',
					height: '100%'
				},
				'& .col-xs-12, & .col-md-10': {
					padding: 0
				}
			},
			listSmallScreenStyle: {
				'& .imgContainer': {
					marginBottom: 30
				}
			},
			alignListRightStyle: {
				marginLeft: 'auto'
			}
		}
	)
)

interface AcceptingProps {
	item: ListModel,
	index: number
}

type DeckListProps = AcceptingProps

const DeckList: React.FC<DeckListProps> = (
	{
		item,
		index
	}
) => {
	const classes = useStyles()
	const { isLargeScreen } = useContext(ResponsiveContext)

	const listClass: ClassValue = classNames(
		'row',
		classes.listStyle,
		{
			'reverse': index % 2,
			[classes.listSmallScreenStyle]: !isLargeScreen
		}
	)

	const textClass: ClassValue = classNames(
		'col-xs-12 col-md-10',
		{
			[classes.alignListRightStyle]: !(index % 2) && isLargeScreen
		}
	)

	const listDetails = () => (
		<div className="col-xs-12 col-md-6">
			<div className="row">
				<div className={textClass}>
					<h2>{item.title}</h2>

					<p>{parse(item.hook)}</p>
				</div>
			</div>
		</div>
	)

	return (
		<div className={listClass}>
			{
				!isLargeScreen && (
					<>
						{listDetails()}
					</>
				)
			}
			<div className="col-xs-12 col-md-6">
				<div className="imgContainer">
					<img src={item.picture} alt={item.title} />
					{
						item.caption && (
							<span>{item.caption}</span>
						)
					}
				</div>
			</div>

			{
				isLargeScreen && (
					<>
						{listDetails()}
					</>
				)
			}
		</div>
	)
}

export default DeckList