import React, { ReactChild } from 'react'
import { useMediaQuery } from 'react-responsive'

const isLargeScreen: boolean = true
const isTabletteScreen: boolean = false
const isLargeHeightScreen: boolean = false

export const ResponsiveContext = React.createContext(
	{
		isLargeScreen,
		isTabletteScreen,
		isLargeHeightScreen
	}
)

export default (props: { children: ReactChild }) => {
	const isLargeScreen = useMediaQuery(
		{
			query: '(min-width: 1025px)'
		}
	)

	const isTabletteScreen = useMediaQuery(
		{
			query: '(min-width: 768px)'
		}
	)

	const isLargeHeightScreen = useMediaQuery(
		{
			query: '(min-height:800px)'
		}
	)

	return (
		<ResponsiveContext.Provider
			value={{
				isLargeScreen,
				isTabletteScreen,
				isLargeHeightScreen
			}}
		>
			{props.children}
		</ResponsiveContext.Provider>
	)
}
