import { REMOVE_USER, SAVE_USER } from './actionTypes'
import { UserModel } from '../types/userModel'
import { UserTypes } from '../types/types'

const initialState: UserModel = {
	id: '0',
	id_contact_yf: '',
	firstname: '',
	lastname: '',
	email: '',
	phone: '',
	homepage_message_fr: '',
	homepage_message_en: null,
	picture: null,
	thumbnail: null,
	loaded: false,
	captcha: ''
}

const user = (
	state: UserModel = initialState,
	action: UserTypes
): UserModel => {
	switch (action.type) {
		case SAVE_USER: {
			return 'payload' in action ? action.payload : initialState
		}
		case REMOVE_USER: {
			return initialState
		}
		default:
			return state
	}
}

export default user