import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'
import { ResponsiveContext } from '../../utils/context/responsiveContext'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import SearchForm from './components/SearchForm'
import styles from '../../styles'
import SvgCommercial from '../../icons/Commercial'
import Button from '../../components/button/Button'
import { useHistory, useLocation } from 'react-router'
import links from '../../enums/linksEnum'
import { natureEnum } from '../../enums/natureEnum'
import { animateScroll as scroll } from 'react-scroll'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../index'
import { elementDisplayedEnum } from '../../components/offersDisplay/enum/elementDisplayedEnum'
import { objIsNatureType } from './utils/checkObjType'
import OffersHeader from './components/header/OffersHeader'
import { SearchFormModel } from './types/searchFormModel'
import { NatureModel } from '../offers/types/offersModel'
import { getFormData } from './utils/getFormData'
import OffersDisplay from '../../components/offersDisplay/OffersDisplay'
import OffersComponent from '../offers/components/OffersComponent'
import OffersProvider from '../offers/utils/context/offersContext'
import { SelectionModel } from '../selection/types/selectionModel'
import { OfferDetailsModel } from '../../components/offerDetails/types/offerDetailsModel'
import { redirectToWithQuery } from '../../utils/formatter/formatRouter'

const useStyles = makeStyles(
    (theme) => (
        {
            offersContainerStyle: {
                width: '100%'
            },
            offersContainerMapStyle: {
                '& > div': {
                    height: '100%'
                }
            },
            backgroundOffers: {
                width: '100%',

                '& > div': {
                    position: 'relative',

                    '& img': {
                        width: '100%',
                        minHeight: 750
                    },

                    '& > div': {
                        backgroundColor: theme.palette.blue.main,
                        opacity: 0.5,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        minHeight: 750
                    }
                }
            },
            modalContainerStyle: {
                '& > div:first-child': {
                    '& h1': {
                        color: theme.palette.blue.main,
                        marginTop: 0
                    },
                    '& .subtitle': {
                        color: theme.palette.blue.main,
                        ...styles(theme).text1,
                        paddingLeft: 5,
                        marginTop: 0
                    }
                },
                '& > div:last-child': {
                    '& h1': {
                        margin: 0,
                        color: theme.palette.blue.main,
                        ...styles(theme).title3
                    }
                }
            },
            modalContainerLargeScreenStyle: {
                position: 'absolute',
                top: 150,
                left: '50%',
                transform: 'translate(-50%, 0%)',
                width: 910,

                '& > div:first-child': {
                    background: 'white',
                    padding: '60px 80px',
                    boxSizing: 'border-box',

                    '& h1': {
                        textAlign: 'center',
                        ...styles(theme).title1,
                        marginBottom: 30
                    },
                    '& .subtitle': {
                        marginBottom: 30
                    }
                },
                '& > div:last-child': {
                    position: 'relative',
                    background: 'white',
                    marginLeft: 45,
                    marginTop: 30,
                    padding: '30px 80px 30px 70px',

                    '& svg': {
                        position: 'absolute',
                        left: -45,
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        backgroundColor: theme.palette.blue.main,
                        padding: '15px 25px'
                    }
                }
            },
            modalContainerSmallScreenStyle: {
                margin: '30px auto 0',
                width: '90%',

                '& > div:first-child': {
                    '& h1': {
                        ...styles(theme).title3,
                        marginBottom: 15,
                        paddingLeft: 5
                    },
                    '& .subtitle': {
                        marginBottom: 15
                    },
                    '& .col-xs-12': {
                        padding: 0
                    }
                },
                '& > div:last-child': {
                    marginBottom: 20,

                    '& h1': {
                        marginTop: 30,
                        marginBottom: 20
                    },
                    '& > div > div:last-child': {
                        margin: 'auto'
                    }
                }
            }
        }
    )
)

const SearchOffers = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const user = useSelector((state: RootState) => state.user)
    const offers = useSelector((state: RootState) => state.offers)
    const { isLargeScreen } = useContext(ResponsiveContext)

    const formData = useMemo<SearchFormModel | NatureModel | undefined>(
        () => getFormData(location.search), [location.search]
    )
    const [elementDisplayed, setElementDisplayed] = useState<elementDisplayedEnum>(elementDisplayedEnum.offers)
    const [offersFiltered, setOffersFiltered] = useState<(SelectionModel & OfferDetailsModel)[]>([])
    const [hasParams, setHasParams] = useState<boolean>(false)

    const backgroundOffersClass: ClassValue = classNames(
        {
            [classes.backgroundOffers]: isLargeScreen
        }
    )

    const modalContainerClass: ClassValue = classNames(
        classes.modalContainerStyle,
        isLargeScreen ? classes.modalContainerLargeScreenStyle : classes.modalContainerSmallScreenStyle
    )

    useEffect(
        () => {
            if (offers.loaded) {
                if (location.search) {

                    if (!!queryString.parse(location.search).cities || !!queryString.parse(location.search).disponibility || !!queryString.parse(location.search).surface) {
                        setHasParams(true)
                    } else if (!!queryString.parse(location.search).nature) {
                        setHasParams(true)
                    } else {
                        setHasParams(false)
                    }
                } else {
                    setHasParams(false)
                }
            }
        }, [dispatch, user.id, offers, location.search]
    )

    const seeRetailOffers = () => {
        scroll.scrollToTop()
        redirectToWithQuery(history, links.research, `${history.location.search}&nature=${natureEnum.retail}`)
    }

    const seeOffers = () => {
        scroll.scrollToTop()
        redirectToWithQuery(history, links.offers)
    }

    if (hasParams) {
        return (
            <div
                className={
                    classNames(
                        classes.offersContainerStyle,
                        {
                            [classes.offersContainerMapStyle]: elementDisplayed === elementDisplayedEnum.map
                        }
                    )
                }
            >
                {
                    elementDisplayed === elementDisplayedEnum.offers && formData && !objIsNatureType(formData) && (
                        <OffersHeader formData={formData}/>
                    )
                }

                <OffersProvider>
                    <OffersDisplay
                        selections={offersFiltered}
                        offers={<OffersComponent formData={formData} onOffersFilteredUpdated={setOffersFiltered}/>}
                        whichElementDisplayed={setElementDisplayed}
                    />
                </OffersProvider>
            </div>
        )
    }

    return (
        <>
            {
                isLargeScreen && (
                    <div className={backgroundOffersClass}>
                        <div>
                            <img
                                src="img/offers/offers_bg.png"
                                alt="Background img"
                            />

                            <div></div>
                        </div>
                    </div>
                )
            }

            <div className={modalContainerClass}>
                <div>
                    <h1>
                        <FormattedMessage
                            id="offers.research.title"
                            defaultMessage="Quel est votre projet ?"
                            description="Offers' research title"
                        />
                    </h1>

                    <div className="row">
                        <div className="col-xs-12">
                            <p className="subtitle">
                                <FormattedMessage
                                    id="offers.research.subtitle"
                                    defaultMessage="Découvrez nos lieux de vie et de travail principalement situés au cœur de la vie urbaine, dans Paris et l'Ouest parisien"
                                    description="Offers' subtitle message"
                                />
                            </p>
                        </div>
                    </div>

                    <SearchForm/>

                    <div className="row">
                        <div className="col-xs-12">
                            <Button
                                background="backgroundWhite"
                                color="colorAccessibility"
                                textStyle="text8"
                                border="borderAccessibility"
                                onClick={seeOffers}
                            >
                                <FormattedMessage
                                    id="offers.research.seeAllOffers"
                                    defaultMessage="Découvrez toutes les offres"
                                    description="See all offers button"
                                />
                            </Button>
                        </div>
                    </div>
                </div>

                <div>
                    {
                        isLargeScreen && (
                            <SvgCommercial width={40} height={48} color="#FFFFFF"/>
                        )
                    }

                    <div className="row middle-xs between-xs">
                        <div className="col-xs-12 col-md-7">
                            <h1>
                                <FormattedMessage
                                    id="offers.research.commercial.title"
                                    defaultMessage="Vous êtes intéressé par un local commercial ?"
                                    description="Commercial message"
                                />
                            </h1>
                        </div>

                        <div className="col-xs-12 col-md-3">
                            <Button
                                background="backgroundAccessibility"
                                color="colorWhite"
                                textStyle="text8"
                                onClick={seeRetailOffers}
                            >
                                <FormattedMessage
                                    id="offers.research.commercial.button"
                                    defaultMessage="Cliquez-ici"
                                    description="Button txt"
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchOffers
